import { Button } from '@components/Elements/Button'
import { Text } from '@components/Elements/Text'
import { ModalLayout } from '@components/Layouts'
import React from 'react'
import styled from 'styled-components/native'

const Wrapper = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 40px;
`

export function PlaygroundModal(props: any) {
  return (
    <ModalLayout title="Playground Modal">
      <Wrapper>
        <Text weight="bold" size="xLarge" margins={{ bottom: 20 }} i18n-ignore>
          Playground Modal
        </Text>
        <Button fullWidth label="Close" onPress={props.navigation.goBack} />
      </Wrapper>
    </ModalLayout>
  )
}
