import { ScreenLayout } from '@components/Layouts'
import { Language as LanguageComponent } from '@components/Settings'
import { useI18n } from '@lib/i18n'
import React from 'react'

export function Language(): JSX.Element {
  const t = useI18n()

  return (
    <ScreenLayout hasBack title={t('settings.language')}>
      <LanguageComponent />
    </ScreenLayout>
  )
}
