import { GenericValueEventWithId } from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import orderBy from 'lodash/orderBy'
import React from 'react'
import styled from 'styled-components/native'

import { IconButton } from '@components/Elements/Button'
import { Empty } from '@components/Elements/Empty'
import { Pressable } from '@components/Elements/Pressable'
import { HintText, Text } from '@components/Elements/Text'
import { useI18n } from '@lib/i18n'

import { SvgEmpty } from '@assets/svgr/Empty'
import { TrackingEvent as TrackingEventName, trackEvent } from '@lib/tracking'
import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import { RouteName } from '../../router/routeNames'
import { getIntervalDateLabel } from './helpers'
import { TrackingEventProps, TrackingEventsProps } from './types'

const EventWrapper = styled.View`
  flex-direction: row;
  padding: 8px 15px;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  background-color: ${({ theme }) => theme.colors.trackingEventBackground};
  align-items: center;
  flex-grow: 1;
`
const Row = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const EventsWrapper = styled.View`
  gap: 5px;
`
const EmptyEventWrapper = styled.View`
  gap: 10px;
`
const ButtonContainer = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
`
const ButtonWrapper = styled.View<{ isReverse: boolean }>`
  flex-direction: ${({ isReverse }) => (isReverse ? 'row-reverse' : 'row')};
  gap: 10px;
  align-items: center;
`

export function TrackingEvent({ value, time, note }: TrackingEventProps): JSX.Element {
  return (
    <EventWrapper>
      <View style={{ flex: 1 }}>
        <Row>
          {!!time && (
            <Text size="small" weight="semiBold">
              {time}
            </Text>
          )}
          <Text size="settings" weight="bold">
            {value}
          </Text>
        </Row>
        {!!note && (
          <Row>
            <HintText numberOfLines={1} size="tiny" weight="medium">
              {note}
            </HintText>
          </Row>
        )}
      </View>
      <View style={{ marginLeft: 15 }}>
        <IconButton pointerEvents="none" variant="secondary" size="small" iconSize={12} icon={['fad', 'pen']} />
      </View>
    </EventWrapper>
  )
}

function EmptyTrackingEvents({
  events,
  intervalType,
  intervalDates: [intervalStart, intervalEnd],
  onPress,
}: TrackingEventsProps): JSX.Element {
  const t = useI18n()
  const { previousEvent, nextEvent } = events.reduce<{
    previousEvent?: GenericValueEventWithId
    nextEvent?: GenericValueEventWithId
  }>((nearestEvents, currentEvent) => {
    const currentTimestamp = dayjs(currentEvent.timestamp)

    if (currentTimestamp.isBefore(intervalStart)) {
      if (
        !nearestEvents.previousEvent ||
        intervalStart.diff(currentTimestamp) < intervalStart.diff(dayjs(nearestEvents.previousEvent.timestamp))
      )
        nearestEvents.previousEvent = currentEvent
    }

    if (currentTimestamp.isAfter(intervalEnd)) {
      if (
        !nearestEvents.nextEvent ||
        intervalEnd.diff(currentTimestamp) < intervalStart.diff(dayjs(nearestEvents.nextEvent.timestamp))
      )
        nearestEvents.nextEvent = currentEvent
    }

    return nearestEvents
  }, {})

  return (
    <EmptyEventWrapper>
      <Empty
        message={t('tracking.noMeasurements', {
          interval: getIntervalDateLabel({ intervalType, intervalDates: [intervalStart, intervalEnd] }),
        })}
        svg={<SvgEmpty />}
      />
      <ButtonContainer>
        {[previousEvent, nextEvent].map((event, index) => {
          if (!event) return null

          return (
            <Pressable
              key={event.id}
              onPress={() => onPress(dayjs(event.timestamp))}
              render={() => (
                <ButtonWrapper isReverse={index !== 0}>
                  <IconButton
                    size="small"
                    iconSize={14}
                    icon={['fas', index === 0 ? 'arrow-left' : 'arrow-right']}
                    onPress={() => onPress(dayjs(event.timestamp))}
                  />
                  <HintText weight="semiBold">
                    {index === 0 ? t('tracking.previousMeasurement') : t('tracking.nextMeasurement')}
                  </HintText>
                </ButtonWrapper>
              )}
            />
          )
        })}
      </ButtonContainer>
    </EmptyEventWrapper>
  )
}

export function TrackingEvents({
  events,
  trackingConfig,
  intervalType,
  intervalDates: [intervalStart, intervalEnd],
  onPress,
}: TrackingEventsProps): JSX.Element {
  const navigation = useNavigation()
  const { getDisplayValue, getDisplayTime } = trackingConfig

  const intervalData = events.filter((event) => {
    const timestamp = dayjs(event.timestamp)

    return timestamp.isSameOrAfter(intervalStart) && timestamp.isSameOrBefore(intervalEnd)
  })

  function handleTrackingEventPress(event: GenericValueEventWithId): void {
    trackEvent(TrackingEventName.trackingEventPressed)
    navigation.navigate(RouteName.TrackingEventModal, { event })
  }

  return (
    <>
      {intervalData.length ? (
        <EventsWrapper>
          {orderBy(intervalData, 'timestamp', 'desc').map((event) => (
            <Pressable
              key={event.id}
              onPress={() => handleTrackingEventPress(event)}
              render={() => (
                <TrackingEvent
                  value={getDisplayValue([event.value ?? 0, event.additionalValue ?? 0], event.unit)}
                  time={getDisplayTime?.({ ...event, intervalType })}
                  note={event.additionalText}
                />
              )}
            />
          ))}
        </EventsWrapper>
      ) : (
        <EmptyTrackingEvents
          events={events}
          intervalDates={[intervalStart, intervalEnd]}
          intervalType={intervalType}
          trackingConfig={trackingConfig}
          onPress={onPress}
        />
      )}
    </>
  )
}
