import { SvgEmpty } from '@assets/svgr/Empty'
import { Empty } from '@components/Elements/Empty'
import { Loading } from '@components/Elements/Loading'
import { useBottom } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { useTrackingEventsStore } from '@stores/trackingEvents'
import dayjs, { Dayjs } from 'dayjs'
import noop from 'lodash/noop'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/native'
import { TrackingEvents } from './TrackingEvents'
import { TrackingInsightsButton } from './TrackingInsights'
import { TrackingInterval, getIntervalDates } from './TrackingInterval'
import { IntervalDates, IntervalType, TrackingChartProps } from './types'

const ScrollView = styled.ScrollView`
  flex: 1;
`
const EventsWrapper = styled.View`
  margin-top: 30px;
`

export function TrackingChart({ trackingType, trackingConfig, isControlled }: TrackingChartProps): JSX.Element | null {
  const t = useI18n()
  const paddingBottom = useBottom(0, 30)
  const { getChart, intervals, getDisplayValue, getDisplayTime } = trackingConfig
  const [isActive, setActive] = useState(false)
  const [isLoading, setLoading] = useState<boolean>(!isControlled)
  const [intervalType, setIntervalType] = useState<IntervalType>(intervals?.[0] || 'day')
  const [intervalDates, setIntervalDates] = useState<IntervalDates>(
    getIntervalDates({ intervalType, intervalDates: [dayjs(), dayjs()] }),
  )

  const [trackingEvents, getTrackingEvents] = useTrackingEventsStore((state) => [
    state.trackingEvents,
    state.getTrackingEvents,
  ])

  const trackingEventsByType = trackingEvents[trackingType]

  const { component: Chart, ...chartProps } = useMemo(() => {
    return getChart(trackingEventsByType, intervalType)
  }, [intervalType, trackingEventsByType, trackingConfig])

  function handleIntervalChange(type: IntervalType, dates: IntervalDates): void {
    setIntervalType(type)
    setIntervalDates(dates)
  }

  function handleEventPress(timestamp: Dayjs): void {
    handleIntervalChange(intervalType, [timestamp.startOf(intervalType), timestamp.endOf(intervalType)])
  }

  useEffect(() => {
    if (!isControlled) {
      setLoading(true)
      getTrackingEvents(trackingType)
        .catch(noop)
        .finally(() => setLoading(false))
    }
  }, [trackingType])

  if (isLoading) return <Loading />

  if (trackingEventsByType.length < 1) {
    return <Empty message={t('empty.noRecords')} svg={<SvgEmpty />} />
  }

  return (
    <>
      <TrackingInsightsButton trackingType={trackingType} trackingConfig={trackingConfig} />

      <TrackingInterval
        intervalType={intervalType}
        intervalDates={intervalDates}
        intervals={intervals}
        minDate={dayjs(trackingEventsByType[0].timestamp)}
        onChange={handleIntervalChange}
      />
      <ScrollView
        scrollEnabled={isControlled && !isActive}
        contentContainerStyle={{ paddingBottom, paddingHorizontal: 20 }}
      >
        <Chart
          {...chartProps}
          key={intervalDates[0].toString()}
          getDisplayValue={getDisplayValue}
          getDisplayTime={getDisplayTime}
          intervalDates={intervalDates}
          intervalType={intervalType}
          horizontalPadding={40}
          onActive={setActive}
        />
        {isControlled && (
          <EventsWrapper>
            <TrackingEvents
              events={trackingEventsByType}
              trackingConfig={trackingConfig}
              intervalType={intervalType}
              intervalDates={intervalDates}
              onPress={handleEventPress}
            />
          </EventsWrapper>
        )}
      </ScrollView>
    </>
  )
}
