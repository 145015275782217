import { Icon } from '@components/Elements/Icon'
import { Pressable } from '@components/Elements/Pressable'
import { Text } from '@components/Elements/Text'
import React from 'react'
import styled from 'styled-components/native'
import { TrackingInsightsBadge } from './TrackingInsights'
import { TrackingEventValue } from './styles'
import { TrackingPreviewProps } from './types'

const Wrapper = styled.View`
  padding: 10px 15px;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};
  flex-direction: row-reverse;
`
const IconWrapper = styled.View`
  width: 50px;
  height: 50px;
  border-radius: ${({ theme }) => theme.borderRadius.xLarge}px;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
`
const InnerWrapper = styled.View`
  flex: 1;
  justify-content: center;
`

export function TrackingPreview({ onPress, config, event }: TrackingPreviewProps) {
  if (event === undefined) return null

  const { color, icon, title, getDisplayValue } = config

  const displayValue = event ? getDisplayValue([event.value ?? 0, event.additionalValue ?? 0], event.unit) : '–'

  return (
    <Pressable
      onPress={onPress}
      render={() => (
        <Wrapper>
          <IconWrapper>
            <Icon icon={icon} size={22} color={color} />
          </IconWrapper>
          <InnerWrapper>
            <Text margins={{ bottom: 3 }} weight="semiBold" size="small" numberOfLines={1} color={color}>
              {title}
            </Text>
            {displayValue && <TrackingEventValue displayValue={displayValue} timestamp={event?.timestamp} />}
            {event?.type === 'bloodPressure' && (
              <TrackingInsightsBadge trackingType={event.type} trackingConfig={config} />
            )}
          </InnerWrapper>
        </Wrapper>
      )}
    />
  )
}
