import { ChatMessage } from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { useTheme } from 'styled-components/native'

import { SvgProsper } from '@assets/Prosper'
import { Icon } from '@components/Elements/Icon'
import { Text } from '@components/Elements/Text'
import { useAuthStore } from '@stores/auth'

const Container = styled.View`
  flex-direction: row;
  margin: 15px 20px;
  gap: 10px;
`
const Avatar = styled.View<{ isPatientMessage: boolean }>`
  border-radius: 24px;
  height: 34px;
  width: 34px;
  background-color: ${({ isPatientMessage, theme }) =>
    isPatientMessage ? theme.colors.secondaryBackground : theme.colors.primary};
  justify-content: center;
  ${({ isPatientMessage }) => isPatientMessage && 'align-items: center;'}
`
const Wrapper = styled.View`
  flex: 1;
  margin-top: 6px;
`
const NameWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`
const MessageWrapper = styled.View``

export function Message({ message }: { message: ChatMessage }): JSX.Element {
  const theme = useTheme()
  const [user] = useAuthStore((state) => [state.user])
  const isPatientMessage = message.userId === message.senderId

  return (
    <Container>
      <Avatar isPatientMessage={isPatientMessage}>
        {isPatientMessage ? (
          <View>
            <Icon size={13} color={theme.colors.primary} icon={['fad', 'user']} />
          </View>
        ) : (
          <SvgProsper width="100%" height={13} strokeWidth={50} stroke={'white'} style={{ marginTop: 1 }} />
        )}
      </Avatar>
      <Wrapper>
        <NameWrapper>
          <Text size="dialog" ellipsizeMode="tail" weight="semiBold">
            {isPatientMessage ? `${user?.firstName}` : 'Care manager'}
          </Text>
          <Text color={theme.colors.secondaryText} size="tiny">
            {dayjs(message.createdAt).format('LT')}
          </Text>
        </NameWrapper>
        <MessageWrapper>
          <Text>{message.content.trim()}</Text>
        </MessageWrapper>
      </Wrapper>
    </Container>
  )
}
