import { NavButton } from '@components/Elements/Button'
import { ButtonPlayground } from '@components/Elements/__Playground__/Button'
import { ImagePlayground } from '@components/Elements/__Playground__/Image'
import { MarkdownPlayground } from '@components/Elements/__Playground__/Markdown'
import { OverlayPlayground } from '@components/Elements/__Playground__/Overlays'
import { ScalePlayground } from '@components/Elements/__Playground__/Scale'
import { StoryPlayground } from '@components/Elements/__Playground__/Story'
import { TextInputPlayground } from '@components/Elements/__Playground__/TextInput'
import { TogglesPlayground } from '@components/Elements/__Playground__/Toggles'
import { VideoPlayerPlayground } from '@components/Elements/__Playground__/VideoPlayer'
import { ScreenLayout } from '@components/Layouts'
import { NativeStackScreenProps, createNativeStackNavigator } from '@react-navigation/native-stack'
import { Colors } from '@vetahealth/fishing-gear/colors'
import React from 'react'
import styled from 'styled-components/native'
import { PlaygroundModal } from './PlaygroundModal'

const Wrapper = styled.View`
  padding: 0 20px;
`

const components = [
  {
    name: 'Story',
    component: StoryPlayground,
  },
  {
    name: 'Markdown',
    component: MarkdownPlayground,
  },
  {
    name: 'Buttons',
    component: ButtonPlayground,
  },
  {
    name: 'TextInput',
    component: TextInputPlayground,
  },
  {
    name: 'Toggles',
    component: TogglesPlayground,
  },
  {
    name: 'Scale',
    component: ScalePlayground,
  },
  {
    name: 'Image',
    component: ImagePlayground,
  },
  {
    name: 'Overlays',
    component: OverlayPlayground,
  },
  {
    name: 'Video',
    component: VideoPlayerPlayground,
  },
]

const Stack = createNativeStackNavigator()

type RootstackParamList = {
  PlaygroundOverView: undefined
  [key: string]: undefined
}

function getRandomColor(): Colors {
  const colors = Object.values(Colors)
  return colors[Math.floor(Math.random() * (colors.length - 1))]
}

function PlaygroundOverView({ navigation }: NativeStackScreenProps<RootstackParamList, 'PlaygroundOverview'>) {
  return (
    <ScreenLayout title="Components" hasBack>
      <Wrapper>
        {components.map((item) => (
          <NavButton
            icon={['fad', 'brackets-curly']}
            key={item.name}
            label={item.name}
            onPress={() => navigation.navigate(`Playground${item.name}`)}
            color={getRandomColor()}
          />
        ))}
      </Wrapper>
    </ScreenLayout>
  )
}

export function Playground() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="PlaygroundOverview" component={PlaygroundOverView as any} />
      {components.map(({ name, component }) => (
        <Stack.Screen key={name} name={`Playground${name}`} component={component} />
      ))}
      <Stack.Screen
        name="PlaygroundModal"
        component={PlaygroundModal}
        options={{ presentation: 'modal', animation: 'slide_from_bottom' }}
      />
    </Stack.Navigator>
  )
}
