import { NavButton } from '@components/Elements/Button'
import { ScreenLayout } from '@components/Layouts'
import { useI18n } from '@lib/i18n'
import { useNavigation } from '@react-navigation/native'
import { Colors } from '@vetahealth/fishing-gear/colors'
import React from 'react'
import styled from 'styled-components/native'
import { RouteName } from '../../../routeNames'

const Wrapper = styled.View`
  flex-grow: 1;
  padding: 0 20px;
`

export function SettingsList() {
  const t = useI18n()
  const navigation = useNavigation()

  return (
    <ScreenLayout title={t('screen.settings')} hasBack>
      <Wrapper>
        <NavButton
          label={t('settings.language')}
          icon={['fad', 'earth-americas']}
          onPress={() => navigation.navigate(RouteName.Language)}
          color={Colors.azure300}
        />
        <NavButton
          label={t('settings.notifications')}
          icon={['fad', 'bell']}
          onPress={() => navigation.navigate(RouteName.Notifications)}
          color={Colors.apricot400}
        />
        <NavButton
          label={t('settings.units')}
          icon={['fad', 'gauge-high']}
          onPress={() => navigation.navigate(RouteName.Units)}
          color={Colors.azure300}
        />
        <NavButton
          label={t('settings.appearance')}
          icon={['fad', 'brightness']}
          onPress={() => navigation.navigate(RouteName.Appearance)}
          color={Colors.apricot300}
        />
      </Wrapper>
    </ScreenLayout>
  )
}
