import dayjs from 'dayjs'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { api } from '@lib/api'
import { storage } from '@lib/mmkv'
import { takeLeading } from '@lib/takeLeading'
import { TrackingEvent, trackEvent } from '@lib/tracking'

import { DeepLinkParams, OnboardingState, OnboardingStep } from './types'

export const initialState: Pick<OnboardingState, 'steps' | 'input' | 'hasDobVerification'> = {
  steps: [],
  input: {},
  hasDobVerification: false,
}

export function isDeepLinkValid(params: DeepLinkParams): boolean {
  return Boolean(
    params.otp &&
      params.otp.length === 6 &&
      params.otpExpiresAt &&
      dayjs().valueOf() < dayjs(params.otpExpiresAt).valueOf() &&
      params.userId,
  )
}

export function getOnboardingFlow(params?: DeepLinkParams): OnboardingStep[] {
  // deepLink flow
  if (params?.showWelcome) return [OnboardingStep.INTRO]

  if (params?.otp) {
    return [OnboardingStep.INTRO, isDeepLinkValid(params) ? OnboardingStep.OTP : OnboardingStep.EXPIRED]
  }

  // manual flow
  if (storage.exists('returningUser')) {
    return [OnboardingStep.INTRO, OnboardingStep.PHONE]
  }

  return [OnboardingStep.INTRO]
}

export const useOnboardingStore = createWithEqualityFn<OnboardingState>(
  (set, get) => ({
    ...initialState,
    updateInput: (values) => set(({ input }) => ({ input: { ...input, ...values } })),
    next: (step) => {
      set(({ steps }) => {
        return steps[steps.length - 1] === step ? { steps } : { steps: [...steps, step] }
      })
    },
    back: () => {
      set(({ steps }) => (steps.length > 1 ? { steps: steps.slice(0, steps.length - 1) } : { steps }))
    },
    replace: (step) => {
      set({ steps: Array.isArray(step) ? step : [step] })
    },
    requestOtp: takeLeading(async () => {
      const { input, deepLinkParams, next } = get()

      const response = await api.requestOtp({
        phone: input.phone,
        userId: deepLinkParams?.userId,
      })

      if (response) {
        // keep it for now => disabled organic signups
        if (response.requiresDobVerification) {
          set({ hasDobVerification: response.requiresDobVerification })
        }

        next(OnboardingStep.OTP)
      }
    }),
    verifyOtp: async () => {
      const { input, deepLinkParams } = get()

      const code = deepLinkParams?.otp || input.otp

      storage.set('isManualSignin', 'isManualSignin')

      if (code) {
        await api.signIn({
          phone: input.phone,
          userId: deepLinkParams?.userId,
          code,
          dateOfBirth: input.dateOfBirth ? dayjs(input.dateOfBirth).format('YYYY-MM-DD') : undefined,
        })
      }
    },
    reset: () => set(initialState),
    deepLink: undefined,
    deepLinkParams: undefined,
    renewDeepLink: async () => {
      const { deepLink, deepLinkParams } = get()

      if (deepLink && deepLinkParams?.userId) {
        await api.renewDeepLink(deepLinkParams.userId, deepLink)
      }
    },
    setDeepLink: (deepLink) => {
      let deepLinkParams: DeepLinkParams | undefined

      if (deepLink) {
        new URL(deepLink).searchParams?.forEach((value, key) => {
          if (!deepLinkParams) deepLinkParams = {}
          deepLinkParams[key] = value
        })
      }

      set({
        deepLink,
        deepLinkParams,
        hasDobVerification: !!deepLinkParams?.otp,
        steps: getOnboardingFlow(deepLinkParams),
      })

      if (deepLinkParams?.notificationId) {
        trackEvent(TrackingEvent.appOpenedViaDeepLink, {
          ...deepLinkParams,
          url: deepLink,
        })
      }
    },
    resetOtpParams: () => {
      const { deepLinkParams } = get()

      if (deepLinkParams)
        set({
          deepLinkParams: {
            ...deepLinkParams,
            otp: undefined,
            otpExpiresAt: undefined,
          },
        })
    },
    resetDeepLink: () => set({ deepLink: undefined, deepLinkParams: undefined }),
  }),
  shallow,
)
