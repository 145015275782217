import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { SvgHappy } from '@assets/svgr/Happy'
import { SvgMonitor } from '@assets/svgr/Monitor'
import { SvgPie } from '@assets/svgr/Pie'
import { SvgSofa } from '@assets/svgr/Sofa'
import { ScreenLayout } from '@components/Layouts'
import { Story, StoryItem } from '@components/Story'
import { useI18n } from '@lib/i18n'

import { View } from 'react-native'
import { Button } from '../Button'

export function StoryPlayground(): JSX.Element {
  const t = useI18n()

  const items: StoryItem[] = [
    {
      id: uuidv4(),
      image: SvgHappy,
      title: t('intro.welcome.title'),
      description: t('intro.welcome.description'),
    },
    {
      id: uuidv4(),
      image: SvgMonitor,
      title: t('intro.monitoring.title'),
      description: t('intro.monitoring.description'),
    },
    {
      id: uuidv4(),
      image: SvgSofa,
      title: t('intro.community.title'),
      description: t('intro.community.description'),
    },
    {
      id: uuidv4(),
      image: SvgPie,
      title: t('intro.tracking.title'),
      description: t('intro.tracking.description'),
    },
  ]

  return (
    <ScreenLayout hasBack title="Story">
      <View style={{ flexDirection: 'row' }}>
        <Story items={items} button={<Button label="Button" />} />
      </View>
    </ScreenLayout>
  )
}
