import React from 'react'
import styled from 'styled-components/native'

import { SvgProgramming } from '@assets/svgr/Programming'
import { useI18n } from '@lib/i18n'

import { Svg } from '../Elements/Image'
import { Message, ScrollView, Title, Wrapper } from './styles'

const Illustration = styled(SvgProgramming)`
  align-self: center;
  margin: 20px;
`

export function ServerDown(): JSX.Element {
  const t = useI18n()

  return (
    <ScrollView>
      <Wrapper>
        <Title>{t('error.server.title')}</Title>
        <Svg>
          <Illustration />
        </Svg>
        <Message>{t('error.server.message')}</Message>
      </Wrapper>
    </ScrollView>
  )
}
