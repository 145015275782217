import { Button } from '@components/Elements/Button'
import { ModalLayout } from '@components/Layouts'
import { Release } from '@components/Release'
import { useDebounce } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { useNavigation } from '@react-navigation/native'
import { useAppStore } from '@stores/app'
import { useAuthStore } from '@stores/auth'
import React, { useEffect } from 'react'
import styled from 'styled-components/native'

const Wrapper = styled.View`
  padding: ${({ theme }) => `10px ${theme.width < 380 ? 20 : 40}px`};
`

const ButtonWrapper = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
`

export function ReleaseModal() {
  const t = useI18n()
  const navigation = useNavigation()

  const [releases] = useAppStore((state) => [state.releases])
  const [user, updateAccount] = useAuthStore((state) => [state.user, state.updateAccount])

  function updateFlags(): void {
    updateAccount({
      flags: {
        welcome: !!user?.flags?.welcome, // TODO: allow only updated flags in the future, right now all flags are required
        viewedReleaseInfo: [
          ...(user?.flags?.viewedReleaseInfo ?? []),
          ...releases.map((release) => release.id).filter((id) => !user?.flags?.viewedReleaseInfo?.includes(id)),
        ],
      },
    })
  }

  const handleCancelPressed = useDebounce(() => {
    trackEvent(TrackingEvent.releaseCancelPressed)
    updateFlags()
  }, [user, releases])

  const handleGotItPressed = useDebounce(() => {
    trackEvent(TrackingEvent.releaseGotItPressed)
    updateFlags()
    navigation.goBack()
  }, [user, releases])

  useEffect(() => {
    trackEvent(TrackingEvent.releaseOpened)
  }, [])

  return (
    <ModalLayout title={t('screen.release')} onCancel={handleCancelPressed}>
      <Wrapper>
        <Release release={releases[0]} />
        <ButtonWrapper>
          <Button onPress={handleGotItPressed} label={t('actions.gotIt')} />
        </ButtonWrapper>
      </Wrapper>
    </ModalLayout>
  )
}
