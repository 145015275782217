import { Colors } from '@vetahealth/fishing-gear/colors'
import styled from 'styled-components/native'

import { Text } from '../Text'

export const Content = styled.View`
  padding: 20px;
`
export const Box = styled.View`
  padding: 20px;
  border-color: ${Colors.gray100};
  border-bottom-width: 1px;
  margin-bottom: 10px;
`
export const Label = styled(Text).attrs({
  size: 'small',
  weight: 'semiBold',
  margins: { top: 20, bottom: 10 },
})``

export const Row = styled.View`
  flex-direction: row;
`
