import { NavigationContainerRefWithCurrent } from '@react-navigation/native'
import { Platform } from 'react-native'

import { config } from '@lib/config'
import { appVersion } from '@lib/constants'
import { identifyMixpanel, mixpanel } from '../Mixpanel'
import { TrackingEvent } from './trackingEvents'

const hasMixpanelKey = !!config.mixpanelKey && config.mixpanelKey !== '-'

export function identify(id: string): void {
  if (hasMixpanelKey) identifyMixpanel(id)
}

export function resetTracking(): void {
  if (hasMixpanelKey) mixpanel?.reset()
}

export function trackEvent(eventName: TrackingEvent, payload: { [key: string]: any } = {}): void {
  if (hasMixpanelKey) {
    mixpanel?.track(eventName, { ...payload, source: Platform.select({ native: 'mobile', web: 'web' }), appVersion })
  }
}

let prevRouteName: string
let prevStringifiedParams: string

export function trackScreen(navigationRef: NavigationContainerRefWithCurrent<any>): void {
  const { name, params } = navigationRef.getCurrentRoute() || {}
  const stringifiedParams = JSON.stringify(params)
  const hasDifferentName = prevRouteName !== name
  const hasDifferentParams = prevStringifiedParams !== stringifiedParams

  if (name && (hasDifferentName || hasDifferentParams)) {
    prevRouteName = name
    prevStringifiedParams = stringifiedParams
    trackEvent(TrackingEvent.screen, { ...params, name })
  }
}
