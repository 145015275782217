import { NewMessage } from '@components/Chat/NewMessage'
import { ModalLayout } from '@components/Layouts'
import { useI18n } from '@lib/i18n'
import React from 'react'

export function NewMessageModal(): JSX.Element {
  const t = useI18n()

  return (
    <ModalLayout title={t('screen.newMessage')}>
      <NewMessage />
    </ModalLayout>
  )
}
