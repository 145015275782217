import { v4 as uuidv4 } from 'uuid'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { ToastState } from './types'

export const useToastsStore = createWithEqualityFn<ToastState>(
  (set, get) => ({
    toasts: [],
    addToast: (toast) => {
      set(({ toasts }) => ({
        toasts:
          toast.key && toasts.some(({ key }) => toast.key === key) ? toasts : [...toasts, { ...toast, id: uuidv4() }],
      }))
    },
    removeToast: (id) => {
      const { toasts } = get()
      const newToasts = toasts.filter((toast) => toast.id !== id)
      set({ toasts: newToasts })
    },
  }),
  shallow,
)
