import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'

import { Pressable } from '../Pressable'
import { Label, LabelWrapper, Toggle, ToggleWrapper, Wrapper } from './styles'
import { RadioGroupProps } from './types'

const RadioWrapper = styled(ToggleWrapper)`
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
`
const Radio = styled(Toggle)`
  height: 14px;
  width: 14px;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
`

export function RadioGroup({ value, onChange, items }: RadioGroupProps) {
  return (
    <View>
      {items.map((item) => {
        const isChecked = item.value === value

        return (
          <Pressable
            aria-label="radio-input"
            key={item.value}
            onPress={() => onChange(item.value)}
            render={({ hovered }) => (
              <Wrapper>
                <RadioWrapper isChecked={isChecked} isHovered={hovered}>
                  <Radio isChecked={isChecked} />
                </RadioWrapper>
                <LabelWrapper>
                  <Label>{item.label}</Label>
                </LabelWrapper>
              </Wrapper>
            )}
          />
        )
      })}
    </View>
  )
}
