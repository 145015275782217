import { Toasts } from '@components/Elements/Toasts'
import { PortalHost, PortalProvider } from '@gorhom/portal'
import { api } from '@lib/api'
import { setLocale } from '@lib/i18n'
import { useDeepLinkingListener } from '@lib/linking'
import { trackScreen } from '@lib/tracking'
import { DarkTheme, DefaultTheme, NavigationContainer, useNavigationContainerRef } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useAppStore } from '@stores/app'
import { useAuthStore } from '@stores/auth'
import { SupportedLocaleEnum } from '@vetahealth/tuna-can-api'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { useColorScheme } from 'react-native'
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context'
import styled, { useTheme } from 'styled-components/native'
import { RouterApp } from './RouterApp'
import { routeConfig } from './routeConfig'
import { RouteName } from './routeNames'
import { RootStackParamList } from './types'

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

const ThemedSafeAreaProvider = styled(SafeAreaProvider)`
  background-color: ${({ theme }) => theme.colors.background};
`
const FullScreenSafeAreaView = styled(SafeAreaView)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.background};
`

const Stack = createNativeStackNavigator<any>()

export function Router() {
  const theme = useTheme()
  const colorScheme = useColorScheme()
  const navigationRef = useNavigationContainerRef()
  const [setRouterInitialized, appearance] = useAppStore((state) => [state.setRouterInitialized, state.appearance])
  const [user, updateAccount] = useAuthStore((state) => [state.user, state.updateAccount])

  useDeepLinkingListener(navigationRef)

  useEffect(() => {
    if (user) {
      if (user.locale) {
        setLocale(user.locale)
        return
      }

      updateAccount({
        locale: i18next.language as SupportedLocaleEnum,
      })
    }
  }, [user])

  let routerTheme = DefaultTheme

  if ((appearance === 'system' && colorScheme === 'dark') || appearance === 'dark') {
    routerTheme = DarkTheme
    routerTheme.colors.background = theme.colors.background
  }

  return (
    <ThemedSafeAreaProvider>
      <FullScreenSafeAreaView edges={['top', 'left', 'right']}>
        <PortalProvider>
          <NavigationContainer
            theme={routerTheme}
            ref={navigationRef}
            documentTitle={{ enabled: false }}
            onReady={() => {
              setRouterInitialized()
              trackScreen(navigationRef)
            }}
            linking={routeConfig}
            onStateChange={() => {
              api.checkTokenValidity()
              trackScreen(navigationRef)
            }}
          >
            <Stack.Navigator screenOptions={{ headerShown: false, orientation: 'portrait_up' }}>
              <Stack.Screen name={RouteName.App} component={RouterApp} />
            </Stack.Navigator>
          </NavigationContainer>
          <PortalHost name="image" />
          <PortalHost name="dialog" />
          <PortalHost name="bottomSheet" />
          <PortalHost name="toast" />
          <Toasts />
        </PortalProvider>
      </FullScreenSafeAreaView>
    </ThemedSafeAreaProvider>
  )
}
