import * as Sentry from './Sentry'
import { isDev } from './constants'

export function captureException(error: any, source?: string) {
  if (!isDev) {
    Sentry.captureException(error, source ? { tags: { source } } : {})
  } else {
    console.log(`Sentry captureException - ${source}`, error)
  }
}
