import mixpanel from 'mixpanel-browser'

import { config } from '@lib/config'

export function initializeMixpanel(): void {
  if (config.mixpanelKey && config.mixpanelKey !== '-') {
    mixpanel.init(config.mixpanelKey, {
      track_pageview: false,
      disable_cookie: true,
    })
  }
}

export function identifyMixpanel(id: string): void {
  mixpanel.identify(id)
  mixpanel.people.set('name', id)
}

export { mixpanel }
