import noop from 'lodash/noop'
import React from 'react'
import styled from 'styled-components/native'

import { BaseButton, ButtonIcon } from './styles'
import { ButtonSize, IconButtonProps } from './types'

const buttonSize: Record<ButtonSize, number> = {
  large: 50,
  medium: 40,
  small: 30,
}

const Wrapper = styled.View`
  flex-direction: row;
`
const ButtonWrapper = styled(BaseButton)<{
  size: ButtonSize
}>`
  ${({ size }) => `
    width: ${buttonSize[size]}px;
    height: ${buttonSize[size]}px;
    border-radius: ${(buttonSize[size] / 10) * 3}px;
    justify-content: center;
    align-items: center;
  `}
  ${({ isDisabled }) => !isDisabled && 'opacity: 0.85;'}
`

export function IconButton({
  icon,
  iconSize = 19,
  onPress = noop,
  variant = 'primary',
  isDisabled = false,
  color: customColor,
  size = 'medium',
  pointerEvents,
}: IconButtonProps): React.ReactElement {
  const opacity = ['secondary', 'plain'].includes(variant) ? 0.65 : 0.75

  return (
    <Wrapper>
      <ButtonWrapper
        role="button"
        pointerEvents={pointerEvents}
        onPress={onPress}
        isDisabled={isDisabled}
        variant={variant}
        size={size}
        hoverStyle={{ opacity }}
        render={() => (
          <ButtonIcon icon={icon} color={customColor} size={iconSize} isDisabled={isDisabled} variant={variant} />
        )}
      />
    </Wrapper>
  )
}
