import { SupportedLocaleEnum } from '@vetahealth/tuna-can-api'
import React, { useEffect, useState } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import styled from 'styled-components/native'
import { v4 as uuidv4 } from 'uuid'

import { SvgHappy } from '@assets/svgr/Happy'
import { SvgMonitor } from '@assets/svgr/Monitor'
import { SvgPie } from '@assets/svgr/Pie'
import { SvgSofa } from '@assets/svgr/Sofa'
import { Button } from '@components/Elements/Button'
import { Story, StoryItem } from '@components/Story'
import { api } from '@lib/api'
import { useI18n } from '@lib/i18n'
import { storage } from '@lib/mmkv'
import { DeepLinkParams, OnboardingStep, isDeepLinkValid, useOnboardingStore } from '@stores/onboarding'
import { OnboardingAnimationWrapper, useOnboardingAnimation } from './styles'

const Wrapper = styled.View<{ bottom: number }>`
  flex: 1;
  padding-bottom: ${({ bottom }) => bottom || 30}px;
`

export function Intro({ params }: { params?: DeepLinkParams }): JSX.Element {
  const t = useI18n()
  const { bottom } = useSafeAreaInsets()
  const style = useOnboardingAnimation()
  const [next, deepLink] = useOnboardingStore((state) => [state.next, state.deepLink])
  const [items, setItems] = useState<StoryItem[]>([])

  function handlePress() {
    let nextStep = OnboardingStep.PHONE

    if (deepLink && params?.otp) {
      nextStep = isDeepLinkValid(params) ? OnboardingStep.OTP : OnboardingStep.EXPIRED
    }

    next(nextStep)
  }

  const genericItems: StoryItem[] = [
    {
      id: uuidv4(),
      image: SvgHappy,
      title: t('intro.welcome.title'),
      description: t('intro.welcome.description'),
    },
    {
      id: uuidv4(),
      image: SvgMonitor,
      title: t('intro.monitoring.title'),
      description: t('intro.monitoring.description'),
    },
    {
      id: uuidv4(),
      image: SvgSofa,
      title: t('intro.community.title'),
      description: t('intro.community.description'),
    },
    {
      id: uuidv4(),
      image: SvgPie,
      title: t('intro.tracking.title'),
      description: t('intro.tracking.description'),
    },
  ]

  useEffect(() => {
    if (params?.siteKey) {
      api
        .getSiteIntro((storage.get('locale') as SupportedLocaleEnum) || SupportedLocaleEnum.EnUs, params.siteKey)
        .then((siteIntro) => {
          if (siteIntro?.length) {
            setItems(
              siteIntro.map(({ imageUrl, ...item }) => ({
                ...item,
                image: imageUrl,
              })),
            )
          } else {
            setItems(genericItems)
          }
        })
    } else {
      setItems(genericItems)
    }
  }, [params?.siteKey])

  return (
    <OnboardingAnimationWrapper style={style}>
      <Wrapper bottom={bottom}>
        <Story
          key={params?.siteKey}
          button={<Button label={t('intro.getStarted')} onPress={handlePress} />}
          items={items}
        />
      </Wrapper>
    </OnboardingAnimationWrapper>
  )
}
