import { useI18n } from '@lib/i18n'
import React from 'react'
import styled from 'styled-components/native'
import { Button } from '../Elements/Button'

const ActionsWrapper = styled.View`
  align-items: center;
  margin: 20px 0;
`

export function Actions({
  onSubmit,
  isSubmitLoading,
  hasQuestions,
}: {
  onSubmit: () => void
  isSubmitLoading?: boolean
  hasQuestions: boolean
}) {
  const t = useI18n()

  return (
    <ActionsWrapper>
      <Button
        label={hasQuestions ? t('actions.submit') : t('actions.gotIt')}
        variant="primary"
        onPress={onSubmit}
        isLoading={isSubmitLoading}
      />
    </ActionsWrapper>
  )
}
