import React from 'react'
import styled from 'styled-components/native'

import { Svg } from '../Image'
import { Text } from '../Text'

const EmptyWrapper = styled.View`
  margin-top: 20px;
  gap: 20px;
`

const MessageWrapper = styled.View`
  padding: 20px;
`

const Message = styled(Text).attrs({
  weight: 'semiBold',
  size: 'button',
  isCentered: true,
})``

export function Empty({
  svg,
  children,
  message,
}: {
  svg?: React.ReactElement
  children?: React.ReactElement
  message?: string
}): JSX.Element {
  return (
    <EmptyWrapper>
      {!!svg && <Svg size="small">{svg}</Svg>}
      {!!message && (
        <MessageWrapper>
          <Message>{message}</Message>
        </MessageWrapper>
      )}
      {children}
    </EmptyWrapper>
  )
}
