import { Text } from '@components/Elements/Text'
import { ScreenLayout } from '@components/Layouts'
import { useI18n } from '@lib/i18n'
import licenses from '@lib/licenses.json'
import React from 'react'
import styled from 'styled-components/native'

const DependencyWrapper = styled.View`
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  margin: 0 20px 10px;
  padding: 20px;
`

export function Licenses() {
  const t = useI18n()

  return (
    <ScreenLayout title={t('screen.licenses')} hasBack>
      {Object.values(licenses).map(
        ({
          name,
          version,
          license,
          licenseText,
        }: {
          name: string
          version: string
          license: string
          licenseText?: string
        }) => (
          <DependencyWrapper key={name}>
            <Text weight="semiBold" size="small">
              {`${name}@${version}`}
            </Text>
            <Text weight="medium" size="tiny">
              {t('about.license', { licenses: license })}
            </Text>
            {!!licenseText && <Text size="tiny">{licenseText}</Text>}
          </DependencyWrapper>
        ),
      )}
    </ScreenLayout>
  )
}
