import { SupportedLocaleEnum } from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { useCallback } from 'react'
import { getLocales } from 'react-native-localize'

import { storage } from '@lib/mmkv'
import { useAuthStore } from '@stores/auth'

import { hyphenate } from './hyphenate'

type TranslationData = {
  [key: string]: any
  withHyphenation?: boolean
}

function translate(key: string, data: TranslationData = {}): string {
  const i18nextTranslate = i18next.t // satisfy i18nLint
  const translation = i18nextTranslate(key, { nsSeparator: false, ...data })

  return !data.withHyphenation || translation.includes('https') || translation.includes('mailto:')
    ? translation
    : hyphenate(translation)
}

export const t = translate // satisfy i18nLint

export function useI18n() {
  const [user] = useAuthStore((state) => [state.user])

  const t = useCallback((key: string, data: TranslationData = {}) => translate(key, { user, ...data }), [user])

  return t
}

export function initializeLocales(): void {
  let locale = storage.get('locale')
  const supportedLocales = Object.values<string>(SupportedLocaleEnum)

  if (!locale) {
    const deviceLocales: string[] = []
    const deviceLanguageCodes: string[] = []

    getLocales().forEach(({ languageTag, languageCode }) => {
      deviceLocales.push(languageTag)
      deviceLanguageCodes.push(languageCode)
    })

    locale = supportedLocales.find(
      (supportedLocale) =>
        deviceLocales.includes(supportedLocale) || deviceLanguageCodes.includes(supportedLocale.substring(0, 2)),
    )
  }

  const supportedLocale = supportedLocales.includes(locale as string) ? locale : SupportedLocaleEnum.EnUs

  setLocale(supportedLocale as SupportedLocaleEnum)
}

export function setLocale(locale: `${SupportedLocaleEnum}`): void {
  const dayjsLocales: Record<SupportedLocaleEnum, string> = {
    [SupportedLocaleEnum.EnUs]: 'en',
    [SupportedLocaleEnum.Es419]: 'es',
    [SupportedLocaleEnum.EsUs]: 'es-us',
  }

  i18next.changeLanguage(locale)
  dayjs.locale(dayjsLocales[locale])
  storage.set('locale', locale)
}
