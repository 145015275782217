export function takeLeading<Method extends (...params: any[]) => Promise<any>>(
  method: Method,
  options: { ignoreParams?: boolean } = { ignoreParams: false },
): (...params: Parameters<Method>) => ReturnType<Method> {
  let promise: any
  let prevParams: string | undefined

  return (...params) => {
    // params could be empty (func()) or an array [undefined] (optional => func(obj?.key))
    // stringify converts undefined to null, which would lead to different params
    // to avoid duplicate calls with basically same intention undefined params are filtered
    const currentParams = JSON.stringify(params.filter((param) => typeof param !== 'undefined'))

    if (promise && (prevParams === currentParams || options.ignoreParams)) {
      return promise
    }

    prevParams = currentParams

    promise = new Promise((resolve, reject) => {
      method(...params)
        .then((data) => {
          promise = null
          resolve(data)
        })
        .catch((error) => {
          promise = null
          reject(error)
        })
    })

    return promise
  }
}
