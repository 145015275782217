import React from 'react'
import { Platform } from 'react-native'

import { ButtonVariant } from '../Button/types'
import { FontAwesomeIcon, FontAwesomeIconProps } from './FontAwesome'

export function Icon({
  icon,
  size,
  color,
}: FontAwesomeIconProps & {
  isDisabled?: boolean
  variant?: `${ButtonVariant}`
}) {
  const sizeProp = Platform.select({
    native: { size },
    web: { style: { fontSize: `${size}px` } },
  })

  // @ts-ignore: TS uses native types. Types for web support CSSProperties
  return <FontAwesomeIcon {...sizeProp} {...{ icon, color }} />
}
