import dayjs, { Dayjs } from 'dayjs'
import noop from 'lodash/noop'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { TextInput } from 'react-native'
import styled from 'styled-components/native'

import { useI18n } from '@lib/i18n'

import { HintText, Text } from '../Text'
import { BaseInput, InputWrapper } from './BaseInput'
import { InputError } from './InputError'
import { DateInputSimpleProps } from './types'

const Wrapper = styled.View`
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-self: center;
`
const DateInputWrapper = styled.View<{ flex?: number; hasMargin?: boolean }>`
  flex: ${({ flex = 1 }) => flex};
  ${({ hasMargin }) => hasMargin && 'margin: 0 5px'};
`
const Label = styled(Text).attrs({
  weight: 'semiBold',
  size: 'small',
})`
  margin-left: 5px;
  margin-bottom: 5px;
`
const Hint = styled(HintText).attrs({
  weight: 'semiBold',
  size: 'tiny',
  isCentered: false,
  margins: { bottom: 5, left: 13 },
})``
const DateInput = styled(BaseInput)`
  text-align: center;
`

export const DateInputSimple = forwardRef<TextInput, DateInputSimpleProps>(
  ({ date, onChange, error, label, onSubmitEditing = noop }, firstInputRef) => {
    const t = useI18n()

    const secondInputRef = useRef<TextInput>(null)
    const thirdInputRef = useRef<TextInput>(null)
    const [inputRefs] = useState([firstInputRef, secondInputRef, thirdInputRef])

    const [month, setMonth] = useState<string>(date ? `${date.get('month') + 1}` : '')
    const [day, setDay] = useState<string>(date ? `${date.get('date')}` : '')
    const [year, setYear] = useState<string>(date ? `${date.get('year')}` : '')

    const [customError, setCustomError] = useState<string>('')

    useEffect(() => {
      setCustomError('')

      let newDate: Dayjs | undefined

      if (month && day && year && year.length === 4) {
        const dateCandidate = dayjs(`${month}-${day}-${year}`, 'M-D-YYYY', true)

        if (dateCandidate.isValid()) {
          newDate = dateCandidate
        } else {
          setCustomError(t('validation.dateInvalid'))
        }
      }

      onChange(newDate)
    }, [month, day, year])

    function focusNext(currentIndex: number): void {
      const inputRef = inputRefs[currentIndex + 1] as React.RefObject<TextInput>
      if (currentIndex < 2 && inputRef) inputRef.current?.focus()
    }

    function handleFocusPrevious(key: string, value: string, currentIndex: number): void {
      if (value === '' && key === 'Backspace') {
        const inputRef = inputRefs[currentIndex - 1] as React.RefObject<TextInput>
        if (currentIndex > 0 && inputRef) inputRef.current?.focus()
      }
    }

    function handleMonth(value: string, currentIndex: number): void {
      if (!value) {
        setMonth('')
        return
      }

      // (0)1-12
      if (/\b(0[1-9]|[0-9]|1[0-2])\b/.test(value)) {
        setMonth(`${Number(value)}`)
        if (Number(value) > 1 || value.length > 1) focusNext(currentIndex)
      }
    }

    function handleDay(value: string, currentIndex: number): void {
      if (!value) {
        setDay('')
        return
      }

      // (0)1-31
      if (/\b(0[1-9]|[0-9]|[12][0-9]|3[01])\b/.test(value)) {
        setDay(`${Number(value)}`)
        if (Number(value) > 3 || value.length > 1) focusNext(currentIndex)
      }
    }

    function handleYear(value: string, currentIndex: number): void {
      if (!value || !Number(value)) {
        setYear('')
        return
      }

      setYear(value)
      focusNext(currentIndex)
    }

    function renderDateInputs(): Array<React.ReactNode> {
      const isComplete = month && day && year
      const dateInputOrder = t('date.inputOrder').split('-')

      return dateInputOrder.map((datePart, index) => {
        const hasMargin = index === 1 // center

        if (datePart === 'DD') {
          return (
            <DateInputWrapper key="dayInput" flex={2} hasMargin={hasMargin}>
              <Hint numberOfLines={1}>{t('date.day.label')}</Hint>
              <DateInput
                ref={inputRefs[index]}
                onKeyPress={({ nativeEvent }) => handleFocusPrevious(nativeEvent.key, day, index)}
                value={day}
                onChangeText={(value) => handleDay(value, index)}
                onSubmitEditing={() => (isComplete ? onSubmitEditing() : focusNext(index))}
                enterKeyHint="next"
                inputMode="numeric"
                maxLength={2}
                placeholder={t('date.day.placeholder')}
              />
            </DateInputWrapper>
          )
        }

        if (datePart === 'MM') {
          return (
            <DateInputWrapper key="monthInput" flex={2} hasMargin={hasMargin}>
              <Hint numberOfLines={1}>{t('date.month.label')}</Hint>
              <DateInput
                ref={inputRefs[index]}
                onKeyPress={({ nativeEvent }) => handleFocusPrevious(nativeEvent.key, month, index)}
                value={month}
                onChangeText={(value) => handleMonth(value, index)}
                onSubmitEditing={() => (isComplete ? onSubmitEditing() : focusNext(index))}
                enterKeyHint="next"
                inputMode="numeric"
                maxLength={2}
                placeholder={t('date.month.placeholder')}
              />
            </DateInputWrapper>
          )
        }

        if (datePart === 'YYYY') {
          return (
            <DateInputWrapper key="yearInput" flex={3} hasMargin={hasMargin}>
              <Hint numberOfLines={1}>{t('date.year.label')}</Hint>
              <DateInput
                ref={inputRefs[index]}
                onKeyPress={({ nativeEvent }) => handleFocusPrevious(nativeEvent.key, year, index)}
                value={year}
                onChangeText={(value) => handleYear(value, index)}
                enterKeyHint="done"
                onSubmitEditing={() => (isComplete ? onSubmitEditing() : focusNext(index))}
                inputMode="numeric"
                maxLength={4}
                placeholder={t('date.year.placeholder')}
              />
            </DateInputWrapper>
          )
        }
      })
    }

    return (
      <InputWrapper>
        {label ? <Label>{label}</Label> : null}
        <Wrapper>{renderDateInputs()}</Wrapper>
        <InputError error={customError || error} />
      </InputWrapper>
    )
  },
)
