import { api } from '@lib/api'
import { timeZone } from '@lib/constants'
import { identify } from '@lib/tracking'
import { SupportedLocaleEnum, UserToken } from '@vetahealth/tuna-can-api'
import { jwtDecode } from 'jwt-decode'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import { AuthState } from './types'

export const initialState: Pick<
  AuthState,
  'authorized' | 'userId' | 'user' | 'supportedLocales' | 'permissions' | 'welcome'
> = {
  authorized: false,
  userId: undefined,
  user: undefined,
  supportedLocales: Object.values(SupportedLocaleEnum),
  permissions: [],
  welcome: undefined,
}

export const useAuthStore = createWithEqualityFn<AuthState>(
  (set, get) => ({
    ...initialState,
    setAuthorized: ({ accessToken, info, permissions }) => {
      const { updateAccount } = get()
      const { sub: userId } = jwtDecode<UserToken>(accessToken)

      if (info.timeZone !== timeZone) updateAccount({ timeZone })
      const { supportedLocales, ...user } = info

      set({ authorized: true, userId, user, permissions, supportedLocales })

      identify(userId)
    },
    setUnauthorized: () => set(initialState),
    updateAccount: async (accountUpdate) => {
      const { user } = get()

      if (isEqual(accountUpdate, pick(user, Object.keys(accountUpdate)))) return

      try {
        const updatedUser = await api.updateAccount(accountUpdate)

        if (updatedUser) set({ user: updatedUser })
      } catch (_) {}
    },
    getWelcome: async () => {
      const { user } = get()

      if (user?.locale) {
        const welcome = await api.getWelcome(user.locale)

        if (welcome) set({ welcome })
      }
    },
  }),
  shallow,
)
