import styled from 'styled-components/native'

import { HintText, Text } from '../Elements/Text'

export const ScrollView = styled.ScrollView`
  flex: 1;
`
export const Wrapper = styled.View`
  padding: 40px;
  margin-top: 70px;
`
export const Title = styled(Text).attrs({
  weight: 'bold',
  size: 'xxLarge',
  isCentered: true,
  margins: { bottom: 30 },
})``
export const Message = styled(Text).attrs({
  isCentered: true,
  margins: { top: 10, bottom: 30 },
})``
export const Hint = styled(HintText).attrs({
  isCentered: true,
  weight: 'semiBold',
  margins: { top: 20, bottom: 40, left: 20, right: 20 },
})``
export const ButtonWrapper = styled.View`
  align-items: center;
`
