import identity from 'lodash/identity'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { getTrackingConfig } from '@components/Tracking/trackingConfig'
import { TrackingChartAssetTrackingTypeWithoutHeightEnum } from '@components/Tracking/types'
import { api } from '@lib/api'
import { takeLeading } from '@lib/takeLeading'
import { TrackingEvent, trackEvent } from '@lib/tracking'

import { useTasksStore } from '../tasks'
import { TrackingEventsState } from './types'

export const initialState: Pick<TrackingEventsState, 'lastTrackingEvents' | 'trackingEvents'> = {
  lastTrackingEvents: null,
  trackingEvents: {
    bloodPressure: [],
    heartRate: [],
    bloodGlucose: [],
    weight: [],
    qualityOfLife: [],
    medicationAdherence: [],
    height: [],
    steps: [],
    sleep: [],
    respiratoryRate: [],
    bodyTemperature: [],
    bloodOxygen: [],
  },
}

export const useTrackingEventsStore = createWithEqualityFn<TrackingEventsState>(
  (set, get) => ({
    ...initialState,
    getLastTrackingEvents: takeLeading(async () => {
      const lastTrackingEvents = (await api.getLastTrackingEvents()) || {}

      set({ lastTrackingEvents })

      if (lastTrackingEvents.bloodPressure) {
        get().getTrackingEvents('bloodPressure')
      }
    }),
    getTrackingEvents: takeLeading(async (trackingType) => {
      const { normalize = identity } = getTrackingConfig<TrackingChartAssetTrackingTypeWithoutHeightEnum>(trackingType)
      const trackingEvents = await api.getTrackingEventsByType(trackingType)

      if (trackingEvents) {
        set((prevState) => ({
          trackingEvents: {
            ...prevState.trackingEvents,
            [trackingType]: normalize(trackingEvents),
          },
        }))
      }
    }),
    addTrackingEvent: async (trackingEvent) => {
      trackEvent(TrackingEvent.trackingAddSavePressed, {
        type: trackingEvent.type,
      })
      const response = await api.addTrackingEvent(trackingEvent)

      if (response) {
        await get().getTrackingEvents(trackingEvent.type as TrackingChartAssetTrackingTypeWithoutHeightEnum)

        useTasksStore.getState().setTask({ task: response.activeTask, info: response.infoScreen })
      }
    },
    updateTrackingEventNote: async (trackingEvent, note) => {
      // trackEvent
      const response = await api.updateTrackingEventNote(trackingEvent, note)

      if (response) {
        await get().getTrackingEvents(trackingEvent.type as TrackingChartAssetTrackingTypeWithoutHeightEnum)
        return true
      }
    },
    reset: () => set({ ...initialState }),
  }),
  shallow,
)
