import { isDev } from '@lib/constants'
import { storage } from '@lib/mmkv'
import { useLinkTo } from '@react-navigation/native'
import { useAppStore } from '@stores/app'
import { useAuthStore } from '@stores/auth'
import { useOnboardingStore } from '@stores/onboarding'
import { useTasksStore } from '@stores/tasks'
import { useEffect } from 'react'
import { captureException } from '../error'

export function useDeepLinkingHandler(): void {
  const linkTo = useLinkTo()
  const [getTask] = useTasksStore((state) => [state.getTask])
  const [deepLink, resetDeepLink] = useOnboardingStore((state) => [state.deepLink, state.resetDeepLink])
  const [isRouterInitialized] = useAppStore((state) => [state.isRouterInitialized])
  const [isAuthorized] = useAuthStore((state) => [state.authorized])

  useEffect(() => {
    if (isAuthorized && isRouterInitialized && deepLink) {
      let path: string | undefined

      if (deepLink.includes('myvetahealth://')) {
        path = deepLink.replace('myvetahealth://', '/')
      } else if (deepLink.includes('https://') || (isDev && deepLink.includes('http://'))) {
        const { pathname, search } = new URL(deepLink) || {}
        path = pathname + search
      }

      const skipLinking = path === '/' || path === '/app' || path?.includes('/app/start')

      // use linking only for manual signin, otherwise it's handled by the router automatically
      if (storage.exists('isManualSignin') && path && !skipLinking) {
        try {
          linkTo(path)
        } catch (error) {
          captureException(error, 'deepLinkindHandler/linkTo')
        }
      }

      // fetch active task to show indicator
      if (!path?.includes('/tasks')) getTask()

      resetDeepLink()
      storage.delete('isManualSignin')
    }
  }, [deepLink, isAuthorized, isRouterInitialized])
}
