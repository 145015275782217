import React from 'react'
import styled from 'styled-components/native'

import { Icon } from '../Elements/Icon'
import { Text } from '../Elements/Text'
import { WrapperProps } from './types'

const ContentWrapper = styled.View`
  margin: 0 20px 20px;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
`
const SectionWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`
const IconWrapper = styled.View`
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
`

export function Wrapper({ title, icon, children, color }: WrapperProps): JSX.Element {
  return (
    <ContentWrapper>
      <SectionWrapper>
        <IconWrapper>
          <Icon color={color} icon={icon} size={16} />
        </IconWrapper>
        <Text weight="semiBold" margins={{ left: 10 }}>
          {title}
        </Text>
      </SectionWrapper>
      {children}
    </ContentWrapper>
  )
}

export const OptionsWrapper = styled.View`
  margin: 5px 0;
  padding-left: 20px;
  padding-right: 15px;
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
`
export const Label = styled(Text).attrs({
  size: 'settings',
  weight: 'semiBold',
})``
export const Value = styled(Text).attrs({
  size: 'settings',
  weight: 'medium',
})``
export const MessageWrapper = styled.View`
  flex-direction: row;
  margin: 10px 0;
`
export const Message = styled(Text).attrs({
  weight: 'medium',
  size: 'settings',
})``
export const ButtonWrapper = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`
