import { Colors } from '@vetahealth/fishing-gear/colors'
import noop from 'lodash/noop'
import React, { forwardRef, useState } from 'react'
import { PixelRatio, Platform, TextInput, TextInputProps } from 'react-native'
import styled from 'styled-components/native'

import { MouseOverType } from './types'

export const InputWrapper = styled.View``

const Input = styled(TextInput).attrs(({ theme }) => ({
  selectionColor: theme.colors.primary,
  placeholderTextColor: Colors.gray500,
}))<
  MouseOverType & {
    isFocused: boolean
    numberOfLines: number
  }
>`
  ${({ theme }) => theme.font.weight.semiBold}
  ${Platform.select({ web: 'outline-style: none;' })}
  background-color: ${({ theme, editable }) => (editable ? theme.colors.tertiaryBackground : theme.colors.background)};
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.font.size.medium / PixelRatio.getFontScale()}px;
  height: ${({ theme, numberOfLines = 1 }) =>
    numberOfLines > 1 ? numberOfLines * ((theme.font.size.medium / PixelRatio.getFontScale()) * 1.4) + 44 : 44}px;
  padding: ${({ numberOfLines = 1 }) => (numberOfLines > 1 ? 15 : 11)}px 20px;
  ${({ theme, numberOfLines }) =>
    numberOfLines > 1 && `line-height: ${(theme.font.size.medium / PixelRatio.getFontScale()) * 1.4}px;`}
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  border-width: 2px;
  border-color: ${({ isFocused, isHovered, theme }) =>
    isFocused ? theme.colors.primary : isHovered ? Colors.mauve500 : theme.colors.border};
  ${({ editable, theme }) =>
    !editable &&
    `
    border-color: ${Colors.mauve100};
    color: ${theme.colors.secondaryText};
  `}
`

export const BaseInput = forwardRef<TextInput, TextInputProps>(
  (
    {
      'aria-label': ariaLabel,
      autoFocus = false,
      inputMode,
      maxLength,
      numberOfLines = 1,
      onChange = noop,
      onChangeText = noop,
      onKeyPress,
      onFocus = noop,
      onSubmitEditing = noop,
      placeholder,
      enterKeyHint,
      secureTextEntry,
      textAlignVertical,
      textContentType,
      value = '',
      style,
      selectTextOnFocus,
      selection,
      editable = true,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState<boolean>(autoFocus)
    const [isHovered, setIsHovered] = useState<boolean>(false)

    return (
      <Input
        aria-label={ariaLabel}
        aria-disabled={!editable}
        autoFocus={autoFocus}
        editable={editable}
        isFocused={isFocused}
        isHovered={isHovered}
        inputMode={inputMode}
        maxLength={maxLength}
        multiline={numberOfLines > 1}
        numberOfLines={numberOfLines}
        onBlur={() => {
          setIsFocused(false)
          setIsHovered(false)
        }}
        onChange={onChange}
        onChangeText={onChangeText}
        onFocus={() => {
          setIsFocused(true)
          onFocus?.()
        }}
        onKeyPress={onKeyPress}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onSubmitEditing={onSubmitEditing}
        placeholder={placeholder}
        ref={ref}
        enterKeyHint={enterKeyHint}
        secureTextEntry={secureTextEntry}
        textAlignVertical={textAlignVertical}
        textContentType={textContentType}
        value={value}
        style={style}
        selectTextOnFocus={selectTextOnFocus}
        selection={selection}
      />
    )
  },
)
