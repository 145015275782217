import { MMKV } from 'react-native-mmkv'

const mmkvStorage = new MMKV()

type MMKVStorageKey =
  | 'returningUser'
  | 'locale'
  | 'refreshToken'
  | 'appearance'
  | 'lastSelectedCountryCode'
  | 'deepLink'
  | 'isManualSignin'

export const storage = {
  get: (key: MMKVStorageKey): string | undefined => mmkvStorage.getString(key),
  set: (key: MMKVStorageKey, value: string) => mmkvStorage.set(key, value),
  exists: (key: MMKVStorageKey) => mmkvStorage.contains(key),
  delete: (key: MMKVStorageKey) => mmkvStorage.delete(key),
}
