import { Icon } from '@components/Elements/Icon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Colors } from '@vetahealth/fishing-gear/colors'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import Animated, {
  interpolate,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'
import styled, { useTheme } from 'styled-components/native'

const TabWrapper = styled.View`
  align-items: center;
  overflow: hidden;
  height: 50px;
  width: 60px;
`
const IconWrapper = styled.View`
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const IconBackground = styled(Animated.View)`
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
`
const Badge = styled.View`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: ${Colors.ruby500};
  position: absolute;
  right: -6px;
  top: -4px;
  justify-content: center;
  align-items: center;
`

export function Tab({
  icon,
  isFocused,
  isHovered,
  hasBadge,
}: {
  icon?: IconProp
  isFocused: boolean
  isHovered?: boolean
  hasBadge?: boolean
}) {
  const theme = useTheme()
  const focus = useSharedValue(isFocused ? 1 : 0)

  const focusStyle = useAnimatedStyle(() => ({
    width: interpolate(focus.value, [0, 1], [0, 40]),
    backgroundColor: interpolateColor(
      focus.value,
      [0, 1],
      [theme.colors.tabBarBackground, theme.colors.tabBarHighlight],
    ),
  }))

  useEffect(() => {
    focus.value = withSpring(isFocused ? 1 : 0, {
      overshootClamping: true,
      damping: 30,
      stiffness: 200,
    })
  }, [isFocused])

  const iconColor = isFocused ? theme.colors.primary : theme.colors.text
  const hoverStyle = { opacity: isHovered ? 0.75 : 1 }

  return (
    <TabWrapper>
      <IconWrapper style={hoverStyle}>
        <IconBackground style={[focusStyle]} />
        <View>
          {icon && <Icon icon={icon} color={iconColor} size={18} />}
          {hasBadge && <Badge />}
        </View>
      </IconWrapper>
    </TabWrapper>
  )
}
