import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useAuthStore } from '@stores/auth'
import React from 'react'
import { Tabs } from './Tabs'
import { RouteName } from './routeNames'
import { AddTrackingModal } from './routes/Modals/AddTrackingModal'
import { ReleaseModal } from './routes/Modals/ReleaseModal'
import { TaskInfoModal } from './routes/Modals/TaskInfoModal'
import { UpdateAccountModal } from './routes/Modals/UpdateAccountModal'
import { WelcomeModal } from './routes/Modals/WelcomeModal'
import { Onboarding } from './routes/Onboarding'

const Stack = createNativeStackNavigator()

export function RouterApp() {
  const [isAuthorized] = useAuthStore((state) => [state.authorized])

  return (
    <Stack.Navigator screenOptions={{ headerShown: false, presentation: 'modal', animation: 'slide_from_bottom' }}>
      {isAuthorized ? (
        <>
          {/* Tabs */}
          <Stack.Screen name={RouteName.Tabs} component={Tabs} />
          {/* global modals */}
          <Stack.Screen name={RouteName.UpdateAccountModal} component={UpdateAccountModal} />
          <Stack.Screen name={RouteName.AddTrackingModal} component={AddTrackingModal} />
          <Stack.Screen name={RouteName.TaskInfoModal} component={TaskInfoModal} />
          <Stack.Screen name={RouteName.WelcomeModal} component={WelcomeModal} options={{ gestureEnabled: false }} />
          <Stack.Screen name={RouteName.ReleaseModal} component={ReleaseModal} options={{ gestureEnabled: false }} />
        </>
      ) : (
        <Stack.Screen
          name={RouteName.Onboarding}
          component={Onboarding}
          options={{ presentation: 'card', animationTypeForReplace: !isAuthorized ? 'pop' : 'push' }}
        />
      )}
    </Stack.Navigator>
  )
}
