import { SvgEmpty } from '@assets/svgr/Empty'
import { Content } from '@components/Content'
import { Button } from '@components/Elements/Button'
import { Svg } from '@components/Elements/Image'
import { Text } from '@components/Elements/Text'
import { ModalLayout } from '@components/Layouts'
import { useDebounce } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { useNavigation } from '@react-navigation/native'
import { useTasksStore } from '@stores/tasks'
import React, { useEffect } from 'react'
import styled from 'styled-components/native'

const Wrapper = styled.View`
  padding: 30px;
  padding-top: 0;
`
const ButtonWrapper = styled.View`
  width: 100%;
  margin-top: 20px;
  align-items: center;
`
const TextWrapper = styled.View`
  margin: 10px;
`

export function TaskInfoModal() {
  const t = useI18n()
  const navigation = useNavigation()
  const [info, resetInfo] = useTasksStore((state) => [state.info, state.resetInfo])

  useEffect(() => {
    return resetInfo
  }, [])

  const handleClose = useDebounce(() => {
    trackEvent(TrackingEvent.taskInfoContinuePressed, { ref: info?.ref })
    navigation.goBack()
  })

  return (
    <ModalLayout title={t('screen.info')} hasCancel={false}>
      <Wrapper>
        {info ? (
          <>
            <Content hideActions content={info} />
            <ButtonWrapper>
              <Button label={t('actions.continue')} onPress={handleClose} />
            </ButtonWrapper>
          </>
        ) : (
          <>
            <Svg>
              <SvgEmpty />
            </Svg>
            <TextWrapper>
              <Text isCentered weight="semiBold">
                {t('error.noContent')}
              </Text>
            </TextWrapper>
            <ButtonWrapper>
              <Button label={t('actions.close')} onPress={handleClose} />
            </ButtonWrapper>
          </>
        )}
      </Wrapper>
    </ModalLayout>
  )
}
