import { SvgProsper } from '@assets/Prosper'
import { IconButton } from '@components/Elements/Button'
import { Expired, Intro, Otp, Phone } from '@components/Onboarding'
import { isWeb } from '@lib/constants'
import { storage } from '@lib/mmkv'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { OnboardingStep, getOnboardingFlow, useOnboardingStore } from '@stores/onboarding'
import React, { useEffect } from 'react'
import styled from 'styled-components/native'

const Wrapper = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${isWeb ? 10 : 0}px;
`
const HeaderWrapper = styled.View`
  position: absolute;
  left: 10px;
`
const ContentWrapper = styled.View`
  flex: 1;
`
const Prosper = styled(SvgProsper)`
  align-self: center;
  margin-top: 10px;
`

const stepToComponent: Record<OnboardingStep, React.ElementType> = {
  [OnboardingStep.INTRO]: Intro,
  [OnboardingStep.PHONE]: Phone,
  [OnboardingStep.OTP]: Otp,
  [OnboardingStep.EXPIRED]: Expired,
}

function BackButton({
  steps,
  back,
}: {
  steps: OnboardingStep[]
  back: () => void
}) {
  if (steps.length <= 1) return null

  return (
    <HeaderWrapper>
      <IconButton icon="arrow-left" variant="plain" onPress={back} />
    </HeaderWrapper>
  )
}

export function Onboarding() {
  const isReturningUser = storage.exists('returningUser')
  const [steps, back, replace, reset, deepLinkParams] = useOnboardingStore((state) => [
    state.steps,
    state.back,
    state.replace,
    state.reset,
    state.deepLinkParams,
  ])

  const currentStep = steps[steps.length - 1]

  useEffect(() => {
    replace(getOnboardingFlow(deepLinkParams))

    return reset
  }, [])

  useEffect(() => {
    if (currentStep) {
      trackEvent(TrackingEvent.onboardingViewed, { view: currentStep })
    }
  }, [currentStep])

  if (!currentStep) return null

  const Component = stepToComponent[currentStep]

  return (
    <Wrapper>
      <ContentWrapper>
        <Prosper width="100%" height={26} strokeWidth={40} />
        <BackButton steps={steps} back={back} />
        <Component params={{ ...deepLinkParams, isReturningUser }} />
      </ContentWrapper>
    </Wrapper>
  )
}
