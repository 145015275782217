import noop from 'lodash/noop'
import React from 'react'
import styled from 'styled-components/native'

import { BaseButton, ButtonIcon, ButtonLabel, ButtonLoading } from './styles'
import { ButtonProps } from './types'

const Wrapper = styled.View<{ fullWidth: boolean }>`
  flex-direction: row;
  ${({ fullWidth }) => fullWidth && 'width: 100%; justify-content: center;'}
`
const ButtonWrapper = styled(BaseButton)<{ fullWidth?: boolean }>`
  flex-direction: row;
  padding: 0px 40px;
  height: 46px;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  ${({ fullWidth }) => fullWidth && 'flex: 1;'}
  ${({ isDisabled }) => !isDisabled && 'opacity: 0.85;'}
`
const IconWrapper = styled.View`
  margin-left: 5px;
`
const LoadingWrapper = styled.View`
  margin-right: 10px;
`

export function Button({
  label,
  isDisabled = false,
  onPress = noop,
  variant = 'primary',
  icon,
  isLoading = false,
  fullWidth = false,
  color,
}: ButtonProps): React.ReactElement {
  const opacity = ['secondary', 'plain'].includes(variant) ? 0.65 : 0.75

  return (
    <Wrapper fullWidth={fullWidth}>
      <ButtonWrapper
        role="button"
        onPress={isLoading ? noop : onPress}
        fullWidth={fullWidth}
        isDisabled={isDisabled}
        variant={variant}
        hoverStyle={{ opacity }}
        render={() => (
          <>
            {isLoading && (
              <LoadingWrapper>
                <ButtonLoading size="small" variant={variant} isDisabled={isDisabled} />
              </LoadingWrapper>
            )}
            <ButtonLabel numberOfLines={1} color={color} variant={variant} isDisabled={isDisabled}>
              {label}
            </ButtonLabel>
            {icon && (
              <IconWrapper>
                <ButtonIcon icon={icon} size={20} isDisabled={isDisabled} variant={variant} />
              </IconWrapper>
            )}
          </>
        )}
      />
    </Wrapper>
  )
}
