import { DatoAssets } from '@components/Content'
import { DownloadApp } from '@components/DownloadApp'
import { ScreenLayout } from '@components/Layouts'
import { useI18n } from '@lib/i18n'
import { useAuthStore } from '@stores/auth'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/native'

const Wrapper = styled.View`
  padding: ${({ theme }) => `0 ${theme.width < 400 ? 20 : 40}`}px;
`

export function Program() {
  const t = useI18n()
  const [getWelcome, welcome] = useAuthStore((state) => [state.getWelcome, state.welcome])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (!welcome) {
      setLoading(true)
      getWelcome().finally(() => setLoading(false))
    }
  }, [welcome])

  return (
    <ScreenLayout title={t('screen.program')} hasBack isLoading={isLoading}>
      <Wrapper>
        {welcome && <DatoAssets assets={welcome.content} />}
        <DownloadApp />
      </Wrapper>
    </ScreenLayout>
  )
}
