import debounce from 'lodash/debounce'
import { useCallback } from 'react'

export function useDebounce<Method extends (...args: any[]) => void>(
  method: Method,
  dependencies: any[] = [],
  offset = 300,
) {
  return useCallback(debounce(method, offset), dependencies)
}
