import { Colors } from '@vetahealth/fishing-gear/colors'
import React, { useEffect } from 'react'
import Animated, {
  Easing,
  cancelAnimation,
  runOnJS,
  useAnimatedProps,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { withPause } from 'react-native-redash'
import { Circle, G, Svg } from 'react-native-svg'
import styled, { useTheme } from 'styled-components/native'

import { isWeb } from '@lib/constants'

const SIZE = 30

const Wrapper = styled.View`
  margin-right: -10px;
  align-items: center;
  justify-content: center;
`
const FullCircle = styled.View<{ isColored: boolean }>`
  height: ${SIZE / 3}px;
  width: ${SIZE / 3}px;
  border-radius: 10px;
  background-color: ${({ isColored, theme }) => (isColored ? theme.colors.primary : Colors.mauve300)};
  position: absolute;
`

const AnimatedCircle = Animated.createAnimatedComponent(Circle)

export function Dot({
  index,
  currentIndex,
  isPaused,
  goForward,
}: {
  index: number
  currentIndex: number
  isPaused: Animated.SharedValue<boolean>
  goForward: () => void
}): JSX.Element {
  const theme = useTheme()
  const circleLength = SIZE * 1.75
  const radius = circleLength / (2 * Math.PI)
  const isActive = index === currentIndex

  const progress = useSharedValue(0)
  const animatedProps = useAnimatedProps(() => ({
    ...(isWeb && { transform: '' }), // to avoid continous errors on web
    strokeDashoffset: circleLength * (1 - progress.value),
  }))

  useEffect(() => {
    cancelAnimation(progress)

    if (currentIndex > index) progress.value = 1
    if (currentIndex <= index) progress.value = 0

    if (currentIndex === index) {
      progress.value = withPause(
        withTiming(1, { duration: 10000, easing: Easing.linear }, (finished) => {
          if (finished) runOnJS(goForward)()
        }),
        isPaused,
      )
    }
  }, [index, currentIndex])

  return (
    <Wrapper>
      <Svg width={SIZE} height={SIZE} fillOpacity={0} strokeOpacity={isActive ? 1 : 0}>
        <G rotation="-90" origin={`${SIZE / 2}, ${SIZE / 2}`}>
          <AnimatedCircle
            cy={SIZE / 2}
            cx={SIZE / 2}
            r={radius}
            stroke={theme.colors.primary}
            strokeWidth={2}
            strokeDasharray={circleLength}
            strokeLinecap="round"
            animatedProps={animatedProps}
          />
        </G>
      </Svg>
      <FullCircle isColored={index <= currentIndex} />
    </Wrapper>
  )
}
