import React, { useState } from 'react'

import { ScreenLayout } from '../../Layouts'
import { SelectButton } from '../Button'
import { Checkbox, RadioButton, RadioGroup, Switch } from '../Toggles'
import { Box, Content, Label, Row } from './styles'

export function TogglesPlayground() {
  const [radio1, setRadio1] = useState<string | number | undefined>()
  const [radio2, setRadio2] = useState<string | number | undefined>()
  const [radio3, setRadio3] = useState<string | number | undefined>('2')
  const [checkbox1, setCheckbox1] = useState(false)
  const [checkbox2, setCheckbox2] = useState(true)
  const [checkbox3, setCheckbox3] = useState(true)
  const [checkbox4, setCheckbox4] = useState(false)
  const [checkbox5, setCheckbox5] = useState(false)
  const [select1, setSelect1] = useState(false)
  const [select2, setSelect2] = useState(false)
  const [switch1, setSwitch1] = useState(false)
  const [switch2, setSwitch2] = useState(true)

  return (
    <ScreenLayout title="Toggles" hasBack>
      <Content>
        <Box>
          <Label>RadioButton</Label>
          <RadioButton
            value={radio1}
            onChange={setRadio1}
            items={[
              { label: 'Radio 1', value: '1' },
              { label: 'Radio 2', value: '2' },
              { label: 'Radio 3', value: '3' },
            ]}
          />
        </Box>
        <Box>
          <Label>SelectButton</Label>
          <SelectButton
            label="This is a SelectButton (checkbox)"
            isChecked={select1}
            onPress={() => setSelect1((prevState) => !prevState)}
            type="checkbox"
          />
          <SelectButton
            label="This is a SelectButton (radio)"
            isChecked={select2}
            onPress={() => setSelect2((prevState) => !prevState)}
            type="radio"
          />
        </Box>
        <Box>
          <Label>Switch</Label>
          <Row>
            <Switch isChecked={switch1} onChange={setSwitch1} label="This is a Switch" />
          </Row>
        </Box>
        <Box>
          <Label>Switch disabled prechecked</Label>
          <Row>
            <Switch isChecked={switch2} onChange={setSwitch2} label="This is a Switch" isDisabled />
          </Row>
        </Box>
        <Box>
          <Label>Checkbox</Label>
          <Checkbox isChecked={checkbox1} onChange={setCheckbox1} label="This is a Checkbox" />
        </Box>
        <Box>
          <Label>Checkbox prechecked</Label>
          <Checkbox isChecked={checkbox2} onChange={setCheckbox2} label="This is a Checkbox" />
        </Box>
        <Box>
          <Label>Checkbox disabled</Label>
          <Checkbox isChecked={checkbox3} onChange={setCheckbox3} label="This is a Checkbox" isDisabled />
          <Checkbox isChecked={checkbox4} onChange={setCheckbox4} label="This is a Checkbox" isDisabled />
        </Box>
        <Box>
          <Label>Checkbox with error</Label>
          <Checkbox
            isChecked={checkbox5}
            onChange={setCheckbox5}
            label="This is a Checkbox"
            error="This checkbox needs to be checked"
          />
        </Box>
        <Box>
          <Label>RadioGroup</Label>
          <RadioGroup
            value={radio2}
            onChange={setRadio2}
            items={[
              {
                label: 'Radio 1',
                value: '1',
              },
              { label: 'Radio 2', value: '2' },
            ]}
          />
        </Box>
        <Box>
          <Label>RadioGroup prechecked</Label>
          <RadioGroup
            value={radio3}
            onChange={setRadio3}
            items={[
              { label: 'Radio 1', value: '1' },
              { label: 'Radio 2', value: '2' },
            ]}
          />
        </Box>
      </Content>
    </ScreenLayout>
  )
}
