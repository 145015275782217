import { Chat } from '@components/Chat'
import { ScreenLayout } from '@components/Layouts'
import { useI18n } from '@lib/i18n'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useChatStore } from '@stores/chat'
import React, { useEffect } from 'react'
import { useTheme } from 'styled-components/native'
import { RouteName } from '../../routeNames'

export function Messages() {
  const theme = useTheme()
  const t = useI18n()
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const [getChatMessages, markChatMessagesAsRead] = useChatStore((state) => [
    state.getChatMessages,
    state.markChatMessagesAsRead,
  ])

  useEffect(() => {
    if (isFocused) {
      getChatMessages().then(markChatMessagesAsRead)
    }
  }, [isFocused])

  return (
    <ScreenLayout
      title={t('screen.messages')}
      action={{
        icon: ['fad', 'pen-to-square'],
        variant: 'plain',
        color: theme.colors.primary,
        onPress: () => navigation.navigate(RouteName.NewMessageModal),
      }}
      isStatic
    >
      <Chat />
    </ScreenLayout>
  )
}
