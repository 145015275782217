import { NavButton } from '@components/Elements/Button'
import { ScreenLayout } from '@components/Layouts'
import { Profile } from '@components/Settings'
import { api } from '@lib/api'
import { isProd } from '@lib/constants'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { useNavigation } from '@react-navigation/native'
import { useOnboardingStore } from '@stores/onboarding'
import { Colors } from '@vetahealth/fishing-gear/colors'
import React from 'react'
import styled, { useTheme } from 'styled-components/native'
import { RouteName } from '../../routeNames'

const Wrapper = styled.View`
  flex-grow: 1;
  padding: 0 20px;
`
const DevWrapper = styled.View`
  margin-top: 30px;
`

export function AccountList() {
  const theme = useTheme()
  const t = useI18n()
  const navigation = useNavigation()
  const [setDeepLink] = useOnboardingStore((state) => [state.setDeepLink])

  function handleSignOut() {
    trackEvent(TrackingEvent.signOutPressed)
    api.signOut().then(() => {
      setDeepLink()
    })
  }

  return (
    <ScreenLayout
      title={t('screen.account')}
      action={{
        icon: ['fad', 'arrow-right-from-bracket'],
        variant: 'plain',
        color: theme.colors.primary,
        onPress: handleSignOut,
      }}
    >
      <Wrapper>
        <Profile />
        <NavButton
          label={t('settings.program')}
          icon={['fad', 'folder-medical']}
          onPress={() => navigation.navigate(RouteName.Program)}
          color={Colors.ruby500}
        />
        <NavButton
          label={t('settings.settings')}
          icon={['fad', 'mobile']}
          onPress={() => navigation.navigate(RouteName.Settings)}
          color={Colors.mauve400}
        />
        <NavButton
          label={t('settings.support')}
          icon={['fas', 'envelope']}
          onPress={() => navigation.navigate(RouteName.ContactUs)}
          color={Colors.mint200}
        />
        <NavButton
          label={t('settings.about')}
          icon={['fal', 'seedling']}
          onPress={() => navigation.navigate(RouteName.About)}
          color={Colors.mauve600}
        />
        {!isProd && (
          <DevWrapper>
            <NavButton
              label="Preview"
              icon={['fad', 'wand-magic-sparkles']}
              onPress={() => navigation.navigate(RouteName.Preview)}
              color={Colors.teal400}
            />
            <NavButton
              label="Components"
              icon={['fad', 'brackets-curly']}
              onPress={() => navigation.navigate(RouteName.Playground)}
              color={Colors.plum500}
            />
          </DevWrapper>
        )}
      </Wrapper>
    </ScreenLayout>
  )
}
