import debounce from 'lodash/debounce'
import React, { useCallback, useState } from 'react'
import { Platform } from 'react-native'
import styled from 'styled-components/native'

import { SvgUpdate } from '@assets/svgr/Update'
import { isWeb } from '@lib/constants'
import { useI18n } from '@lib/i18n'
import { openExternalLink } from '@lib/linking'

import { Button } from '../Elements/Button'
import { Svg } from '../Elements/Image'
import { ButtonWrapper, Hint, Message, ScrollView, Title, Wrapper } from './styles'

const Illustration = styled(SvgUpdate)`
  align-self: center;
  margin: 20px;
`

export function OutdatedApp(): JSX.Element {
  const t = useI18n()
  const [isHintVisible, setHintVisibility] = useState<boolean>(false)

  const handleUpdate = useCallback(
    debounce(async () => {
      const url = Platform.select<string>({
        android: 'market://details?id=myvetahealth.prosper.app',
        ios: 'itms-apps://itunes.apple.com/us/app/id1576826785?mt=8',
      })

      if (url) {
        const isLinkable = await openExternalLink(url)
        if (isLinkable) return
      }

      setHintVisibility(true)
    }, 300),
    [],
  )

  return (
    <ScrollView>
      <Wrapper>
        <Title>{t('update.title')}</Title>
        <Svg>
          <Illustration />
        </Svg>

        {isWeb ? (
          <>
            <Message>{t('update.messageWeb')}</Message>
            <ButtonWrapper>
              <Button onPress={() => window?.location.reload()} label={t('update.reload')} />
            </ButtonWrapper>
          </>
        ) : (
          <>
            <Message>{t('update.message')}</Message>
            <ButtonWrapper>
              <Button onPress={handleUpdate} label={t('update.update')} />
            </ButtonWrapper>
            {isHintVisible && (
              <Hint>{`${Platform.select({
                android: 'Playstore',
                ios: 'App Store',
              })} > Prosper > ${t('update.update')}`}</Hint>
            )}
          </>
        )}
      </Wrapper>
    </ScrollView>
  )
}
