import { Platform } from 'react-native'
import DeviceInfo from 'react-native-device-info'
import { getCountry, getTimeZone } from 'react-native-localize'

import { config } from './config'

export const bundleId = DeviceInfo.getBundleId()
export const deviceCountry = getCountry()
export const timeZone = getTimeZone()

export const appVersion: string = require('../../package.json').version
export const tunaApiVersion: string = require('@vetahealth/tuna-can-api/package.json').version

export const isDev = ['development', 'local'].includes(config.stage)
export const isProd = config.stage === 'production'
export const isWeb = Platform.OS === 'web'
export const isAndroid = Platform.OS === 'android'

export const tabBarHeight = 60
