import React, { ReactNode, useEffect } from 'react'
import { ViewStyle } from 'react-native'
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated'
import styled, { DefaultTheme } from 'styled-components/native'
import { useTheme } from 'styled-components/native'

import { Pressable } from '@components/Elements/Pressable'
import { Markdown } from '@components/Markdown'

import { KeyboardView } from '../Elements/KeyboardView'
import { HintText, Text } from '../Elements/Text'

const Wrapper = styled.View`
  padding: ${({ theme }) => `10px ${theme.width < 380 ? 20 : 40}px`};
  min-height: 100%;
  justify-content: center;
`

export function ContentWrapper({ children }: { children: ReactNode }) {
  const style = {
    flexGrow: 1,
    paddingBottom: 40,
  }

  return (
    <KeyboardView contentContainerStyle={style} extraScrollHeight={20}>
      <Wrapper>{children}</Wrapper>
    </KeyboardView>
  )
}

export const Title = styled(Text).attrs({
  weight: 'bold',
  size: 'heading2',
  margins: { top: 10 },
})`
  line-height: ${({ theme }) => Math.round(theme.font.size.heading2 * 1.3)}px;
`
export const Label = styled(Text).attrs({
  weight: 'semiBold',
  margins: { top: 26, bottom: 15 },
})``

export const Description = styled(Text).attrs({
  weight: 'medium',
  margins: { top: 16, bottom: 40 },
})``

export const ButtonWrapper = styled.View`
  margin-top: 30px;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;
`

export const OnboardingAnimationWrapper = styled(Animated.View)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.background};
`
export function useOnboardingAnimation(): ViewStyle {
  const animation = useSharedValue(0)
  const style = useAnimatedStyle(() => ({
    opacity: animation.value,
    transform: [{ translateY: interpolate(animation.value, [0, 1], [10, 0]) }],
  }))

  useEffect(() => {
    animation.value = withSpring(1, {
      damping: 100,
      mass: 1,
    })
  }, [])

  return style
}

export const HintButton = styled(Pressable)`
  margin-bottom: 10px;
`
export const Hint = styled(HintText).attrs(({ theme }: { theme: DefaultTheme }) => ({
  color: theme.colors.primary,
  weight: 'semiBold',
  size: 'settings',
}))``

const ErrorWrapper = styled.View`
  padding: 10px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.errorBackground};
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
`
export function ErrorMessage({ message }: { message: string }): JSX.Element {
  const theme = useTheme()

  return (
    <ErrorWrapper>
      <Markdown
        defaultFontSize="small"
        defaultWeight="semiBold"
        paragraphColor={theme.colors.error}
        withoutParagraphSpacing
        text={message}
      />
    </ErrorWrapper>
  )
}
