import React from 'react'
import { useTheme } from 'styled-components/native'

import { Size } from './types'

export function Svg({
  children,
  size = 'medium',
}: {
  children: React.ReactElement
  size?: Size
}): JSX.Element {
  const theme = useTheme()

  return React.cloneElement(children, {
    width: '100%',
    height: {
      small: theme.width * 0.45,
      medium: theme.height / 3,
      large: theme.height / 2,
    }[size],
  })
}
