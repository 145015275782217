import { useIsFocused, useNavigation } from '@react-navigation/native'
import { ChatMessage } from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import React, { useEffect, useRef, useState } from 'react'
import { ScrollView, View } from 'react-native'
import styled from 'styled-components/native'

import { SvgStartChat } from '@assets/svgr/StartChat'
import { Button } from '@components/Elements/Button'
import { Empty } from '@components/Elements/Empty'
import { Loading } from '@components/Elements/Loading'
import { Text } from '@components/Elements/Text'
import { useBottom, useDebounce } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { MESSAGE_LIMIT, useChatStore } from '@stores/chat'

import { RouteName } from '../../router/routeNames'
import { Message } from './Message'

const SectionWrapper = styled.View`
  margin-top: 10px;
  align-items: center;
`
const SectionLine = styled.View`
  position: absolute;
  top: 10px;
  width: 80%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
`
const SectionTextWrapper = styled.View`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 0 20px;
`
const LoadingWrapper = styled.View`
  position: absolute;
  top: 0;
  width: 100%;
  align-self: center;
`
const ButtonWrapper = styled.View`
  margin-top: 20px;
  align-self: center;
`

function Section({ day }: { day: number }): JSX.Element {
  const t = useI18n()

  return (
    <SectionWrapper>
      <SectionLine />
      <SectionTextWrapper>
        <Text size="small" weight="semiBold">
          {day === dayjs().startOf('day').valueOf() ? t('date.today') : dayjs(day).format('LL')}
        </Text>
      </SectionTextWrapper>
    </SectionWrapper>
  )
}

export function Chat(): JSX.Element {
  const t = useI18n()
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const paddingBottom = useBottom(0, 30)
  const prevMessages = useRef<ChatMessage[]>([])
  const scrollRef = useRef<ScrollView>(null)
  const [isLoading, setLoading] = useState(false)
  const [chatMessages, getChatMessages, markChatMessagesAsRead] = useChatStore((state) => [
    state.chatMessages,
    state.getChatMessages,
    state.markChatMessagesAsRead,
  ])

  const ascendingChatMessages = orderBy(chatMessages, ['createdAt'], ['asc'])

  const handleGetMessages = useDebounce(
    (scrollOffset: number) => {
      const chatMessageCount = Object.keys(chatMessages).length
      if (scrollOffset <= 0 && chatMessageCount >= MESSAGE_LIMIT) {
        setLoading(true)
        getChatMessages(chatMessageCount).finally(() => setLoading(false))
      }
    },
    [chatMessages],
  )

  useEffect(() => {
    if (isFocused) {
      if (prevMessages.current.length) {
        if (prevMessages.current.length < ascendingChatMessages.length) {
          const prevLastMessage = prevMessages.current[prevMessages.current.length - 1]
          const ascendingLastMessage = ascendingChatMessages[ascendingChatMessages.length - 1]
          const isNewMessage = dayjs(ascendingLastMessage.createdAt).isAfter(dayjs(prevLastMessage.createdAt))

          if (isNewMessage) {
            setTimeout(() => scrollRef.current?.scrollToEnd({ animated: false }), 100)

            if (ascendingLastMessage.senderId !== ascendingLastMessage.userId) {
              markChatMessagesAsRead()
            }
          }
        }
      } else {
        setTimeout(() => scrollRef.current?.scrollToEnd({ animated: false }), 100)
      }

      prevMessages.current = ascendingChatMessages
    }
  }, [isFocused, ascendingChatMessages.length])

  return (
    <ScrollView
      ref={scrollRef}
      scrollEventThrottle={16}
      onScroll={(event) => handleGetMessages(event.nativeEvent.contentOffset.y)}
      maintainVisibleContentPosition={{ minIndexForVisible: 0 }}
      contentContainerStyle={{ paddingBottom }}
    >
      {ascendingChatMessages.length > 0 ? (
        Object.entries(groupBy(ascendingChatMessages, (item) => dayjs(item.createdAt).startOf('day').valueOf())).map(
          ([day, messages]) => (
            <View key={day}>
              <Section day={Number(day)} />
              {messages.map((message) => (
                <Message key={message.id} message={message} />
              ))}
            </View>
          ),
        )
      ) : (
        <Empty message={t('messaging.noMessages')} svg={<SvgStartChat />} />
      )}
      {isLoading && (
        <LoadingWrapper>
          <Loading size="small" />
        </LoadingWrapper>
      )}

      <ButtonWrapper>
        <Button
          variant={ascendingChatMessages.length ? 'secondary' : 'primary'}
          onPress={() => navigation.navigate(RouteName.NewMessageModal)}
          label={t('messaging.writeMessage')}
        />
      </ButtonWrapper>
    </ScrollView>
  )
}
