import { Dialog } from '@components/Elements/Dialog'
import { RadioGroup } from '@components/Elements/Toggles'
import { useI18n } from '@lib/i18n'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { SupportedLocaleEnum } from '@vetahealth/tuna-can-api'
import i18next from 'i18next'
import React, { useState } from 'react'
import styled from 'styled-components/native'
import { RouteName } from '../../routeNames'
import { PreviewSearch } from './PreviewSearch'
import { PreviewTask } from './PreviewTask'

const DialogContent = styled.View`
  margin-top: 20px;
`

const languageLabels: Record<SupportedLocaleEnum, string> = {
  [SupportedLocaleEnum.EnUs]: 'English (US)',
  [SupportedLocaleEnum.Es419]: 'Español (América Latina)',
  [SupportedLocaleEnum.EsUs]: 'Español (US)',
}

const Stack = createNativeStackNavigator()

export function Preview() {
  const t = useI18n()
  const [isVisible, setVisibility] = useState<boolean>(false)
  const [locale, setLocale] = useState<`${SupportedLocaleEnum}`>(i18next.language as SupportedLocaleEnum)

  const handlePress = () => setVisibility(true)

  return (
    <>
      <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={RouteName.PreviewSearch}>
        <Stack.Screen name={RouteName.PreviewSearch}>
          {(props) => <PreviewSearch {...props} locale={locale} onPress={handlePress} />}
        </Stack.Screen>
        <Stack.Screen name={RouteName.PreviewTask}>
          {(props) => <PreviewTask {...props} locale={locale} onPress={handlePress} />}
        </Stack.Screen>
      </Stack.Navigator>

      <Dialog
        title={t('settings.popup.changeLanguage')}
        isVisible={isVisible}
        primaryAction={{
          label: t('actions.close'),
          onPress: () => setVisibility(false),
        }}
      >
        <DialogContent>
          <RadioGroup
            value={locale}
            onChange={(supportedLocale) => {
              setLocale(supportedLocale as SupportedLocaleEnum)
              setVisibility(false)
            }}
            items={Object.entries(languageLabels).map(([value, label]) => ({
              value,
              label,
            }))}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
