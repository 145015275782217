import { useNavigation } from '@react-navigation/native'
import React from 'react'

import { useDebounce } from '@lib/hooks'

import { Button, IconButton } from '../Elements/Button'
import { Loading } from '../Elements/Loading'
import {
  ActionWrapper,
  Header,
  HeaderWrapper,
  LoadingWrapper,
  ScrollContent,
  StaticContent,
  Title,
  TitleWrapper,
  Wrapper,
} from './styles'
import { ScreenLayoutProps } from './types'

export function ScreenLayout({ title, children, isStatic, action, hasBack, isLoading = false }: ScreenLayoutProps) {
  const navigation = useNavigation()
  const Content: React.ElementType = isStatic ? StaticContent : ScrollContent

  const handleBack = useDebounce(navigation.goBack)

  return (
    <Wrapper>
      <HeaderWrapper>
        {hasBack && (
          <ActionWrapper>
            <IconButton icon={['fas', 'arrow-left']} variant="plain" onPress={handleBack} />
          </ActionWrapper>
        )}
        <Header>
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
          {action && (
            <ActionWrapper>
              {action.label ? (
                <Button
                  icon={action.icon}
                  label={action.label}
                  variant={action.variant || 'secondary'}
                  onPress={action.onPress}
                />
              ) : (
                action.icon && (
                  <IconButton
                    icon={action.icon}
                    variant={action.variant || 'secondary'}
                    onPress={action.onPress}
                    color={action.color}
                  />
                )
              )}
            </ActionWrapper>
          )}
        </Header>
      </HeaderWrapper>
      <Content>
        {isLoading ? (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        ) : (
          children
        )}
      </Content>
    </Wrapper>
  )
}
