import { Colors } from '@vetahealth/fishing-gear/colors'
import noop from 'lodash/noop'
import React, { useEffect } from 'react'
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated'
import styled, { useTheme } from 'styled-components/native'
import { Icon } from '../Icon'
import { Pressable } from '../Pressable'
import { Text } from '../Text'
import { SelectButtonProps } from './types'

const StyledPressable = styled(Pressable)<{
  hasTopBorder: boolean
  hasBottomBorder: boolean
}>`
  padding: 10px 0;
  width: 100%;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme, hasBottomBorder }) => (hasBottomBorder ? theme.colors.border : 'transparent')};
  border-top-color: ${({ theme, hasTopBorder }) => (hasTopBorder ? theme.colors.border : 'transparent')};
  ${({ noFeedback }) => noFeedback && 'opacity: 0.5;'}
`
const Wrapper = styled.View`
  flex-direction: row;
  align-items: center;
`
const IconContainer = styled(Animated.View)`
  margin-left: 10px;
  border-width: 2px;
  height: 22px;
  width: 22px;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  align-items: center;
`
const IndicatorWrapper = styled(Animated.View)``
const IndicatorDot = styled.View`
  height: 18px;
  width: 18px;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  border-width: 3px;
  border-color: ${({ theme }) => theme.colors.tertiaryBackground};
`
const LabelWrapper = styled.View`
  flex: 1;
  justify-content: center;
`

export function SelectButton({
  label,
  onPress = noop,
  isChecked,
  type,
  hasTopBorder = false,
  hasBottomBorder = false,
  isDisabled = false,
  noFeedback = false,
}: SelectButtonProps): JSX.Element {
  const theme = useTheme()
  const animation = useSharedValue(isChecked ? 1 : 0)

  const iconContainerStyle = useAnimatedStyle(() => ({
    borderColor: interpolateColor(animation.value, [0, 1], [theme.colors.toggle, theme.colors.primary]),
    backgroundColor: interpolateColor(animation.value, [0, 1], ['transparent', theme.colors.primary]),
  }))

  const indicatorWrapperStyle = useAnimatedStyle(() => ({
    opacity: animation.value,
  }))

  useEffect(() => {
    animation.value = withSpring(isChecked ? 1 : 0)
  }, [isChecked])

  return (
    <StyledPressable
      onPress={onPress}
      hasTopBorder={hasTopBorder}
      hasBottomBorder={hasBottomBorder}
      noFeedback={noFeedback}
      isDisabled={isDisabled}
      render={() => (
        <Wrapper>
          <LabelWrapper>
            <Text margins={{ right: 10 }} weight="medium" size="settings">
              {label}
            </Text>
          </LabelWrapper>
          <IconContainer style={iconContainerStyle}>
            <IndicatorWrapper style={indicatorWrapperStyle}>
              {type === 'radio' ? <IndicatorDot /> : <Icon color={Colors.white} icon={['fas', 'check']} size={12} />}
            </IndicatorWrapper>
          </IconContainer>
        </Wrapper>
      )}
    />
  )
}
