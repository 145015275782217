import { DatoAssets } from '@components/Content/Assets'
import { DownloadApp } from '@components/DownloadApp'
import { Button } from '@components/Elements/Button'
import { ModalLayout } from '@components/Layouts'
import { useDebounce } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { useNavigation } from '@react-navigation/native'
import { useAppStore } from '@stores/app'
import { useAuthStore } from '@stores/auth'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/native'

const Wrapper = styled.View`
  padding: ${({ theme }) => `10px ${theme.width < 380 ? 20 : 40}px`};
`
const ButtonWrapper = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
`

export function WelcomeModal() {
  const t = useI18n()
  const navigation = useNavigation()
  const [releases] = useAppStore((state) => [state.releases])
  const [user, updateAccount, getWelcome, welcome] = useAuthStore((state) => [
    state.user,
    state.updateAccount,
    state.getWelcome,
    state.welcome,
  ])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getWelcome().finally(() => setLoading(false))
    trackEvent(TrackingEvent.welcomeOpened)
  }, [])

  function updateFlags(): void {
    updateAccount({
      flags: { ...user?.flags, welcome: false, ...(releases.length && { viewedReleaseInfo: [releases[0].id] }) },
    })
  }

  const handleCancelPressed = useDebounce(() => {
    updateFlags()
    trackEvent(TrackingEvent.welcomeCancelPressed)
  }, [user, releases])

  const handleGotItPressed = useDebounce(() => {
    updateFlags()
    navigation.goBack()
    trackEvent(TrackingEvent.welcomeGotItPressed)
  }, [user, releases])

  return (
    <ModalLayout title={''} onCancel={handleCancelPressed} isLoading={isLoading}>
      <Wrapper>
        {welcome && (
          <>
            <DatoAssets assets={welcome.content} />
            <DownloadApp />
            <ButtonWrapper>
              <Button onPress={handleGotItPressed} label={t('actions.gotIt')} />
            </ButtonWrapper>
          </>
        )}
      </Wrapper>
    </ModalLayout>
  )
}
