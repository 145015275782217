import { isDev } from '@lib/constants'
import { RouteName } from './routeNames'

export const routeConfig = {
  prefixes: ['myvetahealth://', 'https://prosper.veta.health', 'https://dolphin-staging.veta.health'],
  config: {
    screens: {
      [RouteName.App]: {
        path: '/app',
        initialRouteName: RouteName.Tabs,
        screens: {
          [RouteName.Onboarding]: 'start',
          [RouteName.AddTrackingModal]: 'add-tracking/:trackingType?',
          [RouteName.WelcomeModal]: 'welcome',
          [RouteName.Tabs]: {
            path: '',
            screens: {
              [RouteName.Task]: {
                path: 'tasks/:taskRef?',
                initialRouteName: RouteName.ActiveTask,
                screens: {
                  [RouteName.ActiveTask]: '',
                  [RouteName.TaskInfoModal]: 'info',
                },
              },
              [RouteName.Tracking]: {
                path: 'tracking',
                initialRouteName: RouteName.TrackingList,
                screens: {
                  [RouteName.TrackingList]: '',
                  [RouteName.TrackingByType]: {
                    path: '/:trackingType',
                    initialRouteName: RouteName.TrackingDetails,
                    screens: {
                      [RouteName.TrackingDetails]: '',
                      [RouteName.TrackingInsights]: 'insights',
                      [RouteName.TrackingEventModal]: 'event',
                    },
                  },
                },
              },
              [RouteName.Messaging]: {
                path: 'messaging',
                initialRouteName: RouteName.Messages,
                screens: {
                  [RouteName.Messages]: '',
                  [RouteName.NewMessageModal]: 'new',
                },
              },
              [RouteName.Account]: {
                path: 'account',
                initialRouteName: RouteName.AccountList,
                screens: {
                  [RouteName.AccountList]: '',
                  [RouteName.Program]: 'program',
                  [RouteName.ContactUs]: 'contact-us',
                  [RouteName.About]: {
                    path: 'about',
                    initialRouteName: RouteName.AboutList,
                    screens: {
                      [RouteName.AboutList]: '',
                      [RouteName.Licenses]: 'licenses',
                      [RouteName.Releases]: 'releases',
                    },
                  },
                  [RouteName.Settings]: {
                    path: 'settings',
                    initialRouteName: RouteName.SettingsList,
                    screens: {
                      [RouteName.SettingsList]: '',
                      [RouteName.Notifications]: 'notifications',
                      [RouteName.Units]: 'units',
                      [RouteName.Language]: 'language',
                      [RouteName.Appearance]: 'appearance',
                    },
                  },
                  [RouteName.Preview]: {
                    path: 'preview',
                    initialRouteName: RouteName.PreviewSearch,
                    screens: {
                      [RouteName.PreviewSearch]: '',
                      [RouteName.PreviewTask]: '/:ref',
                    },
                  },
                  ...(isDev && {
                    [RouteName.Playground]: {
                      path: 'playground',
                      initialRouteName: 'PlaygroundOverview',
                      screens: {
                        PlaygroundOverview: '',
                        ...['Buttons', 'Texts', 'TextInput', 'Toggles', 'Slider', 'Image', 'Overlays', 'Video'].reduce(
                          (screens, name) => Object.assign(screens, { [`Playground${name}`]: name.toLowerCase() }),
                          {},
                        ),
                      },
                    },
                  }),
                },
              },
            },
          },
        },
      },
    },
  },
}
