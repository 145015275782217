import { Colors } from '@vetahealth/fishing-gear/colors'
import styled from 'styled-components/native'

import { Text } from '../Text'

export const Wrapper = styled.View<{ isDisabled?: boolean }>`
  flex-direction: row;
  margin-bottom: 10px;
  ${({ isDisabled }) => !isDisabled && 'cursor: pointer;'}
`
type ToggleWrapperProps = {
  isChecked?: boolean
  isDisabled?: boolean
  isHovered?: boolean
}
export const ToggleWrapper = styled.View<ToggleWrapperProps>`
  width: 24px;
  height: 24px;
  border-width: 2px;
  border-color: ${({ isChecked = false, isHovered, theme }) =>
    isChecked ? theme.colors.primary : isHovered ? Colors.mauve500 : Colors.gray500};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
  align-items: center;
  justify-content: center;
`
export const Toggle = styled.View<{ isChecked?: boolean }>`
  height: 16px;
  width: 16px;
  background-color: ${({ isChecked, theme }) => (isChecked ? theme.colors.primary : 'transparent')};
`
export const LabelWrapper = styled.View`
  flex: 1;
  margin-left: 20px;
  justify-content: center;
`
export const Label = styled(Text).attrs({
  weight: 'semiBold',
  size: 'toggle',
})``
