import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'
import { RouteName } from '../../routeNames'
import { Playground } from '../Playground'
import { Preview } from '../Preview/Preview'
import { About } from './About'
import { AccountList } from './AccountList'
import { ContactUs } from './ContactUs'
import { Program } from './Program'
import { Settings } from './Settings'

const Stack = createNativeStackNavigator()

export function Account() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={RouteName.AccountList}>
      <Stack.Screen name={RouteName.AccountList} component={AccountList} />
      <Stack.Screen name={RouteName.Program} component={Program} />
      <Stack.Screen name={RouteName.About} component={About} />
      <Stack.Screen name={RouteName.Settings} component={Settings} />
      <Stack.Screen name={RouteName.ContactUs} component={ContactUs} />
      <Stack.Screen name={RouteName.Preview} component={Preview} />
      <Stack.Screen name={RouteName.Playground} component={Playground} />
    </Stack.Navigator>
  )
}
