import { Colors } from '@vetahealth/fishing-gear/colors'
import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'

import { Icon } from '../Icon'
import { Pressable } from '../Pressable'
import { ErrorText } from '../Text'
import { Label, LabelWrapper, Toggle, ToggleWrapper, Wrapper } from './styles'
import { CheckboxProps } from './types'

const CustomWrapper = styled(Wrapper)`
  margin-bottom: 20px;
`
const CheckBoxWrapper = styled(ToggleWrapper)<{ isChecked: boolean }>`
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
  background-color: ${({ theme, isChecked }) => (isChecked ? theme.colors.primary : 'transparent')};
`
const CheckBoxElement = styled(Toggle)`
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
  justify-content: center;
  align-items: center;
`
const ErrorWrapper = styled.View``

export function Checkbox({ onChange, isChecked, label, isDisabled = false, error }: CheckboxProps) {
  return (
    <Pressable
      aria-label="checkbox-input"
      onPress={() => onChange(!isChecked)}
      disabled={isDisabled}
      render={({ hovered }) => (
        <CustomWrapper isDisabled={isDisabled}>
          <View>
            <CheckBoxWrapper isChecked={!!isChecked} isDisabled={isDisabled} isHovered={hovered && !isDisabled}>
              <CheckBoxElement isChecked={isChecked}>
                {isChecked && <Icon color={Colors.white} icon={['fas', 'check']} size={15} />}
              </CheckBoxElement>
            </CheckBoxWrapper>
          </View>
          <LabelWrapper>
            <Label>{label}</Label>
            {error && (
              <ErrorWrapper>
                <ErrorText>{error}</ErrorText>
              </ErrorWrapper>
            )}
          </LabelWrapper>
        </CustomWrapper>
      )}
    />
  )
}
