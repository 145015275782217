import { KeyboardView } from '@components/Elements/KeyboardView'
import { ScreenLayout } from '@components/Layouts'
import { TrackingList as TrackingListComponent } from '@components/Tracking'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useTrackingEventsStore } from '@stores/trackingEvents'
import { TrackingChartAssetTrackingTypeEnum } from '@vetahealth/tuna-can-api'
import React, { useEffect, useState } from 'react'
import { RouteName } from '../../routeNames'

export function TrackingList() {
  const t = useI18n()
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const [isLoading, setLoading] = useState<boolean>(true)
  const [isRefreshing, setRefreshing] = useState<boolean>(false)
  const [getLastTrackingEvents] = useTrackingEventsStore((state) => [state.getLastTrackingEvents])

  useEffect(() => {
    if (isFocused) getLastTrackingEvents().finally(() => setLoading(false))
  }, [isFocused])

  function handleRefresh(): void {
    trackEvent(TrackingEvent.trackingRefreshPulled)
    setRefreshing(true)
    getLastTrackingEvents().finally(() => setRefreshing(false))
  }

  function handlePress(trackingType: `${TrackingChartAssetTrackingTypeEnum}`): void {
    navigation.navigate(RouteName.TrackingByType, { trackingType })
  }

  return (
    <ScreenLayout title={t('screen.tracking')} isLoading={isLoading} isStatic>
      <KeyboardView isRefreshing={isRefreshing} onRefresh={handleRefresh}>
        <TrackingListComponent onPress={handlePress} />
      </KeyboardView>
    </ScreenLayout>
  )
}
