import assign from 'lodash/assign'
import { DefaultTheme } from 'styled-components'

export const getTheme = (globalTheme: DefaultTheme) => {
  // *
  // * Colors
  // *
  const yellow200 = '#FFF59D'
  const deepOrange600 = '#F4511E'
  const lime300 = '#DCE775'
  const lightGreen500 = '#8BC34A'
  const teal700 = '#00796B'
  const cyan900 = '#006064'
  const colors = [deepOrange600, yellow200, lime300, lightGreen500, teal700, cyan900]
  const blueGrey700 = '#455A64'
  // *
  // * Typography
  // *
  const letterSpacing = 'normal'
  const fontSize = 12
  // *
  // * Layout
  // *
  const padding = 8
  const baseProps = {
    width: 350,
    height: 350,
    padding: 50,
  }
  // *
  // * Labels
  // *
  const baseLabelStyles = {
    ...globalTheme.font.weight.medium,
    fontSize,
    letterSpacing,
    padding,
    fill: globalTheme.colors.text,
    stroke: 'transparent',
    strokeWidth: 0,
  }

  const centeredLabelStyles = assign({ textAnchor: 'middle' }, baseLabelStyles)

  // *
  // * Strokes
  // *
  const strokeLinecap = 'round'
  const strokeLinejoin = 'round'

  return {
    axis: assign(
      {
        style: {
          axis: {
            fill: 'transparent',
            stroke: globalTheme.colors.chartGridLines,
            strokeWidth: 0,
            strokeLinecap,
            strokeLinejoin,
          },
          axisLabel: assign({}, centeredLabelStyles, {
            padding,
            stroke: 'transparent',
          }),
          grid: {
            fill: 'none',
            stroke: globalTheme.colors.chartGridLines,
            strokeDasharray: '2, 10',
            strokeLinecap,
            strokeLinejoin,
            pointerEvents: 'painted',
            strokeWidth: 1,
          },
          ticks: {
            fill: 'transparent',
            size: 5,
            stroke: globalTheme.colors.text,
            strokeWidth: 0,
            strokeLinecap,
            strokeLinejoin,
          },
          tickLabels: assign({}, baseLabelStyles, {
            fill: globalTheme.colors.text,
          }),
        },
      },
      baseProps,
    ),
    chart: baseProps,
    group: assign(
      {
        colorScale: colors,
      },
      baseProps,
    ),
    line: assign(
      {
        style: {
          data: {
            fill: 'transparent',
            opacity: 1,
            stroke: blueGrey700,
            strokeWidth: 2,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps,
    ),
    scatter: assign(
      {
        style: {
          data: {
            fill: blueGrey700,
            opacity: 1,
            stroke: 'transparent',
            strokeWidth: 0,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps,
    ),
    stack: assign(
      {
        colorScale: colors,
      },
      baseProps,
    ),
  }
}
