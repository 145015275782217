import { t } from '@lib/i18n'
import {
  RangeType,
  ValidUnits,
  convert,
  normalize,
  normalizedUnitRanges,
  normalizedUnits,
} from '@vetahealth/fishing-gear/conversions'
import {
  AnswerOption,
  Asset,
  AssetTypeEnum,
  FormResponse,
  QuestionAsset,
  QuestionTypeEnum,
  Task,
  TrackingChartAssetTrackingTypeEnum,
} from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import * as yup from 'yup'

import { MaunalTrackingChartAssetType } from '@components/Tracking'

import './yupPhone'

export interface Input {
  phone: string
  otp: string
  firstName: string
  lastName: string
  dateOfBirth: Date
  email: string
  message: string
}

export function getInputSchema(): yup.ObjectSchema<Partial<Input>> {
  return yup.object({
    phone: yup.string().phone(t('validation.phone')).required(t('validation.phone')),
    otp: yup.string().length(6, t('validation.otp')).required(t('validation.otp')),
    firstName: yup.string().required(
      t('validation.required', {
        value: t('settings.labels.firstName'),
      }),
    ),
    lastName: yup.string().required(
      t('validation.required', {
        value: t('settings.labels.lastName'),
      }),
    ),
    dateOfBirth: yup
      .date()
      .max(dayjs().endOf('day').subtract(18, 'years'), t('validation.minimumAge'))
      .min(dayjs('1900-01-01', 'YYYY-MM-DD'), t('validation.maximumAge'))
      .required(
        t('validation.required', {
          value: t('settings.labels.dateOfBirth'),
        }),
      ),
    email: yup
      .string()
      .email(t('validation.email'))
      .required(
        t('validation.required', {
          value: t('support.labels.email'),
        }),
      ),
    message: yup.string().required(
      t('validation.required', {
        value: t('support.labels.message'),
      }),
    ),
  })
}

const getDefinitionByQuestionType = (
  questionType: QuestionTypeEnum,
): ((answerOptions?: AnswerOption[]) => yup.Schema) => {
  const schemas: Record<QuestionTypeEnum, (answerOptions?: AnswerOption[]) => yup.Schema> = {
    [QuestionTypeEnum.RadioButtons]: (answerOptions) =>
      yup
        .string()
        .oneOf(answerOptions?.map(({ id }) => id) || [], t('validation.radio'))
        .required(t('validation.radio')),
    [QuestionTypeEnum.Slider]: (answerOptions) =>
      yup
        .string()
        .oneOf(answerOptions?.map(({ id }) => id) || [], t('validation.radio'))
        .required(t('validation.radio')),
    [QuestionTypeEnum.Input]: () => yup.string(),
    [QuestionTypeEnum.CheckboxGroup]: (answerOptions) =>
      yup
        .array()
        .of(yup.string().oneOf(answerOptions?.map(({ id }) => id) || [], t('validation.atLeastOne')))
        .min(1, t('validation.atLeastOne'))
        .required(t('validation.atLeastOne')),
  }

  return schemas[questionType]
}

function isQuestionAsset(asset: Asset): asset is QuestionAsset {
  return asset.type === AssetTypeEnum.Question
}

export function getRemoteFormDefinition(content: Task) {
  const validationObject: Record<string, yup.Schema> = {}
  const values: FormResponse = {}

  content.assets.forEach((asset) => {
    if (isQuestionAsset(asset)) {
      validationObject[asset.id] = getDefinitionByQuestionType(asset.kind)(asset.answerOptions)

      if (asset.kind === QuestionTypeEnum.CheckboxGroup) values[asset.id] = []
      if (asset.kind === QuestionTypeEnum.Input) values[asset.id] = ''
    }
  })

  return {
    initialValues: values,
    validationSchema: yup.object(validationObject),
  }
}

function getMinMaxDefaultsByTrackingType(
  trackingType: RangeType,
  unit: ValidUnits[keyof ValidUnits],
): { min: number[]; max: number[]; raw: { min: number; max: number } } {
  const [min, max] = normalizedUnitRanges[trackingType]

  return {
    raw: { min, max },
    min: convert(min, normalizedUnits[trackingType], trackingType, {
      targetUnit: unit,
      fullPrecision: true,
    }),
    max: convert(max, normalizedUnits[trackingType], trackingType, {
      targetUnit: unit,
      fullPrecision: true,
    }),
  }
}

export const getTrackingInputSchema = (
  trackingType: MaunalTrackingChartAssetType,
  unit: ValidUnits[keyof ValidUnits],
): yup.ObjectSchema<{
  value: number
  additionalValue?: number
  timestamp: Date
  additionalText?: string
}> => {
  const schema = {
    [TrackingChartAssetTrackingTypeEnum.BloodPressure]: (() => {
      const { min, max } = getMinMaxDefaultsByTrackingType('bloodPressure', unit)
      const message = t('validation.trackingRange', {
        min: Math.round(min[0]),
        max: Math.floor(max[0]),
        unit,
      })

      return yup.object({
        // systolic
        value: yup
          .number()
          .typeError(t('validation.number'))
          .min(min[0], message)
          .max(max[0], message)
          .required(
            t('validation.required', {
              value: t('tracking.bloodPressure.labels.systolic'),
            }),
          ),
        // diastolic
        additionalValue: yup.number().when('value', ([systolicValue]: number[]) => {
          const systolicMessage = t('validation.trackingRange', {
            min: Math.round(min[0]),
            max: systolicValue,
            unit,
          })

          return yup
            .number()
            .typeError(t('validation.number'))
            .min(min[0], systolicValue ? systolicMessage : message)
            .max(systolicValue ?? max[0], systolicValue ? systolicMessage : message)
            .required(
              t('validation.required', {
                value: t('tracking.bloodPressure.labels.diastolic'),
              }),
            )
        }),
      })
    })(),

    [TrackingChartAssetTrackingTypeEnum.Weight]: (() => {
      const { min, max, raw } = getMinMaxDefaultsByTrackingType('weight', unit)
      const message = t('validation.trackingRange', {
        min: `${Math.round(min[0])}${
          min[1] ? ` ${unit?.split('_')[0]} / ${Math.round(min[1])} ${unit?.split('_')[1]}` : ''
        }`,
        max: `${Math.floor(max[0])}${
          max[1] ? ` ${unit?.split('_')[0]} / ${Math.floor(max[1])} ${unit?.split('_')[1]}` : ''
        }`,
        unit,
      })

      return yup.object({
        value: yup
          .number()
          .min(min[0], message)
          .max(max[0], message)
          .typeError(t('validation.number'))
          .required(
            t('validation.required', {
              value: t('tracking.weight.labels.weight'),
            }),
          ),
        ...(unit.includes('_') && {
          additionalValue: yup.number().when('value', ([weight]: number[], schema: yup.NumberSchema) => {
            return schema
              .transform((value) => {
                if (Number.isNaN(value)) return ''
                return normalize('weight', [weight, value], unit as ValidUnits['weight'])[0]
              })
              .typeError(t('validation.number'))
              .min(raw.min, message)
              .max(raw.max, message)
              .required(
                t('validation.required', {
                  value: t('tracking.weight.labels.weight'),
                }),
              )
          }),
        }),
      })
    })(),

    [TrackingChartAssetTrackingTypeEnum.HeartRate]: (() => {
      const { min, max } = getMinMaxDefaultsByTrackingType('heartRate', unit)
      const message = t('validation.trackingRange', {
        min: Math.round(min[0]),
        max: Math.floor(max[0]),
        unit,
      })

      return yup.object({
        value: yup
          .number()
          .typeError(t('validation.number'))
          .min(min[0], message)
          .max(max[0], message)
          .required(
            t('validation.required', {
              value: t('tracking.heartRate.labels.heartRate'),
            }),
          ),
      })
    })(),

    [TrackingChartAssetTrackingTypeEnum.BloodGlucose]: (() => {
      const { min, max } = getMinMaxDefaultsByTrackingType('bloodGlucose', unit)
      const message = t('validation.trackingRange', {
        min: Math.round(min[0]),
        max: Math.floor(max[0]),
        unit,
      })

      return yup.object({
        value: yup
          .number()
          .typeError(t('validation.number'))
          .min(min[0], message)
          .max(max[0], message)
          .required(
            t('validation.required', {
              value: t('tracking.bloodGlucose.labels.bloodGlucose'),
            }),
          ),
      })
    })(),

    [TrackingChartAssetTrackingTypeEnum.Height]: (() => {
      const { min, max, raw } = getMinMaxDefaultsByTrackingType('height', unit)
      const message = t('validation.trackingRange', {
        min: `${Math.round(min[0])}${
          min[1] ? ` ${unit?.split('_')[0]} / ${Math.round(min[1])} ${unit?.split('_')[1]}` : ''
        }`,
        max: `${Math.floor(max[0])}${
          max[1] ? ` ${unit?.split('_')[0]} / ${Math.floor(max[1])} ${unit?.split('_')[1]}` : ''
        }`,
        unit,
      })

      return yup.object({
        value: yup
          .number()
          .typeError(t('validation.number'))
          .min(min[0], message)
          .max(min[1], message)
          .required(
            t('validation.required', {
              value: t('tracking.height.labels.height'),
            }),
          ),
        ...(unit.includes('_') && {
          additionalValue: yup.number().when('value', ([height = 0]: number[], schema: yup.NumberSchema) => {
            return schema
              .typeError(t('validation.number'))
              .transform((value) => {
                return normalize('height', [height, value], unit as ValidUnits['height'])[0]
              })
              .min(raw.min, message)
              .max(raw.max, message)
              .required(
                t('validation.required', {
                  value: t('tracking.height.labels.height'),
                }),
              )
          }),
        }),
      })
    })(),

    [TrackingChartAssetTrackingTypeEnum.RespiratoryRate]: (() => {
      const { min, max } = getMinMaxDefaultsByTrackingType('respiratoryRate', unit)
      const message = t('validation.trackingRange', {
        min: Math.round(min[0]),
        max: Math.floor(max[0]),
        unit,
      })

      return yup.object({
        value: yup
          .number()
          .typeError(t('validation.number'))
          .min(min[0], message)
          .max(max[0], message)
          .required(
            t('validation.required', {
              value: t('tracking.respiratoryRate.labels.respiratoryRate'),
            }),
          ),
      })
    })(),

    [TrackingChartAssetTrackingTypeEnum.BodyTemperature]: (() => {
      const { min, max } = getMinMaxDefaultsByTrackingType('bodyTemperature', unit)
      const message = t('validation.trackingRange', {
        type: t('tracking.bodyTemperature.title'),
        min: Math.round(min[0]),
        max: Math.floor(max[0]),
        unit,
      })

      return yup.object({
        value: yup
          .number()
          .typeError(t('validation.number'))
          .min(min[0], message)
          .max(max[0], message)
          .required(
            t('validation.required', {
              value: t('tracking.bodyTemperature.labels.bodyTemperature'),
            }),
          ),
      })
    })(),
  }[trackingType] as yup.ObjectSchema<{ value: number; additionalValue?: number }>

  return schema.shape({
    timestamp: yup.date().required(
      t('validation.required', {
        value: t('tracking.timestamp'),
      }),
    ),
    additionalText: yup.string(),
  })
}
