import * as React from 'react'
import { View } from 'react-native'
import Svg, { Circle, Path } from 'react-native-svg'
import type { SvgProps } from 'react-native-svg'

export const SvgPie = (props: SvgProps) => (
  <View>
    {
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        data-name="Layer 1"
        viewBox="0 0 689.162 554.204"
        {...props}
      >
        <Path fill="#ffb6b6" d="m479.89 539.425-12.757-.001-6.068-49.203h18.827l-.002 49.204z" />
        <Path
          fill="#2f2e41"
          d="m479.55 535.258-13.62-5.54-.39-.16-7.52 5.7a16.013 16.013 0 0 0-16.01 16.01v.52h41.13v-16.53Z"
        />
        <Path fill="#ffb6b6" d="m595.153 522.84-11.417 5.69-27.381-41.33 16.851-8.398 21.947 44.038z" />
        <Path
          fill="#2f2e41"
          d="m596.21 517.658-2.92 1.45-.3.15-14.64 1.13-.44.03-4.19 8.45a16.002 16.002 0 0 0-7.18 21.47l.23.46 36.81-18.34ZM471.238 274.959l70.341 2.462.49-.713s6 14 6 26c0 3.99-2.775 30.085-1.364 35.022.176.614-1.032 2.119-.636 4.978.257 1.856 1.675.429 2 3 .234 1.846-2.26 2.865-2 5 .285 2.333 3.717 9.89 4.026 12.484 3.289 27.626 7.667 67.648 7.667 67.648s17.587 47.103 21.307 47.868c6.245 1.285 10.46 12.639 7 15-1.647 1.125-22.944 8.689-22.944 8.689-1.17.212-4.831-1.625-2.5-5.967.43-.799-2.13 1.235-2.556.278-.452-1.017.436-5.067-.082-6.196-4.654-10.143-11.8-24.2-18.572-31.418a68.601 68.601 0 0 1-18.085-38.765l-15.532-86.125-5.324 24.152-4.405 4.352 1.209 10.15-.209 9.85-11 41s-1.65 76.569 1 79c1.435 1.317 7.525 17.538-4 19l-20.938-1.615s-2.821-5.945-1.41-8.061 1.325-1.538-.396-5.001-1.015-9.33-1.015-9.33a691.206 691.206 0 0 1-3.24-82.993c1.049-45.503 7.41-97.777 7.41-98.842a7.035 7.035 0 0 0-.586-3.173v-2.872l2.702-10.177Z"
        />
        <Circle cx={570.225} cy={255.78} r={27.78} fill="#A8DFD3" />
        <Path
          fill="#fff"
          d="M566.435 270.238a3.09 3.09 0 0 1-2.472-1.236l-7.578-10.105a3.09 3.09 0 1 1 4.944-3.708l4.958 6.61 12.735-19.102a3.09 3.09 0 1 1 5.142 3.429l-15.157 22.736a3.091 3.091 0 0 1-2.486 1.375l-.086.001Z"
        />
        <Path
          fill="#ffb8b8"
          d="M583.962 285.148a7.988 7.988 0 0 0-7.312-9.827l-14.235-48.498-9.629 11.175 15.32 45.41a8.031 8.031 0 0 0 15.856 1.74Z"
        />
        <Path
          fill="#e6e6e6"
          d="m573.92 261.488-1.85-6.78 2.12-4.05-.16-.47-.64-1.82-3.32-2.66 1.19-3.39-4.2-11.93-1.03-2.07-24.11-48.41-5.78-11.61-1.34-2.1-4.17-6.54-5.7-8.93a12.75 12.75 0 0 0-8.08-5.63 12.949 12.949 0 0 0-1.62-.25l-.39-.03a12.871 12.871 0 0 0-2.91.17h-.02a12.476 12.476 0 0 0-4.74 1.88 12.74 12.74 0 0 0-2.98 2.68 12.905 12.905 0 0 0-2.85 8.55l.18 4.88.18 4.44 8.46 15.3.55 1 .01.03 26.03 47.1 7.85 14.2 1.19 2.16 5.28 3.5-1.41 3.52.41 5.48 4.49 3.39 6.51 6.61.26.54 2-1.01.19-.1.45-.13 6.19-1.87 3.76-1.13.12-.04 1.03-.26Z"
        />
        <Path
          fill="#f2f2f2"
          d="M680.93 481.648a15.265 15.265 0 0 0-16.3-5.21l-.64.19-5.74 22.41c-.86-3.34-2.7-10.88-3.86-18.26l-.1-.61-.57.25a77.842 77.842 0 0 0-16.79 10.12 80.525 80.525 0 0 0-30.11 60.64l-.01.43-.01.27.76-.27 16.31-5.83a48.688 48.688 0 0 0 39.26-14.64c6.59-6.95 11.11-15.89 15.48-24.54 1.28-2.52 2.59-5.12 3.93-7.62a15.517 15.517 0 0 0-1.61-17.33Z"
        />
        <Path fill="#3f3d56" d="M262.385 109.64h-2V32.597h144.657v2H262.385v75.043z" />
        <Circle cx={427.225} cy={27.78} r={27.78} fill="#A8DFD3" />
        <Path
          fill="#fff"
          d="M423.435 42.238a3.09 3.09 0 0 1-2.472-1.236l-7.578-10.105a3.09 3.09 0 1 1 4.944-3.708l4.958 6.61 12.735-19.102a3.09 3.09 0 1 1 5.142 3.429l-15.157 22.736a3.091 3.091 0 0 1-2.486 1.375l-.086.001Z"
        />
        <Path fill="#3f3d56" d="M164.385 296.685h-2v77.043h144.657v-2H164.385v-75.043z" />
        <Circle cx={329.225} cy={373.78} r={27.78} fill="#A8DFD3" />
        <Path
          fill="#fff"
          d="M325.435 388.238a3.09 3.09 0 0 1-2.472-1.236l-7.578-10.105a3.09 3.09 0 1 1 4.944-3.708l4.958 6.61 12.735-19.102a3.09 3.09 0 1 1 5.142 3.429l-15.157 22.736a3.091 3.091 0 0 1-2.486 1.375l-.086.001Z"
        />
        <Path
          fill="#e6e6e6"
          d="M549.07 299.518a18.113 18.113 0 0 1-1 6.19l-.15-.03c-17.762 3.072-33.605 3.575-40.934-7.327-9.562-12.176-22.715-12.038-37.387-6.693l-1.74-1.88-2.96-3.19 2.72-3.08-2.72-5.94 2.27-7.33 2.9-.53-.78-6.31-1.22-3.69 3.58-3.93 3.04-9.81-2.19-20.53a136.76 136.76 0 0 1 16.62-81.22l8.31-7.46 3.45-3.1 4.63-8.64 4.89.57 13.24 1.53.45 1.08 3.25 7.84 17.12 18.39-.77 7.76-.34 3.42-.25 2.46-1.18 11.84-4.81 48.37-.36 2.57-3.87 27.81c6.29 6.08 11.85 17.79 14.52 28.49.3 1.19-.55 2.4-.33 3.56.31 1.64 1.66 3.2 1.81 4.73a36.372 36.372 0 0 1 .19 4.08Z"
        />
        <Circle cx={515.161} cy={98.925} r={22.996} fill="#ffb6b6" />
        <Path
          fill="#2f2e41"
          d="M542.882 92.652c-1.36-4.243-5.094-7.834-9.525-8.303a15.512 15.512 0 0 0-22.738-15.503 4.823 4.823 0 0 0-4.432-1.684 9.946 9.946 0 0 0-4.5 1.973 22.321 22.321 0 0 0-8.93 21.111c-.01-2.028.889 1.895 3.464 2.242a16.99 16.99 0 0 1 6.018 2.482 17.505 17.505 0 0 0 6.005 2.517c2.161.316 2.718 8.98 1.175 14.56-.293 1.058-.442 2.477.533 2.98 1.2.621 2.414-.94 3.756-1.091a2.325 2.325 0 0 1 2.359 2.206 3.79 3.79 0 0 1-1.583 3.09 11.202 11.202 0 0 1-3.235 1.567l.387.55a3.08 3.08 0 0 0 3.629 1.48c1.537 1.854 17.384-3.296 18.157-6.682a37.403 37.403 0 0 0 8.138-10.513 17.87 17.87 0 0 0 1.322-12.982Z"
        />
        <Path
          fill="#e6e6e6"
          d="M561.48 162.688c-.32 2.68-2.83 4.68-5.47 5.24a11.832 11.832 0 0 1-4.07.07 27.76 27.76 0 0 1-3.9-.87q-2.37-.675-4.69-1.52a74.34 74.34 0 0 1-12.72-5.95 75.232 75.232 0 0 1-8.76-5.98q-2.16-1.71-4.19-3.57l1.69.8-4.53-6.09q-2.19-2.94-4.37-5.88c-.09-.12-.16-.2-.19-.24v-.01c-.67-.89-1.33-1.78-1.99-2.67-1.99-2.67-3.95-6.59-1.66-9a4.292 4.292 0 0 1 3.73-1.13 5.94 5.94 0 0 1 2.05.66 14.462 14.462 0 0 1 4.35 4.39c1.56-2.27 4.59-3.11 7.33-2.74.15.01.29.03.43.05 2.85.49 4.08-1.05 6.55.46 1.15.7 1.85 4.3 3 5 .65.4 3.06 1 3.72 1.41 1.3.8.97 3.79 2.28 4.59.97.59 3.57-1 4.54-.41 1.02.63 2.44 4.79 3.46 5.41 2.03 1.25 3.66-1.03 5.69.21 2.61 1.61 5.39 3.4 6.49 6.26.97 2.52-.28 5.97-2.69 6.69a4.466 4.466 0 0 1 3.92 4.82Z"
        />
        <Path
          d="m543.35 165.608-.25 2.46a33.963 33.963 0 0 1-8.3-1.87 17.534 17.534 0 0 1-8.73-6.49c-1.36-2.04-2.78-4.07-4.2-6.03-2.36-3.28-4.7-6.34-6.64-8.83-2.37-3.01-4.15-5.19-4.76-5.91-.09-.12-.16-.2-.19-.24l-.03-.03.03.02c11.881 15.039 23.105 23.71 33.41 23.5 0 0 1.21 3.98-.34 3.42Z"
          opacity={0.2}
        />
        <Path
          fill="#ffb8b8"
          d="M434.614 45.18a7.988 7.988 0 0 0 7.12 9.967l13.293 48.764 9.844-10.986-14.438-45.697a8.031 8.031 0 0 0-15.82-2.048Z"
        />
        <Path
          fill="#e6e6e6"
          d="m512.66 169.878-.09-4.44-8.38-15.89-.31-.58-.02-.03-6.43-12.18-31.028-58.826-3.333-1.224.167-4.778-.167-5.222-4.003-2.684-1.707-3.236-2.42.69h-.01l-.07.02-10.58 2.97h-.01l-2.2 1.24-2 6-.438 2.84.438 5.16 5 1-1.656 5.29 5.026 15.22c6.29 26.6 14.88 49.56 29.71 62.67l1.22 2 9.65 15.79a12.806 12.806 0 0 0 12.89 5.99h.01a12.683 12.683 0 0 0 4.78-1.78 12.495 12.495 0 0 0 3.46-3.17 12.884 12.884 0 0 0 2.59-7.95Z"
        />
        <Path
          fill="#cacaca"
          d="m687.972 553.897-271.75.307a1.19 1.19 0 0 1 0-2.381l271.75-.308a1.19 1.19 0 0 1 0 2.382Z"
        />
        <Path
          fill="#f2f2f2"
          d="M83.628 153.708a58.779 58.779 0 0 1 16.049-40.426L64.253 64.526A17.275 17.275 0 0 0 38.19 62.33 126.472 126.472 0 0 0 0 153.284a128.785 128.785 0 0 0 1.427 19.119 17.392 17.392 0 0 0 8.057 12.23 17.2 17.2 0 0 0 14.383 1.729l61.165-19.874a59.15 59.15 0 0 1-1.404-12.78Z"
        />
        <Path
          fill="#e6e6e6"
          d="m86.881 173.027-63.59 20.662A17.257 17.257 0 0 0 13.1 217.695a127.485 127.485 0 0 0 94.86 69.624 17.293 17.293 0 0 0 13.894-3.973 17.253 17.253 0 0 0 6.022-13.104v-59.393a59.114 59.114 0 0 1-40.995-37.822Z"
        />
        <Path
          fill="#3f3d56"
          d="M278.113 210.136a19.266 19.266 0 0 0-11.805-10.846l-69.353-22.534a59.046 59.046 0 0 1-47.07 35.492v72.26a19.244 19.244 0 0 0 22.17 19.01 141.918 141.918 0 0 0 105.599-77.504 19.089 19.089 0 0 0 .459-15.878Z"
        />
        <Path
          fill="#A8DFD3"
          d="M260.882 44.393a19.23 19.23 0 0 0-29.013 2.446l-47.415 65.26a58.81 58.81 0 0 1 17.174 41.61c0 1.436-.069 2.856-.17 4.267l75.37 24.49a19.265 19.265 0 0 0 24.978-15.54 143.33 143.33 0 0 0 1.589-21.283 140.788 140.788 0 0 0-42.513-101.25Z"
        />
        <Path
          fill="#e6e6e6"
          d="M142.628 94.708a58.706 58.706 0 0 1 28.967 7.607l28.94-39.833a17.337 17.337 0 0 0 2.836-14.249 16.994 16.994 0 0 0-8.791-11.204 127.626 127.626 0 0 0-115.38-.168 17.436 17.436 0 0 0-9.093 11.47 17.186 17.186 0 0 0 2.81 14.154l32.83 45.187a58.734 58.734 0 0 1 36.881-12.964Z"
        />
      </Svg>
    }
  </View>
)
