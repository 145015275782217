import { TrackingChartAssetTrackingTypeWithoutHeightEnum } from '@components/Tracking'
import { TrackingChartAssetTrackingTypeEnum } from '@vetahealth/tuna-can-api'

export function isSupportedTrackingType(
  trackingType?: string,
): trackingType is TrackingChartAssetTrackingTypeWithoutHeightEnum {
  return (
    !!trackingType &&
    (Object.values(TrackingChartAssetTrackingTypeEnum) as string[])
      .filter((type) => type !== 'height')
      .includes(trackingType)
  )
}
