import { parsePhoneNumber } from 'libphonenumber-js'
import { StringSchema, addMethod } from 'yup'

declare module 'yup' {
  export interface StringSchema {
    phone(errorMessage: string): StringSchema
  }
}

addMethod(StringSchema, 'phone', function yupPhone(errorMessage: string) {
  return this.test('phone', errorMessage, (value) => {
    if (value === null) return false

    try {
      const phoneNumber = parsePhoneNumber(value)

      return phoneNumber.isValid()
    } catch {
      return false
    }
  })
})
