import React from 'react'
import styled from 'styled-components'
import { useTheme } from 'styled-components/native'

const Video = styled.video`
  width: 100%;
`

export function VideoPlayer({ uri }: { uri: string }): JSX.Element {
  const theme = useTheme()

  return (
    <Video
      style={{ borderRadius: theme.borderRadius.medium }}
      id={uri}
      controls
      controlsList="nodownload"
      preload="metadata"
      key={uri}
    >
      <source src={uri} type="video/mp4" />
    </Video>
  )
}
