import React, { useState } from 'react'
import { View } from 'react-native'
import styled from 'styled-components'
import { useTheme } from 'styled-components/native'

import { HintText } from '../Text'
import { ImageLoading } from './ImageLoading'
import { ImageProps } from './types'

const ImageWrapper = styled.img.attrs({
  decoding: 'async',
})`
  width: 100%;
  object-fit: cover;
`

export function Image({ uri, caption }: ImageProps) {
  const theme = useTheme()
  const [isLoading, setLoading] = useState(true)
  const [width, setWidth] = useState(0)

  return (
    <View onLayout={({ nativeEvent }) => setWidth(nativeEvent.layout.width)}>
      <ImageWrapper
        style={{ borderRadius: theme.borderRadius.medium }}
        src={uri as string}
        alt={caption || ''}
        onLoad={() => setLoading(false)}
      />
      {isLoading && !!width && <ImageLoading width={width} />}

      {!!caption && <HintText size="small">{caption}</HintText>}
    </View>
  )
}
