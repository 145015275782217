import * as React from 'react'
import { View } from 'react-native'
import Svg, { Ellipse, Path } from 'react-native-svg'
import type { SvgProps } from 'react-native-svg'

export const SvgUpdate = (props: SvgProps) => (
  <View>
    {
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        data-name="Layer 1"
        viewBox="0 0 774 669.5"
        {...props}
      >
        <Path
          fill="#f0f0f0"
          d="M725.215 207.133C723.733 92.334 665.168 0 593.13 0c-43.291 0-81.718 33.346-105.815 84.873l28.332 71.087-39.155-43.816c-9.876 29.648-15.481 63.496-15.481 99.445a324.21 324.21 0 0 0 9.987 80.809l75.927-12.24-67.95 37.995c22.137 60.691 62.598 102.142 109.236 104.877l-8.988 244.518 27.814.993-5.945-245.757c66.411-6.324 119.61-91.204 123.871-197.428l-45.894-25.662Z"
        />
        <Path fill="#A8DFD3" d="M157.515 354.402h6.602v49.253h-6.602z" />
        <Path
          fill="#A8DFD3"
          d="m187.815 376.276-4.835 4.503-22.157-23.843-22.165 23.843-4.835-4.503 27-29.033 26.992 29.033z"
        />
        <Path
          fill="#A8DFD3"
          d="M158.988 428.41a52.812 52.812 0 1 1 52.812-52.812 52.872 52.872 0 0 1-52.812 52.812Zm0-99.022a46.21 46.21 0 1 0 46.21 46.21 46.263 46.263 0 0 0-46.21-46.21Z"
        />
        <Path fill="#e4e4e4" d="M212.752 253.931h3.64v27.155h-3.64z" />
        <Path
          fill="#e4e4e4"
          d="m229.458 265.992-2.665 2.483-12.217-13.147-12.22 13.147-2.666-2.483 14.886-16.008 14.882 16.008z"
        />
        <Path
          fill="#e4e4e4"
          d="M213.564 294.736a29.118 29.118 0 1 1 29.118-29.118 29.15 29.15 0 0 1-29.118 29.118Zm0-54.596a25.478 25.478 0 1 0 25.478 25.478 25.507 25.507 0 0 0-25.478-25.478ZM99.523 186.739h3.64v27.155h-3.64z"
        />
        <Path
          fill="#e4e4e4"
          d="m116.229 198.799-2.666 2.483-12.217-13.146-12.22 13.146-2.666-2.483 14.886-16.007 14.883 16.007z"
        />
        <Path
          fill="#e4e4e4"
          d="M100.334 227.543a29.118 29.118 0 1 1 29.118-29.118 29.15 29.15 0 0 1-29.118 29.118Zm0-54.596a25.478 25.478 0 1 0 25.479 25.478 25.507 25.507 0 0 0-25.479-25.478Z"
        />
        <Path
          fill="#ffb8b8"
          d="M305.732 520.67a8.225 8.225 0 0 1 .958-12.303 9.563 9.563 0 0 1 1.261-.835l-6.993-30.134 16.265 5.84 4.147 27.721a8.218 8.218 0 0 1-2.214 10.588 10.13 10.13 0 0 1-13.424-.878Z"
        />
        <Path
          fill="#ccc"
          d="m303.885 497.157-.087-.296c-8.793-29.805-17.882-60.615-28.978-94.162l-.073-.22.245-.131c6.185-3.308 15.48-3.087 21.623.512 5.683 3.33 8.556 9.771 7.003 15.683l8.093 38.674c3.29 12.641 6.696 25.726 9.95 38.628l.093.366-.504.03c-5.926.345-12.049.702-16.954.9Z"
        />
        <Path fill="#ffb8b8" d="M229.16 657.57h-11.533l-5.489-40.769h17.022v40.769z" />
        <Path fill="#2f2e41" d="M232.102 667.816h-37.188v-.431c0-7.315 6.494-13.266 14.475-13.266h22.713Z" />
        <Path fill="#ffb8b8" d="m337.837 648.851-10.989 3.209-18.735-37.317 16.218-4.737 13.506 38.845z" />
        <Path
          fill="#2f2e41"
          d="m308.602 668.141-.143-.41c-2.423-6.97 1.792-14.446 9.397-16.667l21.64-6.32 4.538 13.05Z"
        />
        <Ellipse cx={279.463} cy={354.21} fill="#ffb8b8" rx={23.105} ry={21.175} />
        <Path
          fill="#2f2e41"
          d="M289.837 350.286c.131-8.54-3.769-17-9.799-21.252a16.778 16.778 0 0 0-19.88.322c-5.937 4.447-9.654 13.029-9.34 21.562 13.303.035 26.606.07 39.02-.632ZM235.745 644.447l-.463-.041c-10.034-.903-20.409-1.835-30.214-1.942l-.534-.006.073-.484c2.323-15.42 3.308-31.45 4.26-46.954.941-15.308 1.913-31.137 4.178-46.17.959-4.026 1.892-8.003 2.823-11.967 7.7-32.798 14.974-63.778 29.987-94.444l.142-.29.344.038c14.087 1.562 27.623 6.272 38.114 13.263l.203.135-.01.23a131.26 131.26 0 0 0-.101 14.173 44.825 44.825 0 0 1 7.482 42.489c4.13 12.652 8.402 25.73 12.76 38.632 10.58 26.587 21.53 54.1 32.755 81.99l.184.457-.518.112c-8.076 1.737-16.406 3.529-24.625 4.224l-.31.026-.148-.251a587.307 587.307 0 0 1-50.882-112.127c-2.805 7.839-6.845 14.915-10.76 21.768a157.116 157.116 0 0 0-9.86 19.314c-1.733 13.036-2.192 27.16-2.636 40.82-.42 12.914-.817 25.112-2.193 36.581Z"
        />
        <Path
          fill="#2f2e41"
          d="M271.575 329.426c10.015-1.978 21.093-.096 28.958 6.096s11.86 16.906 8.629 26.094-14.202 15.652-24.12 13.28c2.84-7.339-.357-16.35-7.302-20.577-8.238-5.013-10.505-16.981-6.165-24.893Z"
        />
        <Path
          fill="#ccc"
          d="m283.735 456.37-.367-.153c-12.776-5.342-25.304-9.44-37.041-13.18l-.36-.072-.001-.956.233.074.16-.324c.16-.325.27-.546.363-.693l-.002-.001c.978-1.828 1.756-3.594 2.508-5.301.776-1.763 1.578-3.583 2.613-5.508a15.688 15.688 0 0 1-4.023-17.958c2.593-5.632 9.205-10.018 16.151-10.746 2.546-6.422 8.217-19.916 8.217-19.916s11.927-.3 16.723-.858l.081-.009.08.016c10.47 2.103 19.212 11.184 21.258 22.083 1.726 9.199-.896 19.72-7.383 29.628a128.753 128.753 0 0 1-11.015 13.87c-2.922 3.35-5.682 6.511-7.974 9.697Z"
        />
        <Path
          fill="#2f2e41"
          d="M306.392 343.855ZM306.861 341.974c-7.01-.959-12.515-7.527-11.718-13.982s7.763-11.73 14.83-11.229 13.067 6.694 12.772 13.185-6.933 13.25-15.884 12.026Z"
        />
        <Path
          fill="#2f2e41"
          d="M305.766 337.789c-7.01-.96-12.515-7.527-11.718-13.982.545-4.416 3.98-8.277 8.356-10.117-5.654 1.134-10.52 5.65-11.178 10.979-.797 6.455 4.708 13.023 11.718 13.982a14.465 14.465 0 0 0 7.688-.986 14.866 14.866 0 0 1-4.866.124Z"
        />
        <Path
          fill="#ffb8b8"
          d="M169.776 451.572a9.996 9.996 0 0 1 12.976-3.271 9.611 9.611 0 0 1 1.28.811l28.672-16.083-.59 16-27.137 12.82a10.15 10.15 0 0 1-11.632 1.614 8.342 8.342 0 0 1-3.569-11.891Z"
        />
        <Path
          fill="#ccc"
          d="m193.35 442.142.276-.174c27.734-17.529 56.404-35.648 87.22-56.414l.202-.136.216.168c5.463 4.242 8.334 12.348 6.678 18.855-1.533 6.02-7.203 10.649-13.802 11.277l-37.091 19.878c-11.91 7.056-24.237 14.358-36.426 21.469l-.346.202-.198-.426c-2.33-5.006-4.737-10.177-6.574-14.35Z"
        />
        <Path fill="#cacaca" d="M773 669.5H1a1 1 0 0 1 0-2h772a1 1 0 0 1 0 2Z" />
      </Svg>
    }
  </View>
)
