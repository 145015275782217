import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigation } from '@react-navigation/native'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components/native'

import { SvgPersonalization } from '@assets/svgr/Personalization'
import { Button } from '@components/Elements/Button'
import { Dialog } from '@components/Elements/Dialog'
import { useDebounce } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { getInputSchema } from '@lib/validation'
import { useAuthStore } from '@stores/auth'

import { Svg } from '../Elements/Image'
import { DateInputSimple, TextInput } from '../Elements/Input'
import { ButtonWrapper, ContentWrapper, Description, Hint, HintButton, Title } from './styles'

const Illustration = styled(SvgPersonalization)`
  margin-top: 20px;
`

export function Account() {
  const t = useI18n()
  const initialDate = new Date(dayjs().subtract(18, 'years').toISOString())
  const navigation = useNavigation()
  const [isDobExplanationVisible, setDobExplanationVisibility] = useState<boolean>(false)
  const [user, updateAccount] = useAuthStore((state) => [state.user, state.updateAccount])

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(getInputSchema().pick(['firstName', 'lastName', 'dateOfBirth'])),
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      dateOfBirth: dayjs(user?.dateOfBirth || initialDate).toDate(),
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const handleFormSubmit = useDebounce(
    handleSubmit(async (values) => {
      await updateAccount({
        ...values,
        dateOfBirth: dayjs(values.dateOfBirth).format('YYYY-MM-DD'),
      })
    }),
  )

  useEffect(() => {
    if (isSubmitting && user?.firstName && user?.lastName && user?.dateOfBirth) {
      navigation.goBack()
    }
  }, [user, isSubmitting])

  return (
    <ContentWrapper>
      <Title>{t('onboarding.account')}</Title>
      <Svg size="small">
        <Illustration />
      </Svg>
      <Description>{t('onboarding.finishAccount')}</Description>
      <Controller
        control={control}
        name="firstName"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextInput
            value={value}
            onChange={onChange}
            label={t('settings.labels.firstName')}
            placeholder={t('settings.labels.firstName')}
            error={error?.message}
            textContentType="name"
            enterKeyHint="done"
          />
        )}
      />
      <Controller
        control={control}
        name="lastName"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextInput
            value={value}
            onChange={onChange}
            label={t('settings.labels.lastName')}
            placeholder={t('settings.labels.lastName')}
            error={error?.message}
            textContentType="familyName"
            enterKeyHint="done"
          />
        )}
      />
      <Controller
        control={control}
        name="dateOfBirth"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <DateInputSimple
            date={value ? dayjs(value) : undefined}
            onChange={(date) => date && onChange(date.toDate())}
            error={error?.message}
            label={t('settings.labels.dateOfBirth')}
          />
        )}
      />
      <HintButton
        onPress={() => setDobExplanationVisibility(true)}
        render={() => <Hint>{t('onboarding.dateOfBirth')}</Hint>}
      />
      <ButtonWrapper>
        <Button isLoading={isSubmitting} onPress={handleFormSubmit} fullWidth label={t('actions.continue')} />
      </ButtonWrapper>
      <Dialog
        isVisible={isDobExplanationVisible}
        title={t('settings.labels.dateOfBirth')}
        message={t('onboarding.dateOfBirthExplanation')}
        primaryAction={{
          label: t('actions.close'),
          onPress: () => setDobExplanationVisibility(false),
        }}
      />
    </ContentWrapper>
  )
}
