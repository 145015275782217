import { Button } from '@components/Elements/Button'
import { TextInput } from '@components/Elements/Input'
import { HintText, Text } from '@components/Elements/Text'
import { ScreenLayout } from '@components/Layouts'
import { yupResolver } from '@hookform/resolvers/yup'
import { api } from '@lib/api'
import { useDebounce } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { getInputSchema } from '@lib/validation'
import { useAuthStore } from '@stores/auth'
import { useToastsStore } from '@stores/toasts'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components/native'

const Wrapper = styled.View`
  padding: ${({ theme }) => `0 ${theme.width < 400 ? 20 : 40}`}px;
  gap: 15px;
`
const Label = styled(Text).attrs({
  size: 'settings',
  weight: 'medium',
})``
const ButtonWrapper = styled.View`
  align-items: center;
`

export function ContactUs(): JSX.Element {
  const t = useI18n()
  const [user] = useAuthStore((state) => [state.user])
  const [addToast] = useToastsStore((state) => [state.addToast])

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(getInputSchema().pick(['email', 'message'])),
    defaultValues: { email: user?.email || '', message: '' },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const handleFormSubmit = useDebounce(() =>
    handleSubmit(({ email, message }) => {
      if (email && message) {
        trackEvent(TrackingEvent.contactMessageSent)

        api
          .sendSupportMessage({ email, message })
          .then(() => {
            addToast({
              message: t('support.labels.messageSent'),
            })
            reset()
          })
          .catch(() => {
            addToast({
              message: t('error.supportMessage'),
            })
          })
      }
    })(),
  )

  return (
    <ScreenLayout title={t('screen.support')} hasBack>
      <Wrapper>
        <Text weight="bold" size="large" margins={{ bottom: 20 }}>
          {t('support.labels.emailSupport')}
        </Text>

        <Label>{t('support.labels.contactEmail')}</Label>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextInput
              value={value}
              onChange={onChange}
              error={error?.message}
              textContentType="emailAddress"
              placeholder={t('support.placeholder.email')}
            />
          )}
        />

        <Label>{t('support.labels.helpYou')}</Label>
        <Controller
          control={control}
          name="message"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextInput
              value={value}
              onChange={onChange}
              error={error?.message}
              numberOfLines={5}
              placeholder={t('support.placeholder.message')}
            />
          )}
        />

        <HintText weight="semiBold" margins={{ bottom: 20 }}>
          {t('support.labels.sensitiveData')}
        </HintText>

        <ButtonWrapper>
          <Button onPress={handleFormSubmit} isLoading={isSubmitting} label={t('actions.sendMessage')} />
        </ButtonWrapper>
      </Wrapper>
    </ScreenLayout>
  )
}
