import { Components, renderDatoAssets } from '@vetahealth/fishing-gear/react/datoAssets'
import { Asset } from '@vetahealth/tuna-can-api'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useWindowDimensions } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { ContentBarChart } from '../Elements/Charts'
import { Image, RemoteSvg } from '../Elements/Image'
import { TextInput } from '../Elements/Input'
import { Scale } from '../Elements/Scale'
import { ErrorText } from '../Elements/Text'
import { Checkbox, RadioGroup } from '../Elements/Toggles'
import { VideoPlayer } from '../Elements/VideoPlayer'
import { YouTubePlayer } from '../Elements/YouTubePlayer'
import { Markdown } from '../Markdown'
import { TrackingChart, TrackingChartAssetTrackingTypeWithoutHeightEnum, getTrackingConfig } from '../Tracking'

const CommonWrapper = styled.View`
  margin: 10px 0 30px;
`
const ScaleWrapper = styled.View`
  margin-bottom: 20px;
`
const ImageWrapper = styled.View`
  margin: 10px 0 20px;
`
const AssetToggleWrapper = styled.View`
  margin-bottom: 20px;
`
const AssetInputWrapper = styled.View`
  margin-bottom: 20px;
`

function getComponents(control?: Control): Components {
  const components: Components = {
    Markdown: ({ text }) => <Markdown text={text} i18nEnabled />,
    Question: ({ text }) => <Markdown defaultWeight="medium" text={text} />,
    Answer: ({ text }) => <Markdown defaultWeight="medium" text={text} />,
    Image: ({ src, caption }) => {
      const { width } = useWindowDimensions()
      const theme = useTheme()

      if (!width) return null

      return (
        <ImageWrapper>
          {src.endsWith('.svg') ? (
            <RemoteSvg uri={src} />
          ) : (
            <Image
              isZoomable
              uri={`${src}?w=${(width < theme.maxWidth ? width : theme.maxWidth) * 2}`}
              caption={caption}
            />
          )}
        </ImageWrapper>
      )
    },
    Video: ({ src }) => <VideoPlayer uri={src} />,
    Youtube: ({ id }) => <YouTubePlayer id={id} />,
    BarChart: ({ values }) => (
      <CommonWrapper>
        <ContentBarChart data={values} />
      </CommonWrapper>
    ),
    TrackingChart: ({ trackingType }) => {
      const type = trackingType as TrackingChartAssetTrackingTypeWithoutHeightEnum
      const trackingConfig = getTrackingConfig(type)

      return <TrackingChart trackingType={type} trackingConfig={trackingConfig} />
    },
    Scale: ({ asset }) => {
      return (
        <Controller
          name={`${asset.id}`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <ScaleWrapper>
              <Scale
                initialValue={value}
                options={
                  asset.answerOptions?.map((answerOption) => ({ ...answerOption, value: answerOption.id })) || []
                }
                onChange={onChange}
              />
              {error && <ErrorText>{error?.message}</ErrorText>}
            </ScaleWrapper>
          )}
        />
      )
    },
    Radio: ({ asset }) => {
      return (
        <Controller
          name={`${asset.id}`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <AssetToggleWrapper>
              <RadioGroup
                value={value}
                items={asset.answerOptions?.map((answerOption) => ({ ...answerOption, value: answerOption.id })) || []}
                onChange={onChange}
              />
              {error && <ErrorText>{error?.message}</ErrorText>}
            </AssetToggleWrapper>
          )}
        />
      )
    },
    Checkbox: ({ asset }) => {
      return (
        <Controller
          name={`${asset.id}`}
          control={control}
          render={({ field: { value = [], onChange }, fieldState: { error } }) => {
            function handleChange(optionValue: string): void {
              const newValue = value.includes(optionValue)
                ? value.filter((checkedValue: string) => checkedValue !== optionValue)
                : [...value, optionValue]

              onChange(newValue)
            }

            return (
              <AssetToggleWrapper>
                {asset.answerOptions?.map((answerOption, index) => (
                  <Checkbox
                    key={index}
                    label={answerOption.label}
                    isChecked={value.includes(answerOption.id)}
                    onChange={() => handleChange(answerOption.id)}
                  />
                ))}
                {error && <ErrorText>{error?.message}</ErrorText>}
              </AssetToggleWrapper>
            )
          }}
        />
      )
    },
    TextInput: ({ asset }) => {
      return (
        <AssetInputWrapper>
          <Controller
            name={`${asset.id}`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              const { label, hint } = asset.answerOptions?.[0] || {}

              return (
                <TextInput label={label} placeholder={hint} onChange={onChange} value={value} error={error?.message} />
              )
            }}
          />
        </AssetInputWrapper>
      )
    },
  }

  return components
}

export function DatoAssets({ assets, control }: { assets: Asset[]; control?: Control }): React.ReactNode {
  return renderDatoAssets(assets, getComponents(control))
}
