import { Button } from '@components/Elements/Button'
import { DateInput, TextInput } from '@components/Elements/Input'
import { HintText, Text } from '@components/Elements/Text'
import { ModalLayout } from '@components/Layouts'
import { TrackingChartAssetTrackingTypeWithoutHeightEnum, getTrackingConfig } from '@components/Tracking'
import { yupResolver } from '@hookform/resolvers/yup'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { Route, useNavigation, useRoute } from '@react-navigation/native'
import { useToastsStore } from '@stores/toasts'
import { useTrackingEventsStore } from '@stores/trackingEvents'
import { GenericValueEventWithId } from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import noop from 'lodash/noop'
import React, { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { TextInput as NativeTextInput } from 'react-native'
import styled from 'styled-components/native'
import * as yup from 'yup'
import { RouteName } from '../../routeNames'

const Wrapper = styled.View`
  padding: 0 20px;
  gap: 10px;
`
const InlineWrapper = styled.View`
  flex-direction: row;
  gap: 10px;
`
const InputWrapper = styled.View`
  flex: 1;
`
const ButtonWrapper = styled.View`
  margin-top: 10px;
  align-items: center;
`

export function TrackingEventModal() {
  const t = useI18n()
  const inputRef = useRef<NativeTextInput>(null)
  const [addToast] = useToastsStore((state) => [state.addToast])
  const [updateTrackingEventNote, trackingEvents] = useTrackingEventsStore((state) => [
    state.updateTrackingEventNote,
    state.trackingEvents,
  ])
  const navigation = useNavigation()
  const { params } = useRoute<Route<RouteName.TrackingEventModal, { event: GenericValueEventWithId }>>()

  // use tracking event from list to keep track of changes
  const event = trackingEvents[params.event.type as TrackingChartAssetTrackingTypeWithoutHeightEnum].find(
    (trackingEvent) => trackingEvent.id === params.event.id,
  )

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    watch,
  } = useForm({
    resolver: yupResolver(yup.object({ additionalText: yup.string() })),
    defaultValues: { additionalText: event?.additionalText },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  function handleFormSubmit(): void {
    trackEvent(TrackingEvent.trackingEventUpdateNotePressed)

    handleSubmit(async ({ additionalText }) => {
      if (!event) return

      inputRef.current?.blur()

      const note = additionalText || null
      const response = await updateTrackingEventNote(event, note)

      if (response) {
        navigation.goBack()

        addToast({
          message: note ? t('tracking.toasts.noteUpdated') : t('tracking.toasts.noteDeleted'),
          duration: 2000,
          isCancable: false,
        })
      }
    })()
  }

  useEffect(() => {
    if (!event) navigation.goBack()
  }, [])

  if (!event) return null

  const trackingConfig = getTrackingConfig(event.type as TrackingChartAssetTrackingTypeWithoutHeightEnum)

  return (
    <ModalLayout title={t('screen.trackingEvent')}>
      <Wrapper>
        <Text margins={{ bottom: 10 }} weight="bold">
          {trackingConfig.title}
        </Text>

        <DateInput editable={false} onChange={noop} mode="datetime" format="lll" value={dayjs(event.timestamp)} />

        <InlineWrapper>
          <InputWrapper>
            <TextInput
              value={trackingConfig.getDisplayValue([event.value ?? 0, event.additionalValue ?? 0], event.unit)}
              editable={false}
            />
          </InputWrapper>
        </InlineWrapper>

        <Controller
          control={control}
          name="additionalText"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextInput
              ref={inputRef}
              onChange={onChange}
              value={value}
              numberOfLines={4}
              multiline
              placeholder={t('tracking.note.example')}
              error={error?.message}
            />
          )}
        />

        <HintText size="tiny" margins={{ left: 5, right: 5 }} weight="medium">
          {t('tracking.note.disclaimer')}
        </HintText>
        <ButtonWrapper>
          <Button
            isDisabled={event.additionalText === watch('additionalText')}
            onPress={handleFormSubmit}
            isLoading={isSubmitting}
            label={t('tracking.note.update')}
          />
        </ButtonWrapper>
      </Wrapper>
    </ModalLayout>
  )
}
