import { Colors } from '@vetahealth/fishing-gear/colors'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import Animated, {
  interpolate,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'
import styled, { useTheme } from 'styled-components/native'

import { Icon } from '../Icon'
import { Loading } from '../Loading'
import { Pressable } from '../Pressable'
import { Text } from '../Text'
import { SwitchProps } from './types'

const Wrapper = styled.View`
  flex: 1;
  flex-direction: row;
`
const LabelWrapper = styled.View`
  flex: 1;
  justify-content: center;
`
const SwitchWrapper = styled(Animated.View)<{ isDisabled: boolean }>`
  height: 26px;
  width: 44px;
  margin-left: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  border-width: 2px;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  overflow: hidden;
  ${({ isDisabled }) => !isDisabled && 'cursor: pointer;'}
`
const Toggle = styled(Animated.View)`
  position: absolute;
  height: 18px;
  width: 18px;
  left: 2px;
  top: 2px;
  background-color: ${Colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
`
const IconWrapper = styled(Animated.View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`
const LoadingWrapper = styled.View`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  justify-content: center;
  align-items: center;
`

export function Switch({ isChecked, onChange, label, isDisabled = false, isLoading = false }: SwitchProps) {
  const theme = useTheme()
  const animation = useSharedValue(isChecked ? 1 : 0)
  const toggle = useAnimatedStyle(() => ({
    backgroundColor: interpolateColor(animation.value, [0, 1], [theme.colors.toggle, Colors.white]),
    transform: [
      {
        translateX: interpolate(animation.value, [0, 1], [0, 17]),
      },
      {
        scale: interpolate(animation.value, [0, 1], [0.75, 1]),
      },
    ],
  }))
  const toggleBackground = useAnimatedStyle(() => ({
    backgroundColor: interpolateColor(animation.value, [0, 1], ['transparent', theme.colors.primary]),
    borderColor: interpolateColor(animation.value, [0, 1], [theme.colors.toggle, theme.colors.primary]),
  }))
  const toggleCheckIcon = useAnimatedStyle(() => ({
    opacity: animation.value,
  }))

  useEffect(() => {
    animation.value = withSpring(isChecked ? 1 : 0, {
      overshootClamping: false,
      damping: 15,
      stiffness: 150,
    })
  }, [isChecked])

  return (
    <Wrapper>
      <LabelWrapper>
        <Text weight="medium" size="settings">
          {label}
        </Text>
      </LabelWrapper>
      <View>
        <Pressable
          aria-label="switch-input"
          onPress={() => onChange(!isChecked)}
          disabled={isDisabled || isLoading}
          hoverStyle={{ opacity: 0.9 }}
          render={() => (
            <SwitchWrapper isDisabled={isDisabled} style={[toggleBackground]}>
              <Toggle style={toggle}>
                <IconWrapper style={toggleCheckIcon}>
                  <Icon icon={['fas', 'check']} size={13} color={theme.colors.primary} />
                </IconWrapper>
              </Toggle>
              {isLoading && (
                <LoadingWrapper>
                  <Loading size="small" />
                </LoadingWrapper>
              )}
            </SwitchWrapper>
          )}
        />
      </View>
    </Wrapper>
  )
}
