import { Loading } from '@components/Elements/Loading'
import { ScreenLayout } from '@components/Layouts'
import { Task } from '@components/Task'
import { useResetParams } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { Route, useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { useAuthStore } from '@stores/auth'
import { useTasksStore } from '@stores/tasks'
import React, { useEffect, useState } from 'react'
import { RouteName } from '../../routeNames'

export function ActiveTask() {
  const t = useI18n()
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const { params = {} } = useRoute<Route<RouteName.ActiveTask, { taskRef?: string }>>()
  const resetParams = useResetParams()
  const [isInitialized, setInitialized] = useState<boolean>(false)
  const [loadingCount, setLoadingCount] = useState<number>(0)
  const [user] = useAuthStore((state) => [state.user])
  const [getTask, getTaskByRef, info] = useTasksStore((state) => [
    state.getTask,
    state.getTaskByRef,
    state.info,
    state.taskByRef,
  ])

  useEffect(() => {
    if (isFocused) {
      // temporary fix for ?taskRef=undefined
      if (params?.taskRef && params.taskRef !== 'undefined') {
        setLoadingCount(loadingCount + 1)
        getTaskByRef(params.taskRef).finally(() => {
          setLoadingCount(loadingCount - 1)
        })
      } else {
        resetParams()
        setLoadingCount(loadingCount + 1)
        getTask().finally(() => setLoadingCount(loadingCount - 1))
      }

      if (!isInitialized) setInitialized(true)
    }
  }, [isFocused, params?.taskRef, user?.locale])

  useEffect(() => {
    if (info) {
      navigation.navigate(RouteName.TaskInfoModal)
    }
  }, [info])

  return (
    <ScreenLayout title={t('screen.task')} isStatic isLoading={!isInitialized || loadingCount > 0}>
      {/* hide task when info is present to avoid flickering */}
      {info ? <Loading /> : <Task />}
    </ScreenLayout>
  )
}
