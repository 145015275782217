import React from 'react'

import { ScreenLayout } from '../../Layouts'
import { VideoPlayer } from '../VideoPlayer'
import { Box, Content, Label } from './styles'

export function VideoPlayerPlayground() {
  return (
    <ScreenLayout title="Video" hasBack>
      <Content>
        <Box>
          <Label>Remote Video</Label>
          <VideoPlayer uri="https://www.datocms-assets.com/28532/1596635454-how-to-monitor-your-blood-pressure-at-home.mp4" />
        </Box>
      </Content>
    </ScreenLayout>
  )
}
