import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { ViewProps } from 'react-native'

export type ButtonSize = 'small' | 'medium' | 'large'

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  danger = 'danger',
  plain = 'plain',
}

export interface ButtonProps {
  label: string | number
  variant?: `${ButtonVariant}`
  color?: Colors
  isDisabled?: boolean
  onPress?: () => void
  icon?: IconProp
  isLoading?: boolean
  fullWidth?: boolean
}

export interface IconButtonProps {
  icon: IconProp
  iconSize?: number
  isDisabled?: boolean
  onPress?: () => void
  variant?: `${ButtonVariant}`
  color?: Colors
  size?: `${ButtonSize}`
  pointerEvents?: ViewProps['pointerEvents']
}

export interface SelectButtonProps {
  label: string
  onPress?: () => void
  isChecked: boolean
  type: 'radio' | 'checkbox'
  hasBottomBorder?: boolean
  hasTopBorder?: boolean
  isDisabled?: boolean
  noFeedback?: boolean
}

export interface NavButtonProps {
  label: string
  onPress: () => void
  icon: IconProp
  color?: Colors
}
