import noop from 'lodash/noop'
import React, { useEffect, useState } from 'react'
import { ScrollView, ViewStyle } from 'react-native'
import Animated, { cancelAnimation, useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated'
import styled, { useTheme } from 'styled-components/native'

import { Icon } from '@components/Elements/Icon'
import { Image, RemoteSvg, Svg } from '@components/Elements/Image'
import { Pressable } from '@components/Elements/Pressable'
import { Text } from '@components/Elements/Text'

import { Dot } from './Dot'

const Wrapper = styled.View`
  flex: 1;
`
const scrollViewContainerStyle: ViewStyle = { flex: 1, justifyContent: 'center' }
const AnimatedContent = styled(Animated.View)`
  flex-direction: row;
`
const Content = styled.View<{ width: number }>`
  width: ${({ width }) => width}px;
`
const ContentOverlay = styled.View`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  flex-direction: row;
`
const ContentNavigator = styled(Pressable)<{ isForward?: boolean }>`
  flex: 1;
  justify-content: center;
  align-items: ${({ isForward }) => (isForward ? 'flex-end' : 'flex-start')};
`
const ContentNavigationIconWrapper = styled.View`
  padding: 5px;
  border-radius: 20px;
  margin: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  opacity: 0.75;
`
const BottomRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
`
const DotWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`

const ItemWrapper = styled.View`
  justify-content: center;
`
const ImageWrapper = styled.View`
  align-self: center;
  padding: 0 0px;
  width: 100%;
`
const TextWrapper = styled.View`
  padding: 0 30px;
  align-self: center;
  width: 100%;
`
const HorizontalPadding = styled.View`
  padding: 0 10px;
`

export type Item = {
  id: string
  title: string
  description: string
  image: React.ElementType | string
}

function StoryItem({ image, title, description }: Item): JSX.Element {
  const LocalImage = image
  let StoryImage: React.ReactNode

  if (typeof image === 'string') {
    StoryImage = image.endsWith('.svg') ? (
      <HorizontalPadding>
        <RemoteSvg uri={image} />
      </HorizontalPadding>
    ) : (
      <Image noBorderRadius uri={image} />
    )
  } else {
    StoryImage = (
      <HorizontalPadding>
        <Svg>
          <LocalImage />
        </Svg>
      </HorizontalPadding>
    )
  }

  return (
    <ItemWrapper>
      <ImageWrapper>{StoryImage}</ImageWrapper>
      <TextWrapper>
        <Text weight="bold" size="xLarge" margins={{ bottom: 16, top: 20 }}>
          {title}
        </Text>
        <Text>{description}</Text>
      </TextWrapper>
    </ItemWrapper>
  )
}

export function Story({
  items,
  button,
}: {
  items: Array<Item>
  button: React.ReactNode
}): JSX.Element {
  const theme = useTheme()
  const [width, setWidth] = useState<number>(0)
  const [itemIndex, setItemIndex] = useState<number>(0)
  const isPaused = useSharedValue(false)
  const translateX = useSharedValue(0)

  const translateStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: translateX.value }],
  }))

  function goBack(): void {
    setItemIndex((index) => (index > 0 ? index - 1 : index))
  }

  function goForward(): void {
    setItemIndex((index) => (index < items.length - 1 ? index + 1 : index))
  }

  function pause(): void {
    isPaused.value = true
  }

  function release(): void {
    isPaused.value = false
  }

  useEffect(() => {
    cancelAnimation(translateX)
    translateX.value = withSpring(width * -itemIndex, { overshootClamping: true })
  }, [itemIndex])

  return (
    <Wrapper onLayout={({ nativeEvent }) => setWidth(nativeEvent.layout.width)}>
      <ScrollView contentContainerStyle={scrollViewContainerStyle}>
        <AnimatedContent style={translateStyle}>
          {items.map((item) => (
            <Content width={width} key={item.id}>
              <StoryItem {...item} />
            </Content>
          ))}
        </AnimatedContent>
        <ContentOverlay>
          <ContentNavigator
            onPressIn={pause}
            onLongPress={noop}
            onPressOut={release}
            onPress={goBack}
            render={() =>
              itemIndex > 0 && (
                <ContentNavigationIconWrapper>
                  <Icon size={12} color={theme.colors.disabled} icon={['fas', 'arrow-left']} />
                </ContentNavigationIconWrapper>
              )
            }
          />
          <ContentNavigator
            onPressIn={pause}
            onLongPress={noop}
            onPressOut={release}
            onPress={goForward}
            isForward
            render={() =>
              itemIndex < items.length - 1 && (
                <ContentNavigationIconWrapper>
                  <Icon size={12} color={theme.colors.disabled} icon={['fas', 'arrow-right']} />
                </ContentNavigationIconWrapper>
              )
            }
          />
        </ContentOverlay>
      </ScrollView>
      <BottomRow>
        <DotWrapper>
          {items.map(({ id }, index) => (
            <Pressable
              onPress={() => setItemIndex(index)}
              key={id}
              render={() => <Dot index={index} currentIndex={itemIndex} isPaused={isPaused} goForward={goForward} />}
            />
          ))}
        </DotWrapper>
        {button}
      </BottomRow>
    </Wrapper>
  )
}
