import { Colors } from '@vetahealth/fishing-gear/colors'
import React from 'react'
import { Text as NativeText, Platform, useWindowDimensions } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

import { Image, RemoteSvg } from '@components/Elements/Image'

import { InlineLink, Text, TextSize } from '../Elements/Text'

// Heading

const levelToTextSize: Record<number, TextSize> = {
  1: 'xxLarge',
  2: 'heading2',
  3: 'xLarge',
  4: 'heading4',
  5: 'large',
  6: 'medium',
}

export const Heading = ({ children, level }: { children: any; level: number }) => {
  return (
    <Text size={levelToTextSize[level]} weight={level <= 3 ? 'bold' : 'semiBold'}>
      {children}
      {Platform.select({ native: '\n', web: '\n\n' })}
    </Text>
  )
}

function filterLinebreak(children: MarkdownNode): boolean {
  return children?.value !== '\n'
}

// Lists

const ListWrapper = styled.View`
  padding: 0 10px;
`
const ListItemWrapper = styled.View``
const ListElement = styled.View`
  padding-left: 20px;
  margin-top: 10px;
`
const ListIndicator = styled.View`
  position: absolute;
  left: 0;
  top: 1px;
`
const ListBullet = styled.View`
  width: 7px;
  height: 7px;
  background-color: ${({ theme }) => theme.colors.text};
  border-radius: 7px;
  margin-right: 5px;
  margin-top: 9px;
`

export type MarkdownNode = {
  type: 'element' | 'text'
  tagName?: string
  children?: MarkdownNode[]
  value?: string
  properties?: Record<string, any>
}

function InnerListItem({
  node,
  format = [],
}: {
  node: MarkdownNode
  format?: string[]
}): JSX.Element {
  return (
    <>
      {node?.children?.filter(filterLinebreak).map((innerListNode, i: number) => {
        if (innerListNode.type === 'element') {
          // filter sub lists (breaking layout on mobile: <Text><View/></Text> => View loses positioning relation)
          // TODO: find a way to have inline styling and sub lists
          if (['ol', 'ul'].includes(innerListNode?.tagName as string)) {
            // return <List key={i} node={innerListNode} />
            return null
          }

          // for defined tags loop another level
          if (innerListNode?.tagName && ['p', 'em', 'strong', 'a'].includes(innerListNode.tagName)) {
            return <InnerListItem key={i} node={innerListNode} format={[...format, innerListNode.tagName]} />
          }
        }

        // render actual text with styling
        if (innerListNode.type === 'text') {
          return format.includes('a') && node.properties?.href ? (
            <InlineLink key={i} href={node.properties.href}>
              {innerListNode?.value}
            </InlineLink>
          ) : (
            <Text weight={format.includes('strong') ? 'bold' : 'medium'} isItalic={format.includes('em')} key={i}>
              {innerListNode?.value}
            </Text>
          )
        }
      })}
    </>
  )
}

function ListItem({
  node,
  isOrdered,
  index,
}: {
  node: MarkdownNode
  isOrdered: boolean
  index: number
}): JSX.Element {
  return (
    <ListItemWrapper>
      <ListElement>
        <ListIndicator>
          {isOrdered ? (
            <Text weight="semiBold" size="small">
              {index + 1}
            </Text>
          ) : (
            <ListBullet />
          )}
        </ListIndicator>
        <NativeText>
          <InnerListItem node={node} />
        </NativeText>
      </ListElement>
    </ListItemWrapper>
  )
}

export function List({ node }: { node: MarkdownNode }): JSX.Element {
  const isOrdered = node?.tagName === 'ol'

  return (
    <ListWrapper>
      {node?.children
        ?.filter(({ tagName }) => tagName === 'li')
        .map((listNode, i: number) => {
          return <ListItem key={i} index={i} node={listNode} isOrdered={isOrdered} />
        })}
    </ListWrapper>
  )
}

// Table

const TableWrapper = styled.View`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
`
const TableRowWrapper = styled.View<{ color?: Colors }>`
  width: 100%;
  flex-direction: row;
  ${({ color }) => color && `background-color: ${color};`}
`
const TableCell = styled.View`
  flex: 1;
  padding: 5px 10px 8px;
  border-color: ${({ theme }) => theme.colors.background};
  border-left-width: 1px;
  margin-left: -1px;
  border-top-width: 1px;
  margin-top: -1px;
`

function TableRow({
  node,
  isHeader,
}: {
  node: MarkdownNode
  isHeader?: boolean
}): JSX.Element {
  return (
    <>
      {node?.value && (
        <TableCell>
          {isHeader ? (
            <Text weight="semiBold" size="small" isCentered color={Colors.white}>
              {node.value}
            </Text>
          ) : (
            <Text size="small">{node.value}</Text>
          )}
        </TableCell>
      )}
      {node?.children?.filter(filterLinebreak).map((rowNode, index: number) => (
        <TableRow key={index} node={rowNode} isHeader={isHeader} />
      ))}
    </>
  )
}

export function Table({ node }: { node: MarkdownNode }): JSX.Element | null {
  const [header, body] = node?.children?.filter(filterLinebreak) || []

  if (!header && !body) return null

  return (
    <TableWrapper>
      <TableRowWrapper color={Colors.mauve500}>
        <TableRow node={header} isHeader />
      </TableRowWrapper>
      {body?.children?.filter(filterLinebreak).map((bodyNode, index: number) => (
        <TableRowWrapper key={index}>
          <TableRow node={bodyNode} />
        </TableRowWrapper>
      ))}
    </TableWrapper>
  )
}

const ImageWrapper = styled.View`
  flex-direction: row;
  margin: 10px 0 0;
`

export function InlineImage({ node }: { node: MarkdownNode }): JSX.Element | null {
  const { width } = useWindowDimensions()
  const theme = useTheme()

  if (!node.properties?.src) return null

  return (
    <ImageWrapper>
      {width > 0 && node.properties.src.endsWith('.svg') ? (
        <RemoteSvg uri={node.properties.src.srcUrl} />
      ) : (
        <Image
          isZoomable
          uri={`${node.properties.src}?w=${(width < theme.maxWidth ? width : theme.maxWidth) * 2}`}
          caption={node.properties?.alt}
        />
      )}
    </ImageWrapper>
  )
}
