import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'
import { RouteName } from '../../../routeNames'
import { Appearance } from './Appearance'
import { Language } from './Language'
import { Notifications } from './Notifications'
import { SettingsList } from './SettingsList'
import { Units } from './Units'

const Stack = createNativeStackNavigator()

export function Settings() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={RouteName.SettingsList}>
      <Stack.Screen name={RouteName.SettingsList} component={SettingsList} />
      <Stack.Screen name={RouteName.Appearance} component={Appearance} />
      <Stack.Screen name={RouteName.Language} component={Language} />
      <Stack.Screen name={RouteName.Notifications} component={Notifications} />
      <Stack.Screen name={RouteName.Units} component={Units} />
    </Stack.Navigator>
  )
}
