import { useEffect } from 'react'

import { useAuthStore } from '@stores/auth'

import { storage } from '../mmkv'

export function useDeepLinkingListener(): void {
  const [isAuthorized] = useAuthStore((state) => [state.authorized])

  useEffect(() => {
    if (isAuthorized) {
      storage.set('returningUser', 'isReturning')
    }
  }, [isAuthorized])
}
