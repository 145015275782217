import { Linking } from 'react-native'

import { captureException } from '@lib/error'

export async function openExternalLink(url: string): Promise<boolean> {
  return Linking.openURL(url)
    .then(() => true)
    .catch((error) => {
      captureException(error, 'openExternalLink')
      return false
    })
}
