import { useEffect, useState } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { tabBarHeight } from '@lib/constants'

export function useBottom(height = 0, extraOffset = 0): number {
  const { bottom } = useSafeAreaInsets()
  const bottomPadding = bottom ? 0 : 10
  const [offset, setOffset] = useState<number>(0)

  useEffect(() => {
    setOffset(height - (bottom + bottomPadding + tabBarHeight + extraOffset))
  }, [height])

  // returns height (> 0) with offset or just offset (* -1)
  return offset > 0 ? offset : offset * -1
}
