import { Pressable } from '@components/Elements/Pressable'
import { Text } from '@components/Elements/Text'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { SupportedLocaleEnum } from '@vetahealth/tuna-can-api'
import i18next from 'i18next'
import React from 'react'
import { Linking } from 'react-native'
import styled from 'styled-components'

const Wrapper = styled.div`
  gap: 10px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
`

export function DownloadApp({ testLocale }: { testLocale?: SupportedLocaleEnum }): React.ReactNode {
  const t = useI18n()
  const locale = testLocale || i18next.language

  const isEnglish = locale.includes('en')

  function handleDownloadIOSApp(): void {
    trackEvent(TrackingEvent.welcomeDownloadAppPressed, { platform: 'ios' })
    Linking.openURL('https://apps.apple.com/us/app/prosper-by-veta-health/id1576826785')
  }

  function handleDownloadAndroidApp(): void {
    trackEvent(TrackingEvent.welcomeDownloadAppPressed, { platform: 'android' })
    Linking.openURL('https://apps.apple.com/us/app/prosper-by-veta-health/id1576826785')
  }

  return (
    <Wrapper>
      <Text>{t('downloadApp.description', { lng: testLocale })}</Text>
      <ButtonWrapper>
        <Pressable
          onPress={handleDownloadIOSApp}
          render={() => {
            return (
              <img
                style={{ height: 40, padding: '11px 0' }}
                src={isEnglish ? require('./apple-badge-english.svg') : require('./apple-badge-spanish.svg')}
                alt={t('downloadApp.apple', { lng: testLocale })}
              />
            )
          }}
        />
        <Pressable
          onPress={handleDownloadAndroidApp}
          render={() => {
            return (
              <img
                style={{ height: isEnglish ? 60 : 52, width: 'auto', marginLeft: isEnglish ? 0 : 10 }}
                src={
                  isEnglish ? require('./google-play-badge-english.png') : require('./google-play-badge-spanish.png')
                }
                alt={t('downloadApp.google', { lng: testLocale })}
              />
            )
          }}
        />
      </ButtonWrapper>
    </Wrapper>
  )
}
