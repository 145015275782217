import { Platform } from 'react-native'

import * as Sentry from '../Sentry'
import { config } from '../config'
import { appVersion, bundleId, isDev } from '../constants'

// wrapped in if() to avoid creating empty releases from local development
if (!isDev) {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.stage,
    release: Platform.select({
      native: `${bundleId}@${appVersion}+${config.versionCode}`,
      web: `dolphin-web@${appVersion}`,
    }),
    dist: config.versionCode,
  })
}
