import { SelectButton } from '@components/Elements/Button'
import { Dialog } from '@components/Elements/Dialog'
import { Pressable } from '@components/Elements/Pressable'
import { HintText, Text } from '@components/Elements/Text'
import { Switch } from '@components/Elements/Toggles'
import { useDebounce } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { useAuthStore } from '@stores/auth'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { ChannelEnum, MutedEnum, NotificationPreferences } from '@vetahealth/tuna-can-api'
import { produce } from 'immer'
import React, { useState } from 'react'
import styled from 'styled-components/native'
import { useTheme } from 'styled-components/native'
import { MessageWrapper, OptionsWrapper, Wrapper } from '../styles'
import { PushNotification } from './PushNotification'

const DialogContentWrapper = styled.View`
  margin: 10px 0 15px;
  gap: 10px;
`

export function Notifications(): JSX.Element | null {
  const t = useI18n()
  const theme = useTheme()
  const [isVisible, setVisibility] = useState(false)
  const [deviceTokens, setDeviceTokens] = useState<string[]>([])
  const [user, updateAccount] = useAuthStore((state) => [state.user, state.updateAccount])

  function closeDialog(): void {
    setVisibility(false)
    setDeviceTokens([])
  }

  const handleChannel = useDebounce(
    async (channel: ChannelEnum) => {
      if (user && user.preferences.notifications.channel !== channel) {
        await updateAccount({
          preferences: produce(user.preferences, (draft) => {
            draft.notifications.channel = channel
          }),
        })

        trackEvent(TrackingEvent.notificationsChannelChanged, { channel })
      }
    },
    [user],
  )

  const handleDeviceTokens = useDebounce((deviceToken: string) => {
    setDeviceTokens((state) =>
      state.includes(deviceToken) ? state.filter((token) => token !== deviceToken) : [...state, deviceToken],
    )
  })

  const handleDeleteDevices = useDebounce(async () => {
    if (user && deviceTokens.length) {
      await updateAccount({
        preferences: produce(user.preferences, (draft) => {
          if (deviceTokens.length === user.preferences.notifications.pushDevices?.length) {
            draft.notifications.channel = 'sms'
          }
          draft.notifications.pushDevices = user.preferences.notifications.pushDevices?.filter(
            (device) => !deviceTokens.includes(device.token),
          )
        }),
      })

      closeDialog()
    }
  }, [deviceTokens, user])

  const handleTopic = useDebounce(
    async (topicType: keyof NotificationPreferences['topics']) => {
      if (user) {
        await updateAccount({
          preferences: produce(user.preferences, (draft) => {
            const value = !user.preferences.notifications.topics[topicType]
            draft.notifications.topics[topicType] = value

            trackEvent(TrackingEvent.notificationsTopicChanged, {
              type: topicType,
              enabled: value,
            })
          }),
        })
      }
    },
    [user],
  )

  const toggleMuted = useDebounce(
    async (isChecked: boolean) => {
      if (user) {
        await updateAccount({
          preferences: produce(user.preferences, (draft) => {
            const value = isChecked ? MutedEnum.UntilNextMeasurement : MutedEnum.None
            draft.notifications.muted = value

            trackEvent(TrackingEvent.notificationsMuteChanged, { type: value })
          }),
        })
      }
    },
    [user],
  )

  const handleMute = useDebounce(
    async (muteState: `${MutedEnum}`) => {
      if (user) {
        trackEvent(TrackingEvent.notificationsMuteChanged, { type: muteState })
        await updateAccount({
          preferences: produce(user.preferences, (draft) => {
            draft.notifications.muted = muteState
          }),
        })
      }
    },
    [user],
  )

  if (!user) return null

  const { topics, muted, channel, pushDevices = [] } = user.preferences.notifications
  const isMuted = muted !== MutedEnum.None

  return (
    <>
      <Wrapper color={Colors.apricot400} icon={['fad', 'bell']} title={t('settings.labels.channels')}>
        <OptionsWrapper>
          <PushNotification onChannelChange={() => handleChannel('push')} />
          <SelectButton
            type="radio"
            label={t('settings.labels.sms')}
            isChecked={channel === 'sms'}
            onPress={() => handleChannel('sms')}
          />
        </OptionsWrapper>
        {pushDevices.length ? (
          <>
            <Pressable
              style={{ padding: 10, marginBottom: -10 }}
              onPress={() => setVisibility(true)}
              render={() => (
                <Text color={theme.colors.primary} size="small" weight="semiBold">
                  {t('settings.pushNotifications.managePushNotifications')}
                </Text>
              )}
            />
            <Dialog
              isScrollable
              title={t('settings.pushNotifications.manageDevices')}
              isVisible={isVisible}
              primaryAction={{
                label: t('actions.unregister'),
                onPress: handleDeleteDevices,
                isDisabled: deviceTokens.length === 0,
              }}
              secondaryAction={{
                label: t('actions.cancel'),
                onPress: closeDialog,
              }}
            >
              <DialogContentWrapper>
                <Text size="dialog" weight="medium">
                  {t('settings.pushNotifications.selectDevices', { withHyphenation: true })}
                </Text>
                <OptionsWrapper>
                  {user.preferences.notifications.pushDevices?.map((device, index) => (
                    <SelectButton
                      key={device.token}
                      type="checkbox"
                      label={device.name || t('settings.pushNotifications.unknownDevice')}
                      isChecked={deviceTokens.includes(device.token)}
                      onPress={() => handleDeviceTokens(device.token)}
                      hasBottomBorder={index + 1 !== user.preferences.notifications.pushDevices?.length}
                    />
                  ))}
                </OptionsWrapper>
                <HintText>{t('settings.pushNotifications.smsFallback')}</HintText>
              </DialogContentWrapper>
            </Dialog>
          </>
        ) : null}
      </Wrapper>
      <Wrapper color={Colors.azure400} icon={['fad', 'message-lines']} title={t('settings.labels.topics')}>
        <OptionsWrapper>
          <SelectButton
            type="checkbox"
            label={t('settings.labels.nudges')}
            isChecked={topics.nudges}
            onPress={() => handleTopic('nudges')}
            hasBottomBorder
          />
          <SelectButton
            type="checkbox"
            label={t('settings.labels.reminders')}
            isChecked={topics.reminders}
            onPress={() => handleTopic('reminders')}
          />
        </OptionsWrapper>
      </Wrapper>
      <Wrapper color={Colors.plum500} icon={['fad', 'play-pause']} title={t('settings.labels.pause')}>
        <OptionsWrapper>
          <MessageWrapper>
            <Switch isChecked={isMuted} label={t('settings.labels.mute')} onChange={toggleMuted} />
          </MessageWrapper>
          {isMuted && (
            <>
              <SelectButton
                type="radio"
                label={t('settings.labels.muteUntilNextDeviceUsage')}
                isChecked={muted === 'untilNextMeasurement'}
                onPress={() => handleMute('untilNextMeasurement')}
                hasTopBorder
                hasBottomBorder
              />
              <SelectButton
                type="radio"
                label={t('settings.labels.mutePermanently')}
                isChecked={muted === 'permanent'}
                onPress={() => handleMute('permanent')}
              />
            </>
          )}
        </OptionsWrapper>
      </Wrapper>
    </>
  )
}
