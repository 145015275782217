import { Colors } from '@vetahealth/fishing-gear/colors'
import isFunction from 'lodash/isFunction'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Platform } from 'react-native'
import remarkGfm from 'remark-gfm'
import styled from 'styled-components/native'

import { useI18n } from '@lib/i18n'

import { InlineLink, Text, TextSize, TextWeight } from '../Elements/Text'
import { Heading, InlineImage, List, MarkdownNode, Table } from './MarkdownComponents'

const ListWrapper = styled.View`
  margin-bottom: 30px;
  padding-right: 20px;
`

export type MarkdownProps = {
  text: string
  i18nEnabled?: boolean
  linkColor?: Colors
  withoutParagraphSpacing?: boolean
  paragraphColor?: Colors
  defaultWeight?: TextWeight
  defaultBoldWeight?: TextWeight
  defaultFontSize?: TextSize
  isCentered?: boolean
  customActions?: Record<string, () => void>
}

export function Markdown({
  text,
  i18nEnabled = true,
  linkColor,
  withoutParagraphSpacing,
  paragraphColor,
  defaultWeight = 'normal',
  defaultBoldWeight = 'semiBold',
  defaultFontSize = 'medium',
  isCentered = false,
  customActions,
}: MarkdownProps) {
  const interpolateText = useI18n()

  return (
    <ReactMarkdown
      allowedElements={[
        'a',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'p',
        'em',
        'strong',
        'ol',
        'ul',
        'li',
        'table',
        'tbody',
        'td',
        'th',
        'thead',
        'tr',
        'img',
        'code',
      ]}
      remarkPlugins={[remarkGfm]}
      components={{
        a: ({ children, href }) => {
          // keep custom action keys as single characters to avoid hyphenation
          const customAction = href && customActions?.[href]
          const onPress = isFunction(customAction) ? customAction : undefined

          return (
            <InlineLink
              color={linkColor}
              href={customAction ? undefined : href}
              onPress={onPress}
              size={defaultFontSize}
            >
              {children}
            </InlineLink>
          )
        },
        p: ({ children }) => (
          <Text isCentered={isCentered} weight={defaultWeight} size={defaultFontSize} color={paragraphColor}>
            {children}
            {withoutParagraphSpacing ? null : Platform.select({ native: '\n', web: '\n\n' })}
          </Text>
        ),
        h1: Heading,
        h2: Heading,
        h3: Heading,
        h4: Heading,
        h5: Heading,
        h6: Heading,
        em: ({ children }) => <Text isItalic>{children}</Text>,
        strong: ({ children }) => <Text weight={defaultBoldWeight}>{children}</Text>,
        ol: ({ node }) => (
          <ListWrapper>
            <List node={node as MarkdownNode} />
          </ListWrapper>
        ),
        ul: ({ node }) => (
          <ListWrapper>
            <List node={node as MarkdownNode} />
          </ListWrapper>
        ),
        li: () => null,
        table: ({ node }) => <Table node={node as MarkdownNode} />,
        tbody: () => null,
        td: () => null,
        th: () => null,
        thead: () => null,
        tr: () => null,
        img: ({ node }) => <InlineImage node={node as MarkdownNode} />,
        code: ({ children }) => <Text>{children}</Text>,
      }}
    >
      {i18nEnabled ? interpolateText(text, { withHyphenation: true }) : text}
    </ReactMarkdown>
  )
}
