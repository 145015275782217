import React from 'react'

import { ScreenLayout } from '../../Layouts'
import { Button, IconButton } from '../Button'
import { Content, Label, Row } from './styles'

export function ButtonPlayground() {
  return (
    <ScreenLayout title="Buttons" hasBack>
      <Content>
        <Label>Primary</Label>
        <Row>
          <Button label="Primary" />
          <IconButton icon={['fas', 'microphone']} />
        </Row>
        <Label>Primary loading</Label>
        <Row>
          <Button label="Primary" isLoading />
        </Row>
        <Label>Grows</Label>
        <Button label="Grows with content" />
        <Label>Primary Disabled</Label>
        <Row>
          <Button label="Disabled" isDisabled />
          <IconButton icon={['fas', 'microphone-slash']} isDisabled />
        </Row>
        <Label>Primary with Icon</Label>
        <Button label="Primary with Icon" icon={['fas', 'arrow-right']} />
        <Label>Plain</Label>
        <Row>
          <Button label="Plain" variant="plain" />
          <IconButton icon={['fas', 'thumbs-up']} variant="plain" />
        </Row>
        <Label>Plain with Icon</Label>
        <Button label="Plain" variant="plain" icon={['fad', 'user']} />
        <Label>Plain loading</Label>
        <Button label="Plain" variant="plain" isLoading />
        <Label>Plain disabled</Label>
        <Button label="Disabled" isDisabled variant="plain" />
        <Label>Secondary</Label>
        <Row>
          <Button label="Secondary" variant="secondary" />
          <IconButton icon={['fas', 'thumbs-up']} variant="secondary" />
        </Row>
        <Label>Secondary loading</Label>
        <Row>
          <Button label="Secondary" variant="secondary" isLoading />
        </Row>
        <Label>Secondary with Icon</Label>
        <Button label="Secondary" variant="secondary" icon={['fad', 'user']} />
        <Label>Secondary disabled</Label>
        <Button label="Disabled" isDisabled variant="secondary" />
        <Label>Danger</Label>
        <Row>
          <Button label="Danger" variant="danger" />
          <IconButton icon={['fas', 'biking']} variant="danger" />
        </Row>
        <Label>Danger with Icon</Label>
        <Button label="Danger" variant="danger" icon={['fad', 'trash-can']} />
        <Label>Danger disabled</Label>
        <Button label="Danger" isDisabled variant="danger" icon={['fad', 'trash-can']} />
      </Content>
    </ScreenLayout>
  )
}
