import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components/native'

import { Button } from '@components/Elements/Button'
import { TextInput } from '@components/Elements/Input'
import { useDebounce } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { getInputSchema } from '@lib/validation'
import { useChatStore } from '@stores/chat'

const Wrapper = styled.View`
  padding: 0 20px;
`
const ButtonWrapper = styled.View`
  margin-top: 30px;
  align-items: center;
`

export function NewMessage(): JSX.Element {
  const t = useI18n()
  const navigation = useNavigation()
  const [sendMessage] = useChatStore((state) => [state.sendChatMessage])

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(getInputSchema().pick(['message'])),
    defaultValues: { message: '' },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const handleFormSubmit = useDebounce(() => {
    handleSubmit(async ({ message }) => {
      if (message) {
        await sendMessage(message)
        navigation.goBack()
      }
    })()
  })

  return (
    <Wrapper>
      <Controller
        control={control}
        name="message"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextInput
            value={value}
            onChange={onChange}
            error={error?.message}
            numberOfLines={5}
            placeholder={t('messaging.placeholder')}
            autoFocus
          />
        )}
      />
      <ButtonWrapper>
        <Button onPress={handleFormSubmit} isLoading={isSubmitting} label={t('actions.sendMessage')} />
      </ButtonWrapper>
    </Wrapper>
  )
}
