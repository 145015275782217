import { Icon } from '@components/Elements/Icon'
import { Pressable } from '@components/Elements/Pressable'
import { Text } from '@components/Elements/Text'
import { useDebounce } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { useNavigation } from '@react-navigation/native'
import { useTrackingEventsStore } from '@stores/trackingEvents'
import { Colors } from '@vetahealth/fishing-gear/colors'
import React from 'react'
import { View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { RouteName } from '../../router/routeNames'
import { getTrackingInsights, showTrackingInsights } from './helpers'
import { TrackingEventValue } from './styles'
import { TrackingInsightsProps } from './types'

const Wrapper = styled.View`
  gap: 10px;
`
const InsightsWrapper = styled.View`
  padding: 10px 20px;
  background-color: ${Colors.plum150};
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
  margin-bottom: 10px;
`
const Row = styled.View`
  flex-direction: row;
  gap: 10px;
`
const Section = styled.View`
  flex: 1;
  gap: 5px;
`
const Divider = styled.View`
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.background};
`
const Badge = styled.View`
  padding: 5px 10px;
  background-color: ${Colors.plum100};
  margin: 10px 0 5px;
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
  align-self: flex-start;
`
const Button = styled.View`
  padding: 0 15px 0 5px;
  background-color: ${Colors.plum100};
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 0 20px 10px;
`

export function TrackingInsightsBadge({
  trackingType,
  trackingConfig: { getDisplayValue },
  isLarge,
}: TrackingInsightsProps & { isLarge?: boolean }): React.ReactNode {
  const t = useI18n()
  const theme = useTheme()
  const [trackingEvents] = useTrackingEventsStore((state) => [state.trackingEvents])
  const sevenDayInsights = getTrackingInsights(trackingEvents[trackingType], 7)
  const thirtyDayInsights = getTrackingInsights(trackingEvents[trackingType], 30)

  const label = sevenDayInsights
    ? t('tracking.insights.sevenDayAverage', {
        value: getDisplayValue(
          [sevenDayInsights.average.value, sevenDayInsights.average.additionalValue ?? 0],
          sevenDayInsights.unit,
        ),
      })
    : thirtyDayInsights
      ? t('tracking.insights.thirtyDayAverage', {
          value: getDisplayValue(
            [thirtyDayInsights.average.value, thirtyDayInsights.average.additionalValue ?? 0],
            thirtyDayInsights.unit,
          ),
        })
      : undefined

  if (!showTrackingInsights(trackingEvents[trackingType]) || !label) return null

  return (
    <Badge>
      <Text
        color={theme.colors.trackingCardInsight}
        size={isLarge ? 'small' : 'tiny'}
        weight="semiBold"
        disableLineHeight
      >
        {label}
      </Text>
    </Badge>
  )
}

export function TrackingInsightsButton({ trackingType, trackingConfig }: TrackingInsightsProps): React.ReactNode {
  const navigation = useNavigation()
  const theme = useTheme()
  const [trackingEvents] = useTrackingEventsStore((state) => [state.trackingEvents])

  const handlePress = useDebounce(() => {
    trackEvent(TrackingEvent.trackingInsightsPressed, { type: trackingType })
    navigation.navigate(RouteName.TrackingInsights)
  })

  if (!showTrackingInsights(trackingEvents[trackingType])) return null

  return (
    <Pressable
      onPress={handlePress}
      render={() => (
        <Button>
          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10, flex: 1 }}>
            <TrackingInsightsBadge isLarge trackingType={trackingType} trackingConfig={trackingConfig} />
          </View>
          <Icon icon={['fas', 'ellipsis']} color={theme.colors.trackingCardInsight} />
        </Button>
      )}
    />
  )
}

export function TrackingInsights({
  trackingType,
  trackingConfig: { getDisplayValue },
}: TrackingInsightsProps): React.ReactNode {
  const t = useI18n()
  const theme = useTheme()
  const [trackingEvents] = useTrackingEventsStore((state) => [state.trackingEvents])
  const sevenDayInsights = getTrackingInsights(trackingEvents[trackingType], 7)
  const thirtyDayInsights = getTrackingInsights(trackingEvents[trackingType], 30)

  return (
    <Wrapper>
      <Text size="settings" weight="semiBold">
        {t('tracking.insights.averageMeasurements')}
      </Text>
      <InsightsWrapper>
        <Row>
          <Section>
            <TrackingEventValue
              displayValue={
                sevenDayInsights
                  ? getDisplayValue(
                      [sevenDayInsights.average.value, sevenDayInsights.average.additionalValue ?? 0],
                      sevenDayInsights.unit,
                    )
                  : '–'
              }
            />

            <Text size="tiny" weight="semiBold" color={theme.colors.trackingCardInsight}>
              {t('tracking.insights.lastSevenDays')}
            </Text>
          </Section>
          <Divider />
          <Section>
            <TrackingEventValue
              displayValue={
                thirtyDayInsights
                  ? getDisplayValue(
                      [thirtyDayInsights.average.value, thirtyDayInsights.average.additionalValue ?? 0],
                      thirtyDayInsights.unit,
                    )
                  : '–'
              }
            />

            <Text size="tiny" weight="semiBold" color={theme.colors.trackingCardInsight}>
              {t('tracking.insights.lastThirtyDays')}
            </Text>
          </Section>
        </Row>
      </InsightsWrapper>
      <Text size="settings" weight="semiBold">
        {t('tracking.insights.maxMeasurements')}
      </Text>
      <InsightsWrapper>
        <Row>
          <Section>
            <TrackingEventValue
              displayValue={
                sevenDayInsights
                  ? getDisplayValue(
                      [sevenDayInsights.max.value, sevenDayInsights.max.additionalValue ?? 0],
                      sevenDayInsights.unit,
                    )
                  : '–'
              }
            />

            <Text size="tiny" weight="semiBold" color={theme.colors.trackingCardInsight}>
              {t('tracking.insights.lastSevenDays')}
            </Text>
          </Section>
          <Divider />
          <Section>
            <TrackingEventValue
              displayValue={
                thirtyDayInsights
                  ? getDisplayValue(
                      [thirtyDayInsights.max.value, thirtyDayInsights.max.additionalValue ?? 0],
                      thirtyDayInsights.unit,
                    )
                  : '–'
              }
            />

            <Text size="tiny" weight="semiBold" color={theme.colors.trackingCardInsight}>
              {t('tracking.insights.lastThirtyDays')}
            </Text>
          </Section>
        </Row>
      </InsightsWrapper>
    </Wrapper>
  )
}
