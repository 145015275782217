import React, { useEffect, useReducer } from 'react'
import styled from 'styled-components/native'

import { Dialog } from '@components/Elements/Dialog'
import { InlineLink } from '@components/Elements/Text'
import { useI18n } from '@lib/i18n'
import { OnboardingStep, useOnboardingStore } from '@stores/onboarding'

import { Loading } from '@components/Elements/Loading'
import { Markdown } from '@components/Markdown'
import { ContentWrapper, Label, OnboardingAnimationWrapper, Title, useOnboardingAnimation } from './styles'

const MarkdownWrapper = styled.View`
  padding: 26px 0 15px;
`

type ExpiredState = {
  isLoading: boolean
  isVisible: boolean
  error?: 'AccountNotFoundError' | 'AccountIsLockedError'
}

const initialState: ExpiredState = {
  isLoading: false,
  isVisible: false,
  error: undefined,
}

export function Expired(): JSX.Element {
  const t = useI18n()
  const style = useOnboardingAnimation()
  const [{ isLoading, isVisible, error }, set] = useReducer(
    (state: ExpiredState, update: Partial<ExpiredState>) => ({
      ...state,
      ...update,
    }),
    initialState,
  )
  const [replace, renewDeepLink, resetOtpParams] = useOnboardingStore((state) => [
    state.replace,
    state.renewDeepLink,
    state.resetOtpParams,
  ])

  useEffect(() => {
    set({ isLoading: true })
    renewDeepLink()
      .catch((error) => {
        if (error?.cause === 'AccountIsLockedError') {
          set({ error: error.cause })
        }
      })
      .finally(() => set({ isLoading: false }))
  }, [])

  function handleSendLink(): void {
    renewDeepLink()
    set({ isVisible: false })
  }

  function handleRedirect(): void {
    resetOtpParams()
    replace([OnboardingStep.INTRO, OnboardingStep.PHONE])
  }

  return (
    <OnboardingAnimationWrapper style={style}>
      <ContentWrapper>
        {isLoading && <Loading />}
        {!isLoading && !error && (
          <>
            <Title>{t('onboarding.expired.title')}</Title>
            <Label>{t('onboarding.expired.description')}</Label>
            <InlineLink size="settings" onPress={() => set({ isVisible: true })}>
              {t('onboarding.expired.link')}
            </InlineLink>
          </>
        )}
        {!isLoading && error && (
          <>
            <Title>{t('onboarding.expired.locked')}</Title>
            <MarkdownWrapper>
              <Markdown text={t('error.accountLocked')} />
            </MarkdownWrapper>
          </>
        )}
      </ContentWrapper>

      <Dialog
        title={t('onboarding.expired.dialog.title')}
        message={t('onboarding.expired.dialog.description')}
        markdown={{
          defaultBoldWeight: 'semiBold',
          customActions: {
            fn: handleRedirect,
          },
        }}
        isVisible={isVisible}
        primaryAction={{
          label: t('actions.send'),
          onPress: handleSendLink,
        }}
        secondaryAction={{
          label: t('actions.close'),
          onPress: () => set({ isVisible: false }),
        }}
      />
    </OnboardingAnimationWrapper>
  )
}
