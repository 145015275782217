import { Colors } from '@vetahealth/fishing-gear/colors'
import React from 'react'
import { Platform } from 'react-native'
import styled from 'styled-components/native'

import { ButtonVariant } from '../Button'

const Loader = styled.ActivityIndicator.attrs(({ theme, color }) => ({
  color: color || theme.colors.primary,
}))``

export function Loading({
  size = 'large',
  color,
}: {
  size?: 'small' | 'large'
  color?: Colors
  variant?: `${ButtonVariant}`
  isDisabled?: boolean
}): JSX.Element {
  return (
    <Loader
      color={color}
      size={Platform.select<'small' | 'large' | 15>({
        ios: size,
        android: size === 'small' ? 15 : size,
        web: size,
      })}
    />
  )
}
