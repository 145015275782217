import * as React from 'react'
import { View } from 'react-native'
import Svg, { Circle, Path } from 'react-native-svg'
import type { SvgProps } from 'react-native-svg'

export const SvgCheck = (props: SvgProps) => (
  <View>
    {
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        data-name="Layer 1"
        viewBox="0 0 800.122 541.445"
        {...props}
      >
        <Path
          fill="#f2f2f2"
          d="M731.892 189.445c-.84.69-1.68 1.35-2.54 2h-212.14q-2.37-.975-4.72-2a249.807 249.807 0 0 1-61.87-38.52c-12.37-10.48-23.75-21.3-32-31.44-11.51-14.15-22.11-29.57-27.04-47.14-4.92-17.57-3.45-37.71 7.54-52.28a46.795 46.795 0 0 1 12.35-11.31c.79-.5 1.59-.98 2.41-1.43 13.18-7.4 29.81-9.2 44.65-5.37 21.25 5.49 38.91 20.82 51.51 38.8 12.6 17.97 20.78 38.61 28.84 59.02 8.18-36.43 32.95-69.06 66.41-85.65 33.45-16.59 75.01-16.39 107.75 1.58a108.292 108.292 0 0 1 27.55 21.7c.79.85 1.56 1.72 2.32 2.6 15.97 18.49 25.96 42.17 26.78 66.59 1.05 31.46-13.53 63.06-37.8 82.85Z"
        />
        <Path
          fill="#fff"
          d="M525.692 191.445h-3c-.04-.67-.09-1.33-.15-2a224.18 224.18 0 0 0-6.56-38.74 238.817 238.817 0 0 0-38.31-80.46 228.6 228.6 0 0 0-65.5-60.46 1.42 1.42 0 0 1-.7-1.03 1.496 1.496 0 0 1 2.21-1.56 2.256 2.256 0 0 1 .2.13 226.457 226.457 0 0 1 40.12 31.12 240.898 240.898 0 0 1 51.71 74.13 233.592 233.592 0 0 1 19.82 76.87c.06.67.11 1.34.16 2ZM742.912 40.005a.184.184 0 0 1-.07.02c-2.19.4-4.39.82-6.57 1.27a296.84 296.84 0 0 0-52.33 15.89 308.808 308.808 0 0 0-48.5 25 313.784 313.784 0 0 0-43.45 33.08 307.525 307.525 0 0 0-36.99 40.12 297.483 297.483 0 0 0-22.61 34.06c-.38.66-.75 1.33-1.12 2h-3.43c.36-.67.73-1.34 1.11-2a299.923 299.923 0 0 1 27.98-41.33 310.84 310.84 0 0 1 38.31-39.75 317.84 317.84 0 0 1 44.38-32.28 311.853 311.853 0 0 1 49.52-24.16 297.104 297.104 0 0 1 51.45-14.52l1.46-.27c1.87-.34 2.67 2.48.86 2.87Z"
        />
        <Path
          fill="#2f2e41"
          d="M197.68 197.39c5.334-10.07 6.215-23.187-.418-32.454-3.949-5.516-10.114-9.137-16.584-11.18s-13.293-2.642-20.059-3.139c-6.697-.491-13.514-.884-20.056.63s-12.88 5.216-16.066 11.127c-5.384 9.985-.48 22.789-4.434 33.422-3.63 9.758-13.958 15.462-24.11 17.765s-20.75 2.057-30.909 4.337-20.503 7.931-24.197 17.665c-2.67 7.038-1.344 15.146 2.176 21.801s9.03 12.043 14.881 16.781c8.728 7.069 18.939 13.122 30.153 13.735 11.816.646 23.37-4.98 31.875-13.208s14.295-18.862 18.92-29.754c2.935-6.91 5.708-14.347 11.589-19.013 4.321-3.429 9.818-4.931 15.148-6.352l6.036-1.609a41.44 41.44 0 0 0 25.894-20.253l.16-.3Z"
        />
        <Path fill="#ffb8b8" d="M150.079 528.665H137.82l-5.833-47.288h18.095l-.003 47.288z" />
        <Path fill="#2f2e41" d="M129.062 525.162h23.644v14.887h-38.53a14.887 14.887 0 0 1 14.886-14.887Z" />
        <Path fill="#ffb8b8" d="M252.079 528.665H239.82l-5.833-47.288h18.095l-.003 47.288z" />
        <Path
          fill="#2f2e41"
          d="M231.062 525.162h23.644v14.887h-38.53a14.887 14.887 0 0 1 14.886-14.887ZM173.646 491.08a4.834 4.834 0 0 1-.512-.027l-49.878-5.345a4.69 4.69 0 0 1-4.063-5.75l44.163-167.97a4.718 4.718 0 0 1 3.545-3.493l40.968-11.42a4.722 4.722 0 0 1 4.549 1.482c26.34 29.71 46.19 95.57 61.227 178.585a4.692 4.692 0 0 1-4.022 5.49l-40.868 5.217a4.678 4.678 0 0 1-5.17-3.627L203.7 395.574a3.648 3.648 0 0 0-7.138.083l-18.33 91.65a4.666 4.666 0 0 1-4.587 3.773Z"
        />
        <Circle cx={166.059} cy={195.894} r={24.561} fill="#ffb8b8" />
        <Path
          fill="#ccc"
          d="M165.373 312.46a4.68 4.68 0 0 1-4.521-3.486l-14.776-55.51a16.778 16.778 0 0 1 10.11-19.964c11.894-4.643 23.226-6.365 33.68-5.121 9.292 1.107 20.755 56.118 19.997 57.292l3.666 11a4.69 4.69 0 0 1-3.442 6.064l-43.695 9.612a4.732 4.732 0 0 1-1.019.112Z"
        />
        <Path
          fill="#ffb8b8"
          d="M178.615 369.226a7.61 7.61 0 0 0-7.368-9.05l-8.513-25.67-8.7 11.038 9.418 22.684a7.652 7.652 0 0 0 15.163.998ZM288.063 307.534a7.61 7.61 0 0 0-11.385-2.562l-22.556-14.921-.02 14.053 21.422 12.016a7.652 7.652 0 0 0 12.539-8.586Z"
        />
        <Path
          fill="#ccc"
          d="M160.768 357.366a4.69 4.69 0 0 1-4.454-3.217l-19.957-42.217a24.399 24.399 0 0 1-1.235-7.669l6-54.871a12.059 12.059 0 0 1 24.03-1.456l-9.18 62.631 14.649 38.44a4.681 4.681 0 0 1-2.478 5.225l-5.278 2.64a4.685 4.685 0 0 1-2.097.494ZM267.352 313.077a4.681 4.681 0 0 1-2.115-.504l-39.688-20.047a24.368 24.368 0 0 1-6.29-4.56l-38.736-38.863a12.059 12.059 0 0 1 15.992-17.994l41.963 46.603 33.263 21.12a4.68 4.68 0 0 1 1.933 5.45l-1.875 5.595a4.692 4.692 0 0 1-4.448 3.2Z"
        />
        <Path
          fill="#2f2e41"
          d="M138.122 183.196a28.6 28.6 0 0 0 16.895 10.63 29.87 29.87 0 0 0 20.06-2.873c4.947-2.673 8.905-6.684 12.782-10.634 1.618-1.648 3.342-3.549 3.26-5.797-.126-3.484-4.314-5.388-7.908-6.148a56.435 56.435 0 0 0-35.033 3.94c-4.88 2.267-9.965 6.368-9.232 11.466"
        />
        <Path
          fill="#3f3d56"
          d="M783.122 189.445h-482a17.024 17.024 0 0 0-17 17v186a17.024 17.024 0 0 0 17 17h482a17.024 17.024 0 0 0 17-17v-186a17.024 17.024 0 0 0-17-17Zm15 203a15.018 15.018 0 0 1-15 15h-482a15.018 15.018 0 0 1-15-15v-186a15.018 15.018 0 0 1 15-15h482a15.018 15.018 0 0 1 15 15Z"
        />
        <Path
          fill="#3f3d56"
          d="M388.636 357.445a58 58 0 1 1 58-58 58.066 58.066 0 0 1-58 58Zm0-114a56 56 0 1 0 56 56 56.064 56.064 0 0 0-56-56Z"
        />
        <Path
          fill="#A8DFD3"
          d="M740.108 262.445h-231a12.497 12.497 0 0 0-12.48 12.01 4.01 4.01 0 0 0-.02.49 12.518 12.518 0 0 0 12.5 12.5h231a12.5 12.5 0 0 0 0-25ZM740.108 311.445h-231a12.497 12.497 0 0 0-12.48 12.01 4.01 4.01 0 0 0-.02.49 12.518 12.518 0 0 0 12.5 12.5h231a12.5 12.5 0 0 0 0-25ZM382.165 324.787a7.46 7.46 0 0 1-4.489-1.491l-.08-.06-16.904-12.943a7.51 7.51 0 1 1 9.135-11.922l10.95 8.395 25.874-33.744a7.51 7.51 0 0 1 10.53-1.39l-.161.219.165-.216a7.518 7.518 0 0 1 1.39 10.53l-30.435 39.691a7.513 7.513 0 0 1-5.975 2.931Z"
        />
        <Path fill="#3f3d56" d="M382 541.445H1a1 1 0 0 1 0-2h381a1 1 0 0 1 0 2Z" />
        <Path
          fill="#fff"
          d="M683.258 144.395a198.228 198.228 0 0 0-62.845 1.417A202.342 202.342 0 0 0 561.24 166.5a195.2 195.2 0 0 0-29.53 19.51c-1.507 1.204.628 3.315 2.122 2.12a193.808 193.808 0 0 1 53.787-30.235 199.647 199.647 0 0 1 60.064-12.185 191.075 191.075 0 0 1 34.777 1.578 1.554 1.554 0 0 0 1.845-1.048 1.51 1.51 0 0 0-1.047-1.845Z"
        />
      </Svg>
    }
  </View>
)
