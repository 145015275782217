import React from 'react'

import { ScreenLayout } from '../../Layouts'
import { Scale } from '../Scale'
import { Box, Content, Label } from './styles'

export function ScalePlayground() {
  return (
    <ScreenLayout title="Scale" hasBack>
      <Content>
        <Box>
          <Label>Scale</Label>
          <Scale
            initialValue="2"
            options={new Array(2).fill(1).map((_, index) => ({ value: `${index}`, label: `${index}` }))}
          />
        </Box>
        <Box>
          <Label>Scale</Label>
          <Scale
            initialValue="2"
            options={new Array(5).fill(1).map((_, index) => ({ value: `${index}`, label: `${index}` }))}
          />
        </Box>
        <Box>
          <Label>Scale</Label>
          <Scale
            initialValue="2"
            options={new Array(8).fill(1).map((_, index) => ({ value: `${index}`, label: `${index}` }))}
          />
        </Box>
      </Content>
    </ScreenLayout>
  )
}
