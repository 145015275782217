import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'
import { RouteName } from '../../routeNames'
import { TrackingByType } from './TrackingByType'
import { TrackingEventModal } from './TrackingEventModal'
import { TrackingInsightsModal } from './TrackingInsightsModal'
import { TrackingList } from './TrackingList'

const Stack = createNativeStackNavigator()

export function Tracking() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={RouteName.TrackingList}>
      <Stack.Screen name={RouteName.TrackingList} component={TrackingList} />
      <Stack.Screen name={RouteName.TrackingByType} component={TrackingByType} />
      <Stack.Screen
        name={RouteName.TrackingEventModal}
        component={TrackingEventModal}
        options={{ presentation: 'modal', animation: 'slide_from_bottom' }}
      />
      <Stack.Screen
        name={RouteName.TrackingInsights}
        component={TrackingInsightsModal}
        options={{ presentation: 'modal', animation: 'slide_from_bottom' }}
      />
    </Stack.Navigator>
  )
}
