import { ReleaseInfo } from '@vetahealth/tuna-can-api'
import React from 'react'
import styled from 'styled-components/native'

import { DatoAssets } from '@components/Content'
import { Text } from '@components/Elements/Text'
import dayjs from 'dayjs'

const TitleWrapper = styled.View`
  flex-direction: row;
  align-self: flex-start;
  padding: 2px 10px;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  margin-bottom: 20px;
`

export function Release({ release }: { release: ReleaseInfo }): JSX.Element {
  return (
    <>
      <TitleWrapper>
        <Text weight="semiBold" size="tiny">
          {dayjs(release.releaseDate).format('LL')}
        </Text>
      </TitleWrapper>
      <DatoAssets assets={release.content} />
    </>
  )
}
