import React, { useEffect, useState } from 'react'
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated'
import styled, { useTheme } from 'styled-components/native'

import { Pressable } from '../Pressable'
import { Text } from '../Text'
import { RadioGroupProps } from './types'

const Wrapper = styled.View``
const ItemWrapper = styled.View`
  flex-direction: row;
  justify-content: space-evenly;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: ${({ theme }) => theme.borderRadius.switch}px;
  overflow: hidden;
  padding: 3px 5px;
`
const Radio = styled(Pressable)<{ isSelected: boolean }>`
  flex: 1;
  padding: 4px;
  align-items: center;
  justify-content: center;
`
const RadioBackground = styled(Animated.View)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};
  top: 6px;
  bottom: 6px;
  left: 6px;
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
`

export function RadioButton({ value, onChange, items }: RadioGroupProps): JSX.Element {
  const theme = useTheme()
  const [width, setWidth] = useState<number>(0)
  const animation = useSharedValue(0)

  const backgroundStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: interpolate(animation.value, [0, items.length], [0, width - 12]),
      },
    ],
  }))

  useEffect(() => {
    if (value !== undefined) {
      const initialIndex = items.findIndex((item) => item.value === value)

      if (initialIndex > -1)
        animation.value = withSpring(initialIndex, {
          damping: 20,
          stiffness: 230,
        })
    }
  }, [])

  function handleOnPress(newValue: string | number, index: number): void {
    animation.value = withSpring(index, { damping: 20, stiffness: 230 })
    onChange(newValue)
  }

  return (
    <Wrapper onLayout={({ nativeEvent }) => setWidth(nativeEvent.layout.width)}>
      <ItemWrapper>
        <RadioBackground style={[{ width: (width - 12) / items.length }, backgroundStyle]} />
        {items.map((item, index) => {
          const isSelected = item.value === value

          return (
            <Radio
              onPress={() => handleOnPress(item.value, index)}
              isSelected={isSelected}
              key={item.value}
              render={() => (
                <Text
                  weight="semiBold"
                  size="button"
                  isCentered
                  color={isSelected ? theme.colors.primary : undefined}
                  numberOfLines={1}
                >
                  {item.label}
                </Text>
              )}
            />
          )
        })}
      </ItemWrapper>
    </Wrapper>
  )
}
