import { ScreenLayout } from '@components/Layouts'
import { Release } from '@components/Release'
import { useI18n } from '@lib/i18n'
import { useAppStore } from '@stores/app'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/native'

const Wrapper = styled.View`
  padding: ${({ theme }) => `10px ${theme.width < 380 ? 20 : 40}px`};
  gap: 10px;
`

export function Releases() {
  const t = useI18n()
  const [releases, getReleases] = useAppStore((state) => [state.releases, state.getReleases])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    getReleases(releases.length, 20).finally(() => setLoading(false))
  }, [])

  return (
    <ScreenLayout title={t('screen.releases')} hasBack isLoading={isLoading}>
      <Wrapper>
        {releases.map((release) => (
          <Release key={release.id} release={release} />
        ))}
      </Wrapper>
    </ScreenLayout>
  )
}
