export {
  VictoryBar,
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryScatter,
  VictoryTooltip,
  VictoryPortal,
  VictoryGroup,
  VictoryStack,
  VictoryLabel,
  VictoryVoronoiContainer,
} from 'victory'
export { DomainTuple, Tuple, DomainPropType } from 'victory-core'
