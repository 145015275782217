import * as React from 'react'
import { View } from 'react-native'
import Svg, { Path, SvgProps } from 'react-native-svg'

export const SvgProsper = (props: SvgProps) => (
  <View>
    {
      <Svg
        width={24}
        height={24}
        viewBox="0 0 521 478"
        fill="none"
        stroke="#8264A8"
        strokeWidth={25}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <Path
          d="M271.14 297.835C44.765 306.744 24.618 139.899 15 73.08c118.452-10.124 256.14 18.73 256.14 217.668v174.64M253.929 100.504c54.164-71.97 132.625-92.725 250.571-85.78-13.161 51.269-35.165 175.015-196.407 188.54"
          strokeLinecap="round"
        />
      </Svg>
    }
  </View>
)
