import React from 'react'

import { ScreenLayout } from '../../Layouts'
import { Image, RemoteSvg } from '../Image'
import { Box, Content, Label } from './styles'

export function ImagePlayground() {
  return (
    <ScreenLayout title="Image" hasBack>
      <Content>
        <Box>
          <Label>Remote Image with caption</Label>
          <Image
            caption="This is a remote image caption"
            uri="https://www.datocms-assets.com/28532/1599859611-anna-kolosyuk-4r6pg0iq5iu-unsplash.jpg?dpr=1&fm=webp&fit=max&w=560"
          />
        </Box>
        <Box>
          <Label>Local Image</Label>
          <Image uri={require('./local-image.webp')} />
        </Box>
        <Box>
          <Label>Remote SVG</Label>
          <RemoteSvg uri={'https://www.datocms-assets.com/28532/1645189844-undraw_taking_notes_re_bnaf.svg'} />
        </Box>
      </Content>
    </ScreenLayout>
  )
}
