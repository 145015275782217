import { Colors } from '@vetahealth/fishing-gear/colors'
import React, { useEffect } from 'react'
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated'
import styled from 'styled-components/native'

import { Text } from '../Text'
import { ContentBarChartProps } from './types'

const Wrapper = styled.View`
  padding: 10px 20px;
  flex-direction: row;
`
const LabelWrapper = styled.View`
  margin-right: 10px;
`
const Label = styled(Text).attrs({ weight: 'semiBold', size: 'small' })`
  width: 100%;
  text-align: right;
`
const BarWrapper = styled.View`
  flex: 1;
`
const Row = styled.View`
  justify-content: center;
  height: 20px;
  margin-bottom: 10px;
`
const BarElement = styled(Animated.View)`
  flex-direction: row;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
`
const BarValue = styled(Text).attrs({ weight: 'semiBold', size: 'small' })<{ value: number }>`
  position: absolute;
  ${({ value }) => (value > 70 ? 'right: 0px' : 'left: 100%')}
  margin: 0 5px;
  color: ${Colors.gray700};
`

function Bar({ value }: { value: number }) {
  const width = useSharedValue(0)
  const style = useAnimatedStyle(() => ({
    width: `${width.value}%`,
  }))

  useEffect(() => {
    width.value = withSpring(value, { overshootClamping: true })
  }, [])

  return (
    <BarElement style={style}>
      <BarValue value={value}>{value}%</BarValue>
    </BarElement>
  )
}

export function ContentBarChart({ data }: ContentBarChartProps) {
  return (
    <Wrapper>
      <LabelWrapper>
        {data.map(({ label }) => (
          <Row key={label}>
            <Label>{label}</Label>
          </Row>
        ))}
      </LabelWrapper>
      <BarWrapper>
        {data.map(({ label, value }) => (
          <Row key={label}>
            <Bar value={value} />
          </Row>
        ))}
      </BarWrapper>
    </Wrapper>
  )
}
