import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'
import { RouteName } from '../../../routeNames'
import { AboutList } from './AboutList'
import { Licenses } from './Licenses'
import { Releases } from './Releases'

const Stack = createNativeStackNavigator()

export function About() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={RouteName.AboutList}>
      <Stack.Screen name={RouteName.AboutList} component={AboutList} />
      <Stack.Screen name={RouteName.Licenses} component={Licenses} />
      <Stack.Screen name={RouteName.Releases} component={Releases} />
    </Stack.Navigator>
  )
}
