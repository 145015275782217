import noop from 'lodash/noop'
import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components/native'

import { IconButton } from '../Button'
import { Dialog } from '../Dialog'
import { InputError } from './InputError'
import { TextInput } from './TextInput'
import { CodeInputProps } from './types'

const Wrapper = styled.View``
const Row = styled.View`
  flex-direction: row;
`
const InputWrapper = styled.View`
  width: 50%;
`
const ButtonWrapper = styled.View`
  margin-left: 10px;
  margin-top: 2px;
`

export function CodeInput({
  length = 0,
  value,
  onChange = noop,
  onSubmitEditing = noop,
  inputMode,
  enterKeyHint,
  autoFocus = false,
  error,
  hintAction,
}: CodeInputProps): JSX.Element {
  const theme = useTheme()
  const [isVisible, setVisibility] = useState<boolean>(false)

  function handleAction(action: () => void): () => void {
    return () => {
      action()
      setVisibility(false)
    }
  }

  function handleOpenPress(action?: () => void): () => void {
    return () => {
      if (action) action()
      setVisibility(true)
    }
  }

  return (
    <Wrapper>
      <Row>
        <InputWrapper>
          <TextInput
            maxLength={length}
            onChange={onChange}
            onSubmitEditing={onSubmitEditing}
            inputMode={inputMode}
            value={value}
            autoFocus={autoFocus}
            enterKeyHint={enterKeyHint}
            placeholder="Code"
          />
        </InputWrapper>
        {hintAction && (
          <ButtonWrapper>
            <IconButton
              variant="secondary"
              icon={hintAction.icon}
              color={theme.colors.primary}
              onPress={handleOpenPress(hintAction.onOpen)}
            />
            <Dialog
              isVisible={isVisible}
              title={hintAction.title}
              message={hintAction.message}
              markdown={{ withoutParagraphSpacing: true }}
              primaryAction={{
                ...hintAction.primaryAction,
                onPress: handleAction(hintAction.primaryAction.onPress),
              }}
              {...(hintAction.secondaryAction && {
                secondaryAction: {
                  ...hintAction.secondaryAction,
                  onPress: handleAction(hintAction.secondaryAction.onPress),
                },
              })}
            />
          </ButtonWrapper>
        )}
      </Row>
      <InputError error={error} />
    </Wrapper>
  )
}
