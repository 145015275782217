import React from 'react'
import styled from 'styled-components/native'

import { ErrorText } from '../Text'

const ErrorWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  margin-left: 23px;
`

export function InputError({ error }: { error?: string }): JSX.Element | null {
  if (!error) return null

  return (
    <ErrorWrapper>
      <ErrorText>{error}</ErrorText>
    </ErrorWrapper>
  )
}
