import { Colors } from '@vetahealth/fishing-gear/colors'
import React from 'react'

import { SelectButton } from '@components/Elements/Button'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { Appearance as TAppearance, useAppStore } from '@stores/app'

import { OptionsWrapper, Wrapper } from './styles'

export function Appearance(): JSX.Element {
  const t = useI18n()
  const [appearance, setAppearance] = useAppStore((state) => [state.appearance, state.setAppearance])

  const appearances: Array<{
    id: TAppearance
    label: string
  }> = [
    { id: 'light', label: t('settings.labels.light') },
    { id: 'dark', label: t('settings.labels.dark') },
    {
      id: 'system',
      label: t('settings.labels.system'),
    },
  ]

  function handleAppearanceChange(id: TAppearance): () => void {
    return () => {
      trackEvent(TrackingEvent.appAppearanceChanged, { type: id })
      setAppearance(id)
    }
  }

  return (
    <Wrapper color={Colors.apricot300} icon={['fad', 'brightness']} title={t('settings.appearance')}>
      <OptionsWrapper>
        {appearances.map(({ id, label }, index) => (
          <SelectButton
            hasBottomBorder={index < appearances.length - 1}
            type="radio"
            key={id}
            label={label}
            isChecked={appearance === id}
            onPress={handleAppearanceChange(id)}
          />
        ))}
      </OptionsWrapper>
    </Wrapper>
  )
}
