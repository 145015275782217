import { useNavigation, useRoute } from '@react-navigation/native'
import { useCallback } from 'react'

export function useResetParams() {
  const navigation = useNavigation()
  const route = useRoute()

  const resetParams = useCallback(() => {
    if (route.params) {
      navigation.setParams(
        Object.keys(route.params).reduce<{ [key: string]: '' }>((params, key) => {
          params[key] = ''
          return params
        }, {}),
      )
    }
  }, [route.params])

  return resetParams
}
