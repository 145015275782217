import { useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated'
import { useTheme } from 'styled-components/native'

import { isWeb } from '@lib/constants'
import { useDebounce } from '@lib/hooks'

import { IconButton } from '../Elements/Button'
import { Loading } from '../Elements/Loading'
import {
  ActionWrapper,
  Header,
  HeaderWrapper,
  LoadingWrapper,
  ScrollContent,
  StaticContent,
  Title,
  TitleWrapper,
  Wrapper,
} from './styles'
import { ModalLayoutProps } from './types'

export function ModalLayout({
  title,
  children,
  onCancel,
  isStatic,
  hasCancel = true,
  isLoading = false,
}: ModalLayoutProps) {
  const theme = useTheme()
  const navigation = useNavigation()
  const Content: React.ElementType = isStatic ? StaticContent : ScrollContent
  const animation = useSharedValue(isWeb ? 0 : 1)

  const handleCancel = useDebounce(() => {
    navigation.goBack()
    onCancel?.()
  }, [onCancel])

  const animationStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: interpolate(animation.value, [0, 1], [theme.height, 0]) }],
  }))

  useEffect(() => {
    if (isWeb) {
      animation.value = withSpring(1, {
        damping: 30,
        stiffness: 300,
        overshootClamping: true,
      })
    }
  }, [])

  return (
    <Animated.View style={[{ flex: 1 }, animationStyle]}>
      <Wrapper>
        <HeaderWrapper>
          <Header>
            <TitleWrapper>
              <Title>{title}</Title>
            </TitleWrapper>
            {hasCancel && (
              <ActionWrapper>
                <IconButton icon={['fas', 'xmark']} variant="plain" onPress={handleCancel} />
              </ActionWrapper>
            )}
          </Header>
        </HeaderWrapper>
        <Content>
          {isLoading ? (
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          ) : (
            children
          )}
        </Content>
      </Wrapper>
    </Animated.View>
  )
}
