import { Input } from '@lib/validation'

export enum OnboardingStep {
  INTRO = 'INTRO',
  PHONE = 'PHONE',
  OTP = 'OTP',
  EXPIRED = 'Expired',
}

export type DeepLinkParams = Partial<{
  taskRef: string
  siteKey: string
  showWelcome: boolean
  otp: string
  otpExpiresAt: string
  userId: string
  notificationId: string
  trackingType: string
  isReturningUser: boolean
  [key: string]: unknown
}>

export type OnboardingState = {
  steps: OnboardingStep[]
  input: Partial<Input>
  hasDobVerification: boolean
  updateInput: (values: Partial<Input>) => void
  next: (step: OnboardingStep) => void
  back: () => void
  replace: (step: OnboardingStep | OnboardingStep[]) => void
  requestOtp: () => Promise<void>
  verifyOtp: () => Promise<void>
  reset: () => void
  deepLink?: string
  deepLinkParams?: DeepLinkParams
  resetOtpParams: () => void
  renewDeepLink: () => Promise<void>
  setDeepLink: (deepLink?: string) => void
  resetDeepLink: () => void
}
