import React from 'react'
import styled from 'styled-components/native'

import { SvgConnection } from '@assets/svgr/Connection'
import { Button } from '@components/Elements/Button'
import { useI18n } from '@lib/i18n'

import { Svg } from '../Elements/Image'
import { ButtonWrapper, Message, ScrollView, Title, Wrapper } from './styles'

const Illustration = styled(SvgConnection)`
  align-self: center;
  margin: 20px;
`

export function Connection({ redo }: { redo: () => void }): JSX.Element {
  const t = useI18n()

  return (
    <ScrollView>
      <Wrapper>
        <Title>{t('error.connection.title')}</Title>
        <Svg>
          <Illustration />
        </Svg>
        <Message>{t('error.connection.message')}</Message>
        <ButtonWrapper>
          <Button label={t('error.general.redo')} onPress={redo} />
        </ButtonWrapper>
      </Wrapper>
    </ScrollView>
  )
}
