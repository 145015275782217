import { TrackingChartAssetTrackingTypeWithoutHeightEnum } from '@components/Tracking'
import { Route, useNavigation, useRoute } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useTrackingEventsStore } from '@stores/trackingEvents'
import React, { useEffect } from 'react'
import { RouteName } from '../../routeNames'
import { isSupportedTrackingType } from '../helpers'
import { TrackingDetails } from './TrackingDetails'
import { TrackingEventModal } from './TrackingEventModal'
import { TrackingInsightsModal } from './TrackingInsightsModal'

const Stack = createNativeStackNavigator()

export function TrackingByType() {
  const [getTrackingEvents] = useTrackingEventsStore((state) => [state.getTrackingEvents])
  const navigation = useNavigation()
  const { params } =
    useRoute<Route<RouteName.TrackingDetails, { trackingType: TrackingChartAssetTrackingTypeWithoutHeightEnum }>>()
  const trackingType = params.trackingType
  const isSupported = isSupportedTrackingType(trackingType)

  useEffect(() => {
    // handle unsupported tracking types which might be used via a deeplink
    if (!isSupported) {
      return navigation.goBack()
    }

    getTrackingEvents(trackingType)
  }, [trackingType])

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName={RouteName.TrackingDetails}
    >
      <Stack.Screen name={RouteName.TrackingDetails} component={TrackingDetails} initialParams={{ trackingType }} />
      <Stack.Screen
        name={RouteName.TrackingInsights}
        component={TrackingInsightsModal}
        initialParams={{ trackingType }}
        options={{ presentation: 'modal', animation: 'slide_from_bottom' }}
      />
      <Stack.Screen
        name={RouteName.TrackingEventModal}
        component={TrackingEventModal}
        initialParams={{ trackingType }}
        options={{ presentation: 'modal', animation: 'slide_from_bottom' }}
      />
    </Stack.Navigator>
  )
}
