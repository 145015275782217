import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'

import { Markdown } from '@components/Markdown'

import { ScreenLayout } from '../../Layouts'
import { Text } from '../Text'
import { Box, Content, Label } from './styles'

const MarkdownBackground = styled.View`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  margin-bottom: 10px;
`

const titles = ['# H1 Title', '## H2 Title', '### H3 Title', '#### H4 Title', '##### H5 Title', '###### H6 Title']

const fontStyles = [
  '**Bold**',
  '__Bold__',
  '*Italic*',
  '_Italic_',
  'Paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  '[Link](https://myvetahealth.com)',
]

const lists = [
  `Unordered list
  - **George Washington**. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  - *John Adams*. Lorem ipsum dolor sit amet, **consectetur** adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  - Thomas Jefferson
  `,
  `Ordered list
  1. **George Washington**. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  2. *John Adams*. Lorem ipsum dolor sit amet, **consectetur** adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  3. Thomas Jefferson.
  `,
]

const tables = [
  `
  | Level               | Systolic  | and / or | Diastolic |
  |---------------------|-----------|----------|-----------|
  | Normal              | < 120     | and      | < 80      |
  | Elevated            | 120 -129  | and      | < 80      |
  | High Stage 1        | 130 - 139 | or       | 80 - 89   |
  | High Stage 2        | > 140     | or       | > 90      |
  | Hypertensive Crisis | > 180     | and / or | > 120     |
`,
]

function MarkdownGroup({ elements }: { elements: string[] }): JSX.Element {
  return (
    <>
      {elements.map((element) => (
        <View key={element}>
          <MarkdownBackground>
            <Text weight="semiBold" size="small">
              {element}
            </Text>
          </MarkdownBackground>
          <Markdown text={element} />
        </View>
      ))}
    </>
  )
}

export function MarkdownPlayground() {
  return (
    <ScreenLayout title="Markdown" hasBack>
      <Content>
        <Box>
          <Label>Titles</Label>
          <MarkdownGroup elements={titles} />
        </Box>
        <Box>
          <Label>Font Styles</Label>
          <MarkdownGroup elements={fontStyles} />
        </Box>
        <Box>
          <Label>Lists</Label>
          <MarkdownGroup elements={lists} />
        </Box>
        <Box>
          <Label>Tables</Label>
          <MarkdownGroup elements={tables} />
        </Box>
      </Content>
    </ScreenLayout>
  )
}
