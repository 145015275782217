export enum TrackingEvent {
  // routing
  screen = 'SCREEN',
  // task
  taskSubmitPressed = 'TASK_SUBMIT_PRESSED',
  taskRefreshPulled = 'TASK_REFRESH_PULLED',
  taskInfoContinuePressed = 'TASK_INFO_CONTINUE_PRESSED',
  taskViewed = 'TASK_VIEWED',
  taskRespondedTaskPressed = 'TASK_RESPONDED_TASK_PRESSED',
  taskRespondedTrackingPressed = 'TASK_RESPONDED_TRACKING_PRESSED',
  // tracking
  trackingRefreshPulled = 'TRACKING_REFRESH_PULLED',
  trackingAddTypePressed = 'TRACKING_ADD_TYPE_PRESSED',
  trackingAddSavePressed = 'TRACKING_ADD_SAVE_PRESSED',
  trackingAddBackPressed = 'TRACKING_ADD_BACK_PRESSED',
  trackingAddNotePressed = 'TRACKING_ADD_NOTE_PRESSED',
  trackingRangeChanged = 'TRACKING_RANGE_CHANGED',
  trackingChartDataPointPressed = 'TRACKING_CHART_DATA_POINT_PRESSED',
  trackingEventPressed = 'TRACKING_EVENT_PRESSED',
  trackingEventUpdateNotePressed = 'TRACKING_EVENT_UPDATE_NOTE_PRESSED',
  trackingInsightsPressed = 'TRACKING_INSIGHTS_PRESSED',
  // settings
  unitChanged = 'UNIT_CHANGED',
  notificationsChannelChanged = 'NOTIFICATIONS_CHANNEL_CHANGED',
  notificationsTopicChanged = 'NOTIFICATIONS_TOPIC_CHANGED',
  notificationsMuteChanged = 'NOTIFICATIONS_MUTE_CHANGED',
  appLanguageChanged = 'APP_LANGUAGE_CHANGED',
  appAppearanceChanged = 'APP_APPEARANCE_CHANGED',
  contactMessageSent = 'CONTACT_MESSAGE_SENT',
  aboutTermsOfServicePressed = 'ABOUT_TERMS_OF_SERVICE_PRESSED',
  aboutPrivacyPolicyPressed = 'ABOUT_PRIVACY_POLICY_PRESSED',
  signOutPressed = 'SIGN_OUT_PRESSED',
  // onboarding
  onboardingViewed = 'ONBOARDING_VIEWED',
  onboardingResendCodePressed = 'ONBOARDING_RESEND_CODE_PRESSED',
  onboardingResendCodeConfirmPressed = 'ONBOARDING_RESEND_CODE_CONFIRM_PRESSED',
  onboardingSignedIn = 'ONBOARDING_SIGNED_IN',
  // deep links
  pushNotificationPressed = 'PUSH_NOTIFICATION_PRESSED',
  appOpenedViaDeepLink = 'APP_OPENED_VIA_DEEPLINK',
  // welcome
  welcomeOpened = 'WELCOME_OPENED',
  welcomeCancelPressed = 'WELCOME_CANCEL_PRESSED',
  welcomeGotItPressed = 'WELCOME_GOT_IT_PRESSED',
  welcomeDownloadAppPressed = 'WELCOME_DOWNLOAD_APP_PRESSED',
  // release info
  releaseOpened = 'RELEASE_OPENED',
  releaseGotItPressed = 'RELEASE_GOT_IT_PRESSED',
  releaseCancelPressed = 'RELEASE_CANCEL_PRESSED',
  releasesOpened = 'RELEASES_OPENED',
}
