import { Dayjs } from 'dayjs'
import React, { useState } from 'react'

import { ScreenLayout } from '../../Layouts'
import { CodeInput, DateInput, DateInputSimple, PhoneInput, TextInput } from '../Input'
import { Box, Content, Label } from './styles'

export function TextInputPlayground() {
  const [uncontrolledValue, setUncontrolledValue] = useState('')
  const [controlledValue, setControlledValue] = useState('initialControlledValue')
  const [date, setDate] = useState<Dayjs>()

  return (
    <ScreenLayout title="TextInput" hasBack>
      <Content>
        <Box>
          <Label>DateInput Simple</Label>
          <DateInputSimple label="Date of birth" date={date} onChange={setDate} />
        </Box>
        <Box>
          <Label>Simple</Label>
          <TextInput />
        </Box>
        <Box>
          <Label>Disabled</Label>
          <TextInput editable={false} />
        </Box>
        <Box>
          <Label>{`Uncontrolled: ${uncontrolledValue}`}</Label>
          <TextInput label="With label" onChange={setUncontrolledValue} />
        </Box>
        <Box>
          <Label>{`Uncontrolled: ${controlledValue}`}</Label>
          <TextInput value={controlledValue} onChange={setControlledValue} />
        </Box>
        <Box>
          <Label>Max length 10 characters</Label>
          <TextInput maxLength={10} />
        </Box>
        <Box>
          <Label>Placeholder</Label>
          <TextInput placeholder="placeholder text" />
        </Box>
        <Box>
          <Label>Password</Label>
          <TextInput secureTextEntry />
        </Box>
        <Box>
          <Label>Number Pad</Label>
          <TextInput inputMode="numeric" />
        </Box>
        <Box>
          <Label>With an error</Label>
          <TextInput error="This is an error" />
        </Box>
        <Box>
          <Label>Code Input</Label>
          <CodeInput length={6} />
        </Box>
        <Box>
          <Label>Phone Input</Label>
          <PhoneInput />
        </Box>
        <Box>
          <Label>Date Input</Label>
          <DateInput mode="datetime" onChange={() => {}} format="l" />
        </Box>
      </Content>
    </ScreenLayout>
  )
}
