import { ScreenLayout } from '@components/Layouts'
import { TrackingChart, TrackingChartAssetTrackingTypeWithoutHeightEnum } from '@components/Tracking'
import { getTrackingConfig } from '@components/Tracking/trackingConfig'
import { Route, useRoute } from '@react-navigation/native'
import { useAuthStore } from '@stores/auth'
import React, { useMemo } from 'react'
import styled from 'styled-components/native'
import { RouteName } from '../../routeNames'
import { isSupportedTrackingType } from '../helpers'

const Wrapper = styled.View`
  flex: 1;
`

export function TrackingDetails() {
  const { params } =
    useRoute<Route<RouteName.TrackingDetails, { trackingType: TrackingChartAssetTrackingTypeWithoutHeightEnum }>>()
  const trackingType = params?.trackingType
  const [user] = useAuthStore((state) => [state.user])
  const isSupported = isSupportedTrackingType(trackingType)

  const trackingConfig = useMemo(() => {
    return !!trackingType && getTrackingConfig(trackingType)
  }, [user])

  if (!trackingConfig) return null

  return (
    <ScreenLayout title={trackingConfig.title} hasBack isStatic>
      {isSupported && (
        <Wrapper>
          <TrackingChart trackingConfig={trackingConfig} trackingType={trackingType} isControlled />
        </Wrapper>
      )}
    </ScreenLayout>
  )
}
