// injected by webpack for sentry dist flag
// biome-ignore lint/style/noVar: is global
declare var __VERSION_CODE__: string

export const config = {
  tunaUrl: process.env.PUBLIC_DOLPHIN_TUNA_URL || window.PUBLIC_DOLPHIN_TUNA_URL,
  stage: process.env.PUBLIC_STAGE || window.PUBLIC_STAGE,
  sentryDsn: process.env.PUBLIC_DOLPHIN_SENTRY_DSN || window.PUBLIC_DOLPHIN_SENTRY_DSN,
  mixpanelKey: process.env.PUBLIC_DOLPHIN_MIXPANEL_KEY || window.PUBLIC_DOLPHIN_MIXPANEL_KEY,
  versionCode: __VERSION_CODE__,
}
