import { Colors } from '@vetahealth/fishing-gear/colors'
import React, { useEffect } from 'react'
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated'
import styled from 'styled-components/native'

import { Icon } from '../Icon'

const Wrapper = styled.View<{ height: number; noBorderRadius?: boolean }>`
  width: 100%;
  height: ${({ height = 0 }) => height}px;
  border-radius: ${({ noBorderRadius, theme }) => (noBorderRadius ? 0 : theme.borderRadius.medium)}px;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  justify-content: center;
  align-items: center;
`
export function ImageLoading({
  noBorderRadius,
  width,
}: {
  noBorderRadius?: boolean
  width: number
}): JSX.Element {
  const animation = useSharedValue(0)

  const style = useAnimatedStyle(() => ({
    transform: [{ scale: interpolate(animation.value, [0, 1], [1, 0.9]) }],
  }))

  useEffect(() => {
    animation.value = withRepeat(withTiming(1, { duration: 1000 }), -1, true)
  }, [])

  return (
    <Wrapper height={width * (2 / 3)} noBorderRadius={noBorderRadius}>
      <Animated.View style={style}>
        <Icon icon={['fad', 'image']} size={80} color={Colors.mauve300} />
      </Animated.View>
    </Wrapper>
  )
}
