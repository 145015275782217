import './src/lib/init'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { AppRegistry } from 'react-native'
import { name as appName } from './app.json'
import { App } from './src/App'

AppRegistry.registerComponent(appName, () => App)
createRoot(document.getElementById('root')).render(<App />)
