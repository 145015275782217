import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'
import { RouteName } from '../../routeNames'
import { ActiveTask } from './ActiveTask'

const Stack = createNativeStackNavigator()

export function Task() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={RouteName.ActiveTask}>
      <Stack.Screen name={RouteName.ActiveTask} component={ActiveTask} />
    </Stack.Navigator>
  )
}
