import dayjs from 'dayjs'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { api } from '@lib/api'
import { takeLeading } from '@lib/takeLeading'

import { useAuthStore } from '../auth'
import { TasksState } from './types'

export const initialState: Pick<TasksState, 'taskByRef' | 'task' | 'info' | 'hasTask'> = {
  taskByRef: undefined,
  task: undefined,
  info: undefined,
  hasTask: false,
}

export const useTasksStore = createWithEqualityFn<TasksState>(
  (set, get) => ({
    ...initialState,
    setTask: ({ task, info }) => set({ task, info }),
    setInfo: (info) => set({ info }),
    getTask: takeLeading(async () => {
      const { user } = useAuthStore.getState()
      const { task } = get()

      if (user?.locale === task?.locale) return

      const activeTask = await api.getActiveTask()

      set({ task: activeTask, hasTask: !!activeTask })
    }),
    getTaskByRef: takeLeading(async (ref) => {
      const { user } = useAuthStore.getState()
      const { taskByRef, getTask } = get()

      if (user?.locale === taskByRef?.locale && ref === taskByRef?.ref) return

      const activeTask = await api.getTaskByRef(ref)

      if (activeTask) {
        set({ taskByRef: activeTask, hasTask: true })
      } else {
        if (!get().task) await getTask() // fallback
      }
    }),
    submitTask: async ({ task, response }) => {
      const { activeTask, infoScreen } =
        (await api.submitTask({
          id: task.id,
          version: task.version || dayjs().toISOString(),
          locale: task.locale || 'en-US',
          followUp: task.followUp,
          response,
        })) || {}

      set({
        task: activeTask,
        info: infoScreen,
        taskByRef: undefined,
        hasTask: !!activeTask,
      })
    },
    resetInfo: () => set({ info: undefined }),
    reset: () => set(initialState),
  }),
  shallow,
)
