import * as React from 'react'
import { View } from 'react-native'
import Svg, { Circle, Ellipse, Path } from 'react-native-svg'
import type { SvgProps } from 'react-native-svg'

export const SvgSofa = (props: SvgProps) => (
  <View>
    {
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        data-name="Layer 1"
        viewBox="0 0 830.942 469.301"
        {...props}
      >
        <Ellipse cx={505.681} cy={417.301} fill="#f0f0f0" rx={294} ry={52} />
        <Path
          fill="#e4e4e4"
          d="M779.04 317.801h-551v-93.543h8.5v-69.626a61.944 61.944 0 0 1 61.874-61.874h411.251a61.944 61.944 0 0 1 61.874 61.874v69.626h7.5Z"
        />
        <Path
          fill="#cacaca"
          d="M793.969 420.758h-27.43v-.5a91.604 91.604 0 0 0-91.5-91.5h-343a91.604 91.604 0 0 0-91.5 91.5v.5h-27.428a23.597 23.597 0 0 1-23.571-23.57V244.275c-7.965-4.5-14.443-28.94-10.236-48.459 2.641-12.249 10.265-27.042 31.586-27.958 20.756-.893 36.162 18.593 37.538 37.35a40.454 40.454 0 0 1-8.148 27.549h.26v21.814c45.008-14.423 90.08-21.763 133.974-21.814a410.048 410.048 0 0 1 132.03 21.973c88.384-28.587 175.852-29.143 259.996-1.647v-20.326h.26a40.454 40.454 0 0 1-8.148-27.548c1.51-20.574 18.338-37.33 37.515-37.351h.064c22.614 0 30.612 13.613 33.34 25.04 4.828 20.222-4.036 46.84-12.031 51.377v152.911a23.597 23.597 0 0 1-23.571 23.571Z"
        />
        <Path fill="#f0f0f0" d="M170.681 274.301h-168v14.5h3v131.5h17v-131.5h129v131.5h17v-131.5h2v-14.5z" />
        <Path
          fill="#9e616a"
          d="M600.79 263.07s8.248-13.53 18.836-5.53 30.196 21.498 30.196 21.498 4.017 8.634-6.378 5.39-44.328-8.46-44.328-8.46Z"
          data-name="Path 164"
        />
        <Path
          fill="#2f2e41"
          d="M560.277 279.887c-8.874 0-16.997-.85-23.452-2.86-7.672-2.389-12.489-6.252-14.319-11.49a13.53 13.53 0 0 1 2.12-13.413c11.35-14.789 50.735-18.96 56.02-19.453l10.72-20.3c.686-4.844-2.56-8.511 3.048-8.603l66.33-.9c5.506-.116 3.943 3.294 4.886 8.017l10.106 18.04-43.493 18.356 6.575 16.024-.429.25c-12.729 7.383-49.336 16.331-78.112 16.332Z"
          data-name="Path 161"
        />
        <Path
          fill="#2f2e41"
          d="M669.715 229.695s60.742-6.951 56.78 20.854-50.178 47.5-120.163 0l7.923-13.903s26.08-1.297 41.925-8.248Z"
          data-name="Path 162"
        />
        <Path
          fill="#3f3d56"
          d="m664.102 88.98-21.837-8.167-42.327-.546-18.22 8.714 5.118 29.409s-10.332 17.494 1.044 34.99l2.552 62.344s40.808 23.664 75.254-4.753l-7.785-57.964s13.022-26.894 2.928-36.885Z"
        />
        <Path
          fill="#9e616a"
          d="M653.2 256.315s-6.802-14.312-18.162-7.451-32.26 18.256-32.26 18.256-4.889 8.171 5.786 6.022 44.966-3.826 44.966-3.826Z"
          data-name="Path 163"
        />
        <Path
          fill="#fff"
          d="M642.545 209.03h-42.603a4.043 4.043 0 0 1-4.043-4.043l-1.877-55.745a4.043 4.043 0 0 1 4.043-4.043h46.357a4.043 4.043 0 0 1 4.043 4.043l-1.877 55.745a4.043 4.043 0 0 1-4.043 4.043Z"
        />
        <Circle cx={621.243} cy={162.096} r={3.755} fill="#a8dfd3" />
        <Path
          fill="#9e616a"
          d="M622.415 186.841a10.18 10.18 0 0 0-15.157-3.724l-19.894-12.055-10.274 10.303 28.333 16.629a10.234 10.234 0 0 0 16.992-11.153Z"
        />
        <Path
          fill="#9e616a"
          d="M621.459 182.302a10.18 10.18 0 0 1 15.585-.855l21.78-8.166 8.19 12.026-30.92 11.1a10.234 10.234 0 0 1-14.635-14.105Z"
        />
        <Path
          fill="#3f3d56"
          d="M661.749 88.737s14.141-2.828 17.912 9.428 22.626 57.507 16.97 64.107-26.397 24.511-32.997 28.282-9.427 3.771-9.427 3.771l-8.485 3.771-2.828-21.683 9.676-5.732 14.835-12.18-8.484-25.455ZM583.36 89.138s-14.142-2.828-17.913 9.428-22.626 57.507-16.97 64.106 26.398 24.512 32.997 28.283 9.427 3.77 9.427 3.77l8.485 3.772 2.828-21.683-9.676-5.732-14.835-12.18 13.67-33.468Z"
        />
        <Circle cx={622.769} cy={39.506} r={32.588} fill="#9e616a" />
        <Path
          fill="#2f2e41"
          d="M655.186 15.67a20.793 20.793 0 0 0-10.88-10.511 19.852 19.852 0 0 0-14.884.013c-4.308-2.722-9.095-4.88-14.136-5.147s-10.371 1.603-13.478 5.72a22 22 0 0 1-2.354 3.059c-2.498 2.313-6.293 1.979-9.612 1.506l4.468 3.828-5.407 1.412 6.309 3.827c-6.106 3.664-8.88 11.663-8.148 18.927.694 6.877 4.014 13.166 7.983 18.756 1.584-3.342 1.243-7.34 1.216-11.103-.027-3.976.677-8.492 3.863-10.714 2.15-1.499 4.913-1.566 7.505-1.552a344.797 344.797 0 0 1 39.114 2.442 11.01 11.01 0 0 1 5.007 1.453c2.87 1.958 3.43 6 3.719 9.545a69.165 69.165 0 0 0 2.207-17.93c-.04-4.628-.572-9.35-2.492-13.531Z"
        />
        <Path
          fill="#feb8b8"
          d="M398.037 326.139s5.742 13.585 16.585 7.668 30.741-15.664 30.741-15.664 4.879-7.4-5.135-5.835-41.97 1.728-41.97 1.728Z"
          data-name="Path 163"
        />
        <Path
          fill="#ffb6b6"
          d="M316.964 40.233a11.223 11.223 0 0 1 .255 15.673 10.75 10.75 0 0 1-1.346 1.167l11.275 37.588-19.188-6.002-7.766-34.774a11.181 11.181 0 0 1 1.366-13.61 10.76 10.76 0 0 1 15.404-.042Z"
        />
        <Path fill="#feb8b8" d="m398.438 318.288 12.055-4.249-11.017-49.559-17.793 6.272 16.755 47.536z" />
        <Path
          fill="#feb8b8"
          d="M442.803 305.433s10.536 10.32 18.259.68 22.326-26.304 22.326-26.304 1.648-8.709-6.989-3.405-38.058 17.776-38.058 17.776Z"
          data-name="Path 163"
        />
        <Path fill="#feb8b8" d="m440.146 298.034 9.485-8.568-29.273-41.479-13.999 12.647 33.787 37.4z" />
        <Path
          fill="#a8dfd3"
          d="m323.316 78.738 2.201 6.693 9.897 30.06a15.932 15.932 0 0 1-9.935 20.236 15.588 15.588 0 0 1-11.168-.569l-55.693-25.346a16.648 16.648 0 0 1-7.33-24.156 15.851 15.851 0 0 1 21.027-5.357l38.606 21.916-4.359-20.786Z"
        />
        <Path
          fill="#2f2e41"
          d="m410.959 294.839-19.021 8.758-38.743-75.718c-33.214 18.541-65.883 32.245-97.327 35.056a19.773 19.773 0 0 1-21.276-17.828q-.079-.76-.099-1.523c-.26-11.752 1.845-25.186 4.883-39.283l53.492.667 4.364 6.68 48.226-24.347a19.756 19.756 0 0 1 26.518 9.123q.392.787.715 1.606Z"
        />
        <Path
          fill="#2f2e41"
          d="m434.955 267.562-19.02 8.757-61.354-60.355c-33.214 18.541-65.884 32.244-97.327 35.056a19.773 19.773 0 0 1-21.277-17.828q-.078-.76-.099-1.523c-.26-11.752 1.845-25.186 4.883-39.283l56.602-14.747 1.254 22.094 48.226-24.347a19.756 19.756 0 0 1 26.518 9.123q.392.787.716 1.606Z"
        />
        <Ellipse
          cx={435.68}
          cy={256.928}
          fill="#ffb6b6"
          rx={25.589}
          ry={26.159}
          transform="rotate(-19.413 -286.062 688.641)"
        />
        <Path
          fill="#3f3d56"
          d="M293.017 72.462a2.053 2.053 0 0 0 1.624 2.342c.674-.148 19.286 4.289 19.324 3.016a1.984 1.984 0 0 0 .801-1.317l8.306-54.287a2.057 2.057 0 0 0-.49-1.673c.26-1.415-18.83.237-19.552.095a1.95 1.95 0 0 0-1.846 1.66Z"
        />
        <Path
          fill="#a8dfd3"
          d="M319.228 22.488s-16.1 1.038-15.915.776c-.403.124-1.404-.167-1.451.513l-7.619 47.728c-.626 1.203 17.254 2.922 17.533 3.3 1.483.336 7.441-51.218 8.023-51.677a.56.56 0 0 0-.455-.634.534.534 0 0 0-.116-.006ZM278.15 75.102a7.094 7.094 0 0 0-.18-1.865 6.938 6.938 0 0 0-8.25-5.25l-11.664 2.695a6.859 6.859 0 0 0-5.047 8.32 7.066 7.066 0 0 0 2.517 3.948 31.418 31.418 0 0 0-17.774 29.602c-3.825 24.51-3.406 51.151 2.787 80.701l58.148 6.1 4.67-47.264 1.707-7.336a58.99 58.99 0 0 0 1.288-3.134 55.966 55.966 0 0 0 4.033-18.298 41.795 41.795 0 0 0-1.623-13.287c-4.007-13.795-15.13-25.117-30.612-34.932Z"
        />
        <Path
          d="M494.915 338.67a55.974 55.974 0 0 1-4.034 18.298c-.39 1.041-.823 2.081-1.288 3.134l-1.706 7.336c-17.791-5.59-43.83-43.105-43.83-43.105l14.79-8.685Z"
          opacity={0.2}
          transform="translate(-184.53 -215.35)"
        />
        <Path
          fill="#ffb6b6"
          d="M297.337 55.629a11.249 11.249 0 0 0 8.642 13.095 10.794 10.794 0 0 0 1.772.208l11.914 37.392 12.466-15.731-13.225-33.096a11.218 11.218 0 0 0-8.816-10.48 10.735 10.735 0 0 0-12.753 8.612Z"
        />
        <Path
          fill="#a8dfd3"
          d="m313.335 92.855 18.501-3.703 12.1 36.751a16.186 16.186 0 0 1-2.929 15.495 15.508 15.508 0 0 1-18.17 4.174l-55.695-25.35a16.722 16.722 0 0 1-8.794-21.246 15.858 15.858 0 0 1 20.911-9.02 16.271 16.271 0 0 1 1.583.755l38.6 21.916Z"
        />
        <Path
          fill="#2f2e41"
          d="M262.287 6.224c-6.226-1.217-12.505.988-18.428 3.16l-12.873 4.723c-5.208 1.91-10.679 4.005-14.148 8.378s-3.894 11.782.607 15.168c-11.491-4.69-25.031-1.299-34.65 6.402a33.362 33.362 0 0 0-10.794 14.335c-2.26 6.223-2.131 13.165-.76 19.7s3.928 12.758 6.47 18.935c1.6 3.884 3.62 8.188 7.529 9.566 3.606 1.27 7.603-.522 10.34-3.166 4.77-4.61 6.78-11.457 7.74-18.113.96-6.657 1.072-13.49 2.915-19.939s5.845-12.71 12.054-14.98c-2.073 7.642.303 16.851 6.85 21.296 4.708 3.196 10.815 3.564 16.278 2.28 5.462-1.285 10.44-4.057 15.339-6.798 3.317-1.856 7.076-4.442 7.025-8.316-.05-3.8-3.716-6.362-5.976-9.418-4.36-5.898-2.981-15.271 2.866-19.475l-.716-.337c5.669-1.133 11.883-4.125 13.088-9.872 1.316-6.274-4.53-12.313-10.756-13.53Z"
        />
        <Path
          fill="#3f3d56"
          d="M271.415 270.301a44.373 44.373 0 0 1-16.293-3.11 42.188 42.188 0 0 1-23.472-22.442l-32.073-73.708c-9.221-21.192 1.114-45.684 23.039-54.598l.544-.222 65.52 150.575-.544.222a44.361 44.361 0 0 1-16.72 3.283ZM739.47 263.086a36.295 36.295 0 0 1-14.102-2.855l-.46-.194 55.44-131.816.461.194a36.542 36.542 0 0 1 19.494 47.797l-27.138 64.525a36.558 36.558 0 0 1-33.695 22.349Z"
        />
        <Path
          fill="#f0f0f0"
          d="M.017 222.078a1.5 1.5 0 0 1 .656-1.028l52.773-34.84a3.954 3.954 0 0 1-1.894-1.659 4.012 4.012 0 0 1 1.464-5.464l4.33-2.5a4.011 4.011 0 0 1 5.464 1.464 3.939 3.939 0 0 1 .507 1.643l38.093-25.148a23.059 23.059 0 0 1 14.27-13.156V130.3h14v11.089a23.007 23.007 0 0 1 16 21.911v3h-14a9 9 0 0 1-18 0h-14v-3a23.123 23.123 0 0 1 .406-4.285l-96.37 63.62 40.032 48.67c.31-.002.62-.005.933-.005 3.194 0 6.288.076 9.237.206a3.957 3.957 0 0 1-.228-1.544 4.012 4.012 0 0 1 4.202-3.786l4.994.26a4.012 4.012 0 0 1 3.786 4.203 3.932 3.932 0 0 1-.542 1.772c8.83.938 14.55 2.231 14.55 2.89 0 1.078-15.362.051-34.591.002a1.495 1.495 0 0 1-2.566.45l-.37-.45c-19.173.053-34.472 1.074-34.472-.003 0-1.014 13.602-3.538 31.226-3.943L.341 223.254a1.5 1.5 0 0 1-.324-1.176ZM135.848 271.652v-68.386c0-2.03 3.14-3.682 7-3.682s7 1.652 7 3.682v68.386c0 2.03-3.14 3.682-7 3.682s-7-1.652-7-3.682Z"
        />
        <Path
          fill="#f0f0f0"
          d="M119.098 271.652v-68.386c0-2.03 3.14-3.682 7-3.682s7 1.652 7 3.682v68.386c0 2.03-3.14 3.682-7 3.682s-7-1.652-7-3.682ZM102.348 271.652v-68.386c0-2.03 3.14-3.682 7-3.682s7 1.652 7 3.682v68.386c0 2.03-3.14 3.682-7 3.682s-7-1.652-7-3.682Z"
        />
      </Svg>
    }
  </View>
)
