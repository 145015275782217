import { Colors } from '@vetahealth/fishing-gear/colors'
import React from 'react'
import {
  Pressable as NativePressable,
  PressableStateCallbackType as NativePressableCallbackType,
  PressableProps as NativePressableProps,
  ViewStyle,
} from 'react-native'

type PressableStateCallbackType = NativePressableCallbackType & {
  hovered?: boolean
}

type PressableProps = {
  pressStyle?: { color?: Colors; opacity?: number }
  hoverStyle?: { color?: Colors; opacity?: number }
  style?: ViewStyle
  render?: ({ pressed, hovered }: PressableStateCallbackType) => React.ReactNode
  noFeedback?: boolean
  isDisabled?: boolean
}

export function Pressable({
  render,
  style,
  pressStyle = {},
  hoverStyle = {},
  noFeedback = false,
  isDisabled = false,
  ...props
}: Omit<NativePressableProps, 'style' | 'children'> & PressableProps): JSX.Element {
  const styles = {
    press: { opacity: 0.75, ...pressStyle },
    hover: { opacity: 0.8, ...hoverStyle },
  }

  return (
    <NativePressable
      style={({ pressed, hovered }: PressableStateCallbackType) => [
        style,
        (!isDisabled || !noFeedback) && {
          ...(pressed && { opacity: styles.press.opacity }),
          ...(hovered && { opacity: styles.hover.opacity }),
          ...(styles.press.color && pressed && { backgroundColor: styles.press.color }),
          ...(styles.hover.color && hovered && { backgroundColor: styles.hover.color }),
        },
        isDisabled && { opacity: 0.5 },
      ]}
      {...props}
      disabled={isDisabled}
    >
      {({ pressed, hovered }: PressableStateCallbackType) => (render ? render({ pressed, hovered }) : null)}
    </NativePressable>
  )
}
