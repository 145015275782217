import { Colors } from '@vetahealth/fishing-gear/colors'
import { SupportedLocaleEnum } from '@vetahealth/tuna-can-api'
import { enUS as dateFnsEnUS } from 'date-fns/locale/en-US'
import { es as dateFnsEs } from 'date-fns/locale/es'
import dayjs from 'dayjs'
import noop from 'lodash/noop'
import range from 'lodash/range'
import React, { forwardRef, useImperativeHandle } from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker/dist/calendar'
import 'react-datepicker/dist/react-datepicker.css'
import { StyleSheet } from 'react-native'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import { DefaultTheme, useTheme } from 'styled-components/native'
import { IconButton } from '../Button'
import { BaseInput, InputWrapper } from './BaseInput'
import { InputError } from './InputError'
import { DateInputMethods, DateInputProps } from './types'

registerLocale(SupportedLocaleEnum.EnUs, dateFnsEnUS)
registerLocale(SupportedLocaleEnum.Es419, dateFnsEs)
registerLocale(SupportedLocaleEnum.EsUs, dateFnsEs)

const GlobalStyle = createGlobalStyle`
  .react-datepicker {
    display: flex;
  }
  .react-datepicker,
  .react-datepicker__time-list {
    ${({ theme }) => theme.font.weight.normal}
    background-color: ${({ theme }) => theme.colors.background};
  }
  .react-datepicker,
  .react-datepicher__header,
  .react-datepicker__day,
  .react-datepicker__day-name,
  .react-datepicker-time__header {
    color: ${({ theme }) => theme.colors.text};
  }
  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
  }
  .react-datepicker__day:hover,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
  }
  .react-datepicker-time__header,
  .react-datepicker__day-name,
  .react-datepicker__day--today {
    ${({ theme }) => theme.font.weight.semiBold};
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    color: ${Colors.white};
    &:hover {
      background-color: ${Colors.mauve500};
    }
  }
  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.disabled};
  }
`

const style = StyleSheet.create({
  wrapper: {
    zIndex: 1,
  },
  input: {
    width: '100%',
  },
})
const CustomHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`
const CustomSelect = styled.select`
  font-family: 'Montserrat';
  font-weight: 600;
  border: 0px solid transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
  padding: 0 5px;
  border-radius: 5px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
`
const CustomOption = styled.option`
  font-family: 'Montserrat';
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  color: ${({ theme }) => theme.colors.text};
`

function CustomHeader({
  increaseMonth,
  decreaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  changeYear,
  changeMonth,
  date,
  theme,
}: ReactDatePickerCustomHeaderProps & { theme: DefaultTheme }): JSX.Element {
  const months = dayjs.months()
  const years = range(1900, dayjs().year() + 1, 1)

  return (
    <ThemeProvider theme={theme}>
      <CustomHeaderWrapper>
        <IconButton
          size="small"
          icon="arrow-left"
          iconSize={12}
          variant="plain"
          onPress={decreaseMonth}
          isDisabled={prevMonthButtonDisabled}
        />
        <CustomSelect
          value={months[dayjs(date).month()]}
          onChange={({ target }) => changeMonth(months.indexOf(target.value))}
        >
          {months.map((month) => (
            <CustomOption key={month} value={month}>
              {month}
            </CustomOption>
          ))}
        </CustomSelect>
        <CustomSelect value={dayjs(date).year()} onChange={({ target }) => changeYear(Number(target.value))}>
          {years.map((year) => (
            <CustomOption key={year} value={year}>
              {year}
            </CustomOption>
          ))}
        </CustomSelect>
        <IconButton
          size="small"
          icon="arrow-right"
          iconSize={12}
          variant="plain"
          onPress={increaseMonth}
          isDisabled={nextMonthButtonDisabled}
        />
      </CustomHeaderWrapper>
    </ThemeProvider>
  )
}
export const DateInput = forwardRef<DateInputMethods, DateInputProps>(
  ({ value, placeholder, error, format, mode, min, max, onChange, editable = true }, ref) => {
    const theme = useTheme()

    useImperativeHandle(ref, () => ({
      open: noop,
    }))

    return (
      <InputWrapper style={style.wrapper}>
        <GlobalStyle theme={theme} />
        <ReactDatePicker
          disabled={!editable}
          selected={value?.toDate()}
          value={value?.format(format)}
          onChange={(newDate) => onChange(dayjs(newDate))}
          placeholderText={placeholder}
          locale={dayjs.locale()}
          minDate={min?.toDate()}
          maxDate={max?.toDate()}
          dateFormat={'Pp'}
          showTimeSelect={mode === 'datetime'}
          timeIntervals={5}
          filterTime={(time) => dayjs(time).isBefore(dayjs())}
          customInput={<BaseInput editable={editable} style={style.input} />}
          showPopperArrow={false}
          renderCustomHeader={(props) => <CustomHeader {...props} theme={theme} />}
        />
        <InputError error={error} />
      </InputWrapper>
    )
  },
)
