import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'
import { RouteName } from '../../routeNames'
import { Messages } from './Messages'
import { NewMessageModal } from './NewMessageModal'

const Stack = createNativeStackNavigator()

export function Messaging() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={RouteName.Messages}>
      <Stack.Screen name={RouteName.Messages} component={Messages} />
      <Stack.Screen
        name={RouteName.NewMessageModal}
        component={NewMessageModal}
        options={{ presentation: 'modal', animation: 'slide_from_bottom' }}
      />
    </Stack.Navigator>
  )
}
