import { SelectButton } from '@components/Elements/Button'
import { useI18n } from '@lib/i18n'
import { useAuthStore } from '@stores/auth'
import React from 'react'

export function PushNotification({ onChannelChange }: { onChannelChange?: () => void }): JSX.Element {
  const t = useI18n()
  const [user] = useAuthStore((state) => [state.user])

  return (
    <SelectButton
      type="radio"
      label={t('settings.labels.pushNotifications')}
      isChecked={user?.preferences.notifications.channel === 'push'}
      onPress={user?.preferences.notifications.pushDevices?.length ? onChannelChange : undefined}
      hasBottomBorder
      isDisabled={!user?.preferences.notifications.pushDevices?.length}
    />
  )
}
