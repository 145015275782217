import * as React from 'react'
import { View } from 'react-native'
import Svg, { Circle, Path } from 'react-native-svg'
import type { SvgProps } from 'react-native-svg'

export const SvgEmpty = (props: SvgProps) => (
  <View>
    {
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        data-name="Layer 1"
        viewBox="0 0 862.703 644.786"
        {...props}
      >
        <Path fill="#9e616a" d="M629.943 612.644h-17.166l-8.169-66.209h25.335v66.209z" />
        <Path
          fill="#2f2e41"
          d="M639.003 642.385h-12.31l-2.198-11.622-5.627 11.622h-32.649a7.34 7.34 0 0 1-4.17-13.379L608.12 611v-11.75l27.424 1.637Z"
        />
        <Path fill="#9e616a" d="m731.923 590.981-13.775 10.243-46.063-48.255 20.33-15.118 39.508 53.13z" />
        <Path
          fill="#2f2e41"
          d="m756.94 609.44-9.878 7.346-8.699-8.015 2.42 12.685-26.2 19.482a7.34 7.34 0 0 1-11.33-8.248l10.178-30.007-7.011-9.428 22.983-15.05ZM649.927 271.04s32.57 28.138 17.542 108.352l-18.345 78.597 59.83 99.256-19.077 23.207-77.78-107.433-28.185-66.114-7.909-117.669Z"
        />
        <Path fill="#2f2e41" d="m599.447 425.746-1.959 30.338 5.995 129.281 28.209-4.913 5.391-92.046-37.636-62.66z" />
        <Path fill="#ffb6b6" d="m237.445 628.211 15.351-.001 7.302-59.209-22.655.001.002 59.209z" />
        <Path
          fill="#2f2e41"
          d="m233.53 623.2 4.32-.001 16.87-6.86 9.04 6.859h.002a19.266 19.266 0 0 1 19.265 19.265v.626l-49.497.002Z"
        />
        <Path fill="#ffb6b6" d="m296.932 618.538 14.973 3.38 20.166-56.146-22.099-4.99-13.04 57.756z" />
        <Path
          fill="#2f2e41"
          d="m294.216 612.786 4.215.952 17.966-2.976 7.308 8.682h.001a19.266 19.266 0 0 1 14.549 23.036l-.138.61-48.282-10.9ZM218.003 266.236c-7.197 21.706-6.436 45.268 1.73 70.556l3.491 142.378s-5.201 93.369 11.582 126.225h24.34l12.051-134.752 1.513-90.445 52.183 76.305-33.237 146.689 29.957 2.678 53.934-159.191-66.544-154.443Z"
        />
        <Path
          fill="#e6e6e6"
          d="M498.698 204.408c18.617-16.777 46.309-25.212 69.537-15.805a115.466 115.466 0 0 0-51.888 59.935c-3.698 9.838-6.787 21.166-15.882 26.433-5.66 3.278-12.7 3.438-19.046 1.856-6.345-1.583-12.162-4.754-17.899-7.894l-1.632-.037c7.328-23.966 18.192-47.712 36.81-64.488Z"
        />
        <Path
          fill="#fff"
          d="M568.105 189.112a98.692 98.692 0 0 0-54.906 25.93 42.5 42.5 0 0 0-8.346 10.378 24.376 24.376 0 0 0-2.817 12.515c.1 4.059.673 8.198-.215 12.21a14.925 14.925 0 0 1-7.424 9.689c-4.546 2.613-9.76 3.437-14.886 4.065-5.692.698-11.615 1.332-16.543 4.525-.597.387-1.162-.562-.566-.949 8.573-5.554 19.42-3.533 28.637-7.24 4.302-1.73 8.107-4.767 9.454-9.357 1.178-4.015.591-8.284.454-12.392a26.01 26.01 0 0 1 2.3-12.34 39.29 39.29 0 0 1 7.915-10.66 95.75 95.75 0 0 1 24.333-17.42 100.443 100.443 0 0 1 32.6-10.058c.703-.09.708 1.014.01 1.104Z"
        />
        <Path
          fill="#fff"
          d="M517.799 210.184a14.807 14.807 0 0 1 1.632-19.104c.506-.498 1.305.265.798.764a13.71 13.71 0 0 0-1.482 17.774c.415.577-.535 1.14-.948.566ZM501.714 235.884a28.54 28.54 0 0 0 20.394-4.083c.598-.385 1.163.564.566.948a29.685 29.685 0 0 1-21.23 4.206c-.701-.126-.427-1.196.27-1.07ZM545.798 194.34a8.381 8.381 0 0 0 6.269 4.895c.702.118.427 1.188-.27 1.071a9.392 9.392 0 0 1-6.947-5.399.57.57 0 0 1 .19-.757.555.555 0 0 1 .758.19Z"
        />
        <Path
          fill="#e6e6e6"
          d="M593.813 269.507a79.27 79.27 0 0 0-1.328-.175 110.379 110.379 0 0 0-17.882-.908c-.462.006-.93.02-1.392.04a116.365 116.365 0 0 0-41.75 9.61 113.005 113.005 0 0 0-15.163 8.056c-6.688 4.234-13.602 9.357-21.078 11.084a19.386 19.386 0 0 1-2.362.421L461.97 270.89c-.04-.096-.085-.186-.125-.282l-1.283-1.011c.24-.176.49-.353.73-.529.137-.102.282-.198.42-.3.094-.067.19-.133.27-.199.032-.022.063-.044.088-.059.082-.065.164-.117.24-.176q2.103-1.489 4.234-2.954c.006-.007.006-.007.02-.008a166.157 166.157 0 0 1 34.6-18.6c.367-.138.74-.284 1.12-.41a107.831 107.831 0 0 1 16.94-4.767 95.329 95.329 0 0 1 9.553-1.334 79.272 79.272 0 0 1 24.723 1.751c16.143 3.743 30.91 12.608 39.656 26.433.224.354.44.702.658 1.063Z"
        />
        <Path
          fill="#fff"
          d="M593.404 269.84a98.692 98.692 0 0 0-59.452-12.354 42.5 42.5 0 0 0-12.91 3.26 24.376 24.376 0 0 0-9.785 8.298c-2.363 3.3-4.398 6.95-7.523 9.62a14.925 14.925 0 0 1-11.761 3.265c-5.203-.65-9.862-3.132-14.333-5.716-4.965-2.87-10.076-5.93-15.933-6.347-.71-.05-.59-1.149.12-1.098 10.188.726 17.632 8.87 27.224 11.46 4.476 1.208 9.342 1.075 13.182-1.78 3.358-2.495 5.46-6.258 7.823-9.62a26.01 26.01 0 0 1 9.265-8.47 39.29 39.29 0 0 1 12.738-3.744 95.75 95.75 0 0 1 29.917.741 100.443 100.443 0 0 1 32.085 11.596c.616.351-.045 1.237-.657.888Z"
        />
        <Path
          fill="#fff"
          d="M540.55 256.376a14.807 14.807 0 0 1 12.806-14.27c.704-.094.883.997.177 1.09a13.711 13.711 0 0 0-11.884 13.3c-.016.71-1.114.587-1.098-.12ZM512.234 267.212a28.54 28.54 0 0 0 18.742 9.018c.71.053.59 1.151-.119 1.098a29.685 29.685 0 0 1-19.483-9.423c-.484-.523.38-1.213.86-.693ZM572.445 260.584a8.381 8.381 0 0 0 2.059 7.682c.49.516-.374 1.205-.86.692a9.392 9.392 0 0 1-2.297-8.493.57.57 0 0 1 .609-.49.555.555 0 0 1 .49.609Z"
        />
        <Path
          fill="#e6e6e6"
          d="M51.273 627.136c-1.45 5.44-5.26 9.97-9.86 13.27-.75.54-1.52 1.04-2.3 1.51-.24.14-.48.29-.73.42q-.405.24-.81.45h-21.63c-.39-.79-.77-1.59-1.15-2.38-9.27-19.48-15.78-40.5-14.67-61.91a79.254 79.254 0 0 1 5.17-24.25c5.94-15.47 16.78-28.86 31.69-35.6.37-.17.76-.34 1.14-.5l-.36 1.28a110.785 110.785 0 0 0-3.38 17.59c-.06.46-.11.92-.15 1.39a116.054 116.054 0 0 0 3.72 42.69l.03.1q1.275 4.605 2.96 9.07c.88 2.35 1.83 4.67 2.87 6.95 4.34 9.57 10.16 19.77 7.46 29.92Z"
        />
        <Path
          fill="#fff"
          d="M38.393 518.596c-.21.28-.42.55-.63.83a98.129 98.129 0 0 0-11.12 18.76c-.16.33-.31.66-.44 1a97.814 97.814 0 0 0-7.82 29.24 1.49 1.49 0 0 0-.02.21c-.25 2.36-.4 4.74-.46 7.12a42.48 42.48 0 0 0 1.43 13.24 23.769 23.769 0 0 0 5.46 9.42c.25.27.5.54.77.8.2.21.42.42.63.62 2.02 1.93 4.23 3.72 6.13 5.79a21.432 21.432 0 0 1 2.35 3 14.904 14.904 0 0 1 1.6 12.1c-1.36 5.06-4.47 9.33-7.65 13.4-1.59 2.04-3.23 4.1-4.65 6.28-.52.78-1 1.57-1.44 2.38h-1.26c.42-.81.88-1.6 1.38-2.38 3.65-5.75 8.84-10.69 11.53-17.02 1.82-4.27 2.37-9.11.07-13.3a17.682 17.682 0 0 0-2.43-3.38c-1.83-2.07-4.02-3.84-6.01-5.71-.5-.47-.99-.95-1.46-1.45a24.964 24.964 0 0 1-5.64-8.9 39.23 39.23 0 0 1-1.94-13.13c0-2.84.15-5.7.43-8.54.03-.36.07-.73.11-1.1a100.767 100.767 0 0 1 19.67-49.23c.2-.28.41-.55.62-.82.43-.56 1.22.22.79.77Z"
        />
        <Path
          fill="#fff"
          d="M17.717 569.07a14.807 14.807 0 0 1-12.354-14.662.553.553 0 0 1 1.104-.024A13.711 13.711 0 0 0 17.987 568c.702.114.427 1.184-.27 1.07ZM24.516 598.617a28.54 28.54 0 0 0 11.533-17.308c.15-.695 1.222-.424 1.07.27a29.685 29.685 0 0 1-12.037 17.986c-.585.407-1.148-.544-.566-.948ZM26.312 538.069a8.381 8.381 0 0 0 7.894-.972c.58-.413 1.142.538.566.949a9.392 9.392 0 0 1-8.73 1.094.57.57 0 0 1-.4-.67.555.555 0 0 1 .67-.401Z"
        />
        <Path
          fill="#e6e6e6"
          d="M113.413 557.266c-.35.27-.71.54-1.06.82a110.362 110.362 0 0 0-13.29 12c-.32.33-.64.67-.95 1.01l-.01.01a116.347 116.347 0 0 0-22.66 36.14l-.03.09c-.01.03-.02.05-.03.08a114.443 114.443 0 0 0-5.03 16.42c-1.22 5.46-2.22 11.31-4.13 16.57-.29.81-.61 1.61-.95 2.38h-44.46c.15-.79.31-1.59.47-2.38a160.302 160.302 0 0 1 10.54-33.7c.16-.36.32-.72.5-1.08a108.305 108.305 0 0 1 8.61-15.35.01.01 0 0 1 .01-.01 94.956 94.956 0 0 1 5.8-7.69 79.119 79.119 0 0 1 18.72-16.24c.04-.03.09-.05.13-.08 14.04-8.71 30.68-12.86 46.59-9.27h.01c.41.09.81.18 1.22.28Z"
        />
        <Path
          fill="#fff"
          d="M113.363 557.796c-.34.09-.68.19-1.01.29a98.589 98.589 0 0 0-20.17 8.27c-.32.17-.64.35-.96.53a98.255 98.255 0 0 0-23.79 18.59.035.035 0 0 0-.01.02c-.08.08-.17.17-.24.25a90.03 90.03 0 0 0-4.6 5.35 42.769 42.769 0 0 0-6.82 11.43 23.674 23.674 0 0 0-1.31 10.81c.03.37.08.73.13 1.1.04.29.08.58.13.88.66 4.01 1.8 8.03 1.48 12.12a14.91 14.91 0 0 1-6.01 10.63 23.794 23.794 0 0 1-3.68 2.34 36.852 36.852 0 0 1-5.77 2.38h-3.93l1.58-.45a48.212 48.212 0 0 0 5.53-1.93 26.912 26.912 0 0 0 3-1.48c4.02-2.31 7.37-5.85 8.07-10.58.61-4.14-.57-8.28-1.27-12.33-.12-.7-.23-1.39-.29-2.08a24.439 24.439 0 0 1 .85-10.46 39.062 39.062 0 0 1 6.36-11.66 83.355 83.355 0 0 1 5.48-6.55q.36-.405.75-.81a100.901 100.901 0 0 1 24.21-18.73h.01a99.288 99.288 0 0 1 21.1-8.74h.01c.33-.1.67-.2 1-.29.69-.18.85.91.17 1.1Z"
        />
        <Path
          fill="#fff"
          d="M66.468 585.645A14.807 14.807 0 0 1 65.43 566.5c.433-.564 1.33.08.897.646a13.711 13.711 0 0 0 1 17.807c.491.514-.372 1.203-.86.692ZM54.107 613.33a28.54 28.54 0 0 0 19.63-6.876c.538-.464 1.23.397.692.86a29.685 29.685 0 0 1-20.44 7.114c-.712-.028-.59-1.126.118-1.098ZM91.996 566.067a8.381 8.381 0 0 0 6.887 3.977c.712.019.588 1.117-.119 1.098a9.392 9.392 0 0 1-7.629-4.382.57.57 0 0 1 .084-.777.555.555 0 0 1 .777.084Z"
        />
        <Path
          fill="#ffb8b8"
          d="M456.382 173.13a11.6 11.6 0 0 1-17.766.837l-37.8 16.44 3.681-21.101 35.332-12.377a11.662 11.662 0 0 1 16.553 16.2Z"
        />
        <Path
          fill="#e6e6e6"
          d="m431.157 179.718-87.797 39.109-.189-.068L243.104 183.1a32.96 32.96 0 0 1-14.781-42.755 32.924 32.924 0 0 1 46.987-14.637l74.468 44.86 72.212-9.36Z"
        />
        <Path
          fill="#ccc"
          d="M862.703 643.596a1.187 1.187 0 0 1-1.19 1.19H1.223a1.19 1.19 0 0 1 0-2.38h860.29a1.187 1.187 0 0 1 1.19 1.19Z"
        />
        <Path
          fill="#e6e6e6"
          d="m313.344 296.796-88.506-14.156a16.893 16.893 0 0 1-9.956-23.646l4.014-8.029-1.56-84.346a62.482 62.482 0 0 1 30.337-54.654l8.631-5.16 4.362-11.077 40.22.98.117 14.528 14.404 22.968v.095l-.904 125.014-3.97 12.902 6.002 15.005Z"
        />
        <Circle cx={284.459} cy={45.41} r={36.544} fill="#ffb8b8" />
        <Path
          fill="#2f2e41"
          d="M246.405 53.376c-1.09-4.591-.59-11.053.027-15.677 1.615-12.12 8.346-23.644 18.573-30.47a13.38 13.38 0 0 1 6.665-2.648c2.42-.101 5.042 1.194 5.784 3.499a11.993 11.993 0 0 1 6.766-6.71 21.136 21.136 0 0 1 9.63-1.297 35.197 35.197 0 0 1 29.364 20.99c.976 2.318 3.702-6.247 4.939-4.056a9.74 9.74 0 0 0 5.524 4.854c2.423.676 3.407 10.66 4.361 8.332a11.098 11.098 0 0 1-10.61 15.475c-2.467-.092-4.825-1-7.263-1.39-8.715-1.396-17.96 4.923-19.823 13.55a23.987 23.987 0 0 0-3.155-7.02 8.119 8.119 0 0 0-6.514-3.579c-2.48.093-4.659 1.714-6.267 3.603s-2.818 4.093-4.438 5.972c-4.756 5.513-11.188 18.37-17.965 17.432-5.348-.74-14.11-14.59-15.598-20.86Z"
        />
        <Path
          fill="#fff"
          d="M505.364 214.54a7.133 7.133 0 0 0-4.807-7.853l-98.413-32.777a7.132 7.132 0 0 0-2.933-.337l-24.667 2.333-14.154 1.342-26.119 2.469a7.155 7.155 0 0 0-6.383 5.99l-13.261 82.837a7.186 7.186 0 0 0 4.484 7.796l99.44 38.384a6.947 6.947 0 0 0 1.447.389 7.136 7.136 0 0 0 2.175.016l64.256-9.523a7.12 7.12 0 0 0 6.023-6Z"
        />
        <Path
          fill="#A8DFD3"
          d="m321.365 270.503 99.44 38.383a.897.897 0 0 0 .457.053l64.247-9.522a.883.883 0 0 0 .755-.752l12.92-85.066a.905.905 0 0 0-.6-.982l-.661-.224-97.758-32.546a.678.678 0 0 0-.137-.033.887.887 0 0 0-.23-.012l-60.164 5.693-4.775.448a.903.903 0 0 0-.794.748l-13.26 82.834a.897.897 0 0 0 .56.978Z"
        />
        <Path
          fill="#2f2e41"
          d="m339.634 185.495 60.164-5.693a.887.887 0 0 1 .23.012.678.678 0 0 1 .137.033l97.758 32.546-25.787 2.73-9.65 1.016-27.46 2.902a1.939 1.939 0 0 1-.241-.003c-.05-.015-.098-.03-.157-.045Z"
        />
        <Path
          fill="#3f3d56"
          d="m319.11 276.345 99.44 38.382a6.722 6.722 0 0 0 1.45.38 7.224 7.224 0 0 0 2.177.027l64.247-9.522a7.135 7.135 0 0 0 6.028-6.004l12.91-85.068a7.19 7.19 0 0 0-.418-3.716 6.244 6.244 0 0 0-.207-.462 6.99 6.99 0 0 0-2.264-2.698 7.138 7.138 0 0 0-1.916-.976l-.116-.041-98.292-32.738a8.955 8.955 0 0 0-1.227-.298 7.086 7.086 0 0 0-1.715-.033l-24.663 2.324-14.152 1.35-26.123 2.467a7.092 7.092 0 0 0-3.019.997 1.33 1.33 0 0 0-.202.122 1.192 1.192 0 0 0-.13.098 7.148 7.148 0 0 0-3.027 4.763l-13.27 82.844a7.194 7.194 0 0 0 4.488 7.802Zm10.541-90.357a5.3 5.3 0 0 1 1.27-2.671 4.651 4.651 0 0 1 .676-.659 5.317 5.317 0 0 1 2.324-1.084 4.059 4.059 0 0 1 .509-.072l43.984-4.155 20.965-1.995c.142-.017.273-.014.404-.022a5.007 5.007 0 0 1 .948.07 4.145 4.145 0 0 1 .844.202l98.409 32.779c.078.027.145.054.223.081a5.218 5.218 0 0 1 2.273 1.654 5.26 5.26 0 0 1 1.12 4.146l-12.92 85.076a5.35 5.35 0 0 1-4.509 4.502l-64.257 9.521a5.413 5.413 0 0 1-2.722-.31l-99.441-38.373a5.402 5.402 0 0 1-3.36-5.846Z"
        />
        <Path
          fill="#3f3d56"
          d="M330.704 181.387a.877.877 0 0 1 .268-.386 1.051 1.051 0 0 1 .129-.088c.041-.016.084-.042.126-.058a.873.873 0 0 1 .624-.011l2.07.73 101.115 35.67 23.665-2.5 13.243-1.398 28.03-2.967 2.506-.263.487-.054a.904.904 0 0 1 .953.654.74.74 0 0 1 .026.143.893.893 0 0 1-.55.922.988.988 0 0 1-.248.067l-3.41.357-27.602 2.918-9.65 1.016-27.46 2.902a1.939 1.939 0 0 1-.241-.003c-.05-.015-.098-.03-.157-.045l-103.031-36.335-.35-.124a.67.67 0 0 1-.22-.121.91.91 0 0 1-.323-1.026Z"
        />
        <Path
          fill="#3f3d56"
          d="M420.27 315.368a.894.894 0 0 1-.742-1.016l14.517-96.334a.894.894 0 0 1 1.017-.75h.008a.894.894 0 0 1 .743 1.016l-14.517 96.334a.894.894 0 0 1-1.017.75ZM457.068 309.256l-9.655 1.02 11.293-95.535s12.895-2.335 13.24-1.399c.212.559-14.786 95.302-14.878 95.914Z"
        />
        <Path
          fill="#3f3d56"
          d="m331.25 182.533-.26 43.567 77.126 29.388 27.697-37.204-104.563-35.751zM502.483 210.118a5.301 5.301 0 0 0-2.497-1.737l-98.406-32.778a5.106 5.106 0 0 0-.848-.206 5.009 5.009 0 0 0-.95-.071l.16-.998.984-.713 23.369-16.919 78.04 23.917.136 27.052Z"
        />
        <Path
          fill="#fff"
          d="M335.18 252.473a1.513 1.513 0 0 1 .327.068l30.193 9.917a1.5 1.5 0 0 1-.935 2.85l-30.194-9.916a1.5 1.5 0 0 1 .61-2.92Z"
        />
        <Circle cx={457.003} cy={423.236} r={12} fill="#f2f2f2" />
        <Circle cx={151.003} cy={467.236} r={12} fill="#f2f2f2" />
        <Circle cx={401.003} cy={70.236} r={12} fill="#f2f2f2" />
        <Path
          fill="#ffb8b8"
          d="M420.692 270.121a11.6 11.6 0 0 1-15.907-7.957l-41.012-4.143 13.53-16.606 36.871 6.48a11.662 11.662 0 0 1 6.518 22.226Z"
        />
        <Path
          fill="#e6e6e6"
          d="m395.467 263.534-95.709-8.819-.131-.15-69.85-80.037a32.96 32.96 0 0 1 8.012-44.523 32.924 32.924 0 0 1 48.144 10.209l43.022 75.544 67.566 27.147Z"
        />
        <Path
          fill="#2f2e41"
          d="M635.69 109.617c-2.377-17.434-5.358-36.152-17.654-48.737a41.35 41.35 0 0 0-59.744.618c-8.95 9.549-12.903 22.957-13.265 36.04s2.552 26.02 5.784 38.703a119.29 119.29 0 0 0 49.786-9.799c3.926-1.704 7.789-3.63 11.937-4.686s7.104 1.6 10.963 3.453l2.118-4.055c1.734 3.226 7.102 2.27 9.05-.833 1.947-3.102 1.52-7.075 1.025-10.704Z"
        />
        <Path
          fill="#9e616a"
          d="m567.884 206.925-69.876 1.495a11.055 11.055 0 1 0-4.94 15.574c9.267.526 81.772 10.817 86.097 4.185 4.39-6.73 27.825-30.486 27.825-30.486l-18.013-25.644Z"
        />
        <Circle cx={584.911} cy={94.035} r={32.83} fill="#9e616a" />
        <Path
          fill="#3f3d56"
          d="M637.494 157.204c-3.87-7.7-5.75-17.212-13.997-19.709-5.57-1.685-28.097.841-33.173 3.686-8.444 4.734-.792 13.603-5.774 21.903-5.415 9.022-20.132 27.13-25.547 36.152-3.723 6.203 8.817 24.41 6.804 31.358-2.012 6.948-2.11 14.747 1.32 21.117 3.069 5.702-1.372 10.745 1.715 16.437 3.21 5.92 7.149 28.053 4.161 34.088l-2 6c19.847 1.166 36.535-22.544 56.258-25.042 4.9-.62 9.986-1.43 14.023-4.274 5.946-4.189 8.297-11.79 9.766-18.913a159.326 159.326 0 0 0-13.556-102.803Z"
        />
        <Path
          fill="#9e616a"
          d="M667.25 238.505c-2.765-7.545-7.77-40.536-7.77-40.536l-31.324-.919 15.315 37.772-41.79 58.503.218.358a11.052 11.052 0 1 0 9.27 11.745.763.763 0 0 0 .957-.165c4.65-5.835 57.888-59.212 55.124-66.758Z"
        />
        <Path
          fill="#3f3d56"
          d="M670.434 217.67c-2.875-12.134-5.771-24.335-10.619-35.825s-11.786-22.343-21.546-30.106c-3.12-2.482-6.61-4.672-10.521-5.444-3.912-.771-8.32.092-11.067 2.982-4.396 4.623-3.073 12.045-1.46 18.218l14.262 54.6 41.188-4.365Z"
        />
        <Path
          fill="#2f2e41"
          d="M625.139 98.589c-1.21-7.942-2.472-15.95-5.313-23.429-2.84-7.478-7.418-14.482-13.986-18.719-10.399-6.707-23.862-5.413-35.52-1.555-9.017 2.984-17.818 7.519-24.177 14.81-6.358 7.29-9.93 17.693-7.564 27.226l37.309-8.815-1.361.962a30.038 30.038 0 0 1 16.03 20.893 31.122 31.122 0 0 1-6.565 25.848l25.445-9.027c5.235-1.857 10.838-3.997 13.943-8.76 3.599-5.523 2.763-12.843 1.759-19.434Z"
        />
      </Svg>
    }
  </View>
)
