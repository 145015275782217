import { SupportedLocaleEnum } from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/es-us'
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat'
import dayjsDuration from 'dayjs/plugin/duration'
import dayjsIsBetween from 'dayjs/plugin/isBetween'
import dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import dayjsIsToday from 'dayjs/plugin/isToday'
import dayjsIsYesterday from 'dayjs/plugin/isYesterday'
import dayjsLocaleData from 'dayjs/plugin/localeData'
import dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat'
import dayjsRelativeTime from 'dayjs/plugin/relativeTime'
import dayjsTimezone from 'dayjs/plugin/timezone'
import dayjsUpdateLocale from 'dayjs/plugin/updateLocale'
import dayjsUtc from 'dayjs/plugin/utc'
import i18next from 'i18next'

import enUS from '../i18n/en-US.json'
import es419 from '../i18n/es-419.json'
import esUS from '../i18n/es-US.json'

dayjs.extend(dayjsUtc)
dayjs.extend(dayjsLocalizedFormat)
dayjs.extend(dayjsDuration)
dayjs.extend(dayjsLocaleData)
dayjs.extend(dayjsCustomParseFormat)
dayjs.extend(dayjsRelativeTime)
dayjs.extend(dayjsIsToday)
dayjs.extend(dayjsIsYesterday)
dayjs.extend(dayjsIsSameOrAfter)
dayjs.extend(dayjsIsSameOrBefore)
dayjs.extend(dayjsIsBetween)
dayjs.extend(dayjsUpdateLocale)
dayjs.extend(dayjsTimezone)

const weekDaysShortSpanish = ['Lun', 'Mar', 'Miérc', 'Juev', 'Vier', 'Sáb', 'Dom']

dayjs.updateLocale('es', { weekdaysShort: weekDaysShortSpanish })
dayjs.updateLocale('es-US', { weekdaysShort: weekDaysShortSpanish })

i18next.init({
  fallbackLng: SupportedLocaleEnum.EnUs,
  resources: {
    en: { translation: enUS },
    es: { translation: es419 },
    'es-US': { translation: esUS },
  },
  interpolation: {
    escapeValue: false,
    prefix: '{',
    suffix: '}',
  },
  returnNull: false,
})
