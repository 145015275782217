import { Colors } from '@vetahealth/fishing-gear/colors'
import noop from 'lodash/noop'
import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components/native'

import { Pressable } from '../Pressable'
import { Text } from '../Text'

const Wrapper = styled.View``
const ButtonWrapper = styled.View`
  flex-direction: row;
  gap: 4px;
`
const ScaleButton = styled(Pressable)<{ isSelected: boolean }>`
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.primary : theme.colors.scale)};
  flex: 1;
`
const DescriptionWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0px;
  gap: 10px;
`
const DescriptionText = styled(Text).attrs({
  size: 'tiny',
  weight: 'medium',
})``

type ScaleProps = {
  options: Array<{ value: string; label: string; hint?: string }>
  initialValue?: string
  onChange?: (value: string) => void
}

function getHints(options: ScaleProps['options'] = []) {
  const firstHint = options[0].hint
  const lastHint = options[options.length - 1].hint

  return { firstHint, lastHint }
}

export function Scale({ options, initialValue, onChange = noop }: ScaleProps): JSX.Element {
  const theme = useTheme()
  const hasInitialValue = options.some(({ value }) => value === initialValue)
  const [value, setValue] = useState<string | undefined>(hasInitialValue ? initialValue : undefined)

  function handleChange(newValue: string) {
    setValue(newValue)
    onChange(newValue)
  }

  const { firstHint = '', lastHint = '' } = getHints(options)

  return (
    <Wrapper>
      <ButtonWrapper>
        {options.map((option) => {
          const isSelected = option.value === value

          return (
            <ScaleButton
              isSelected={isSelected}
              key={option.label}
              onPress={() => handleChange(option.value)}
              render={() => (
                <Text size="settings" weight="semiBold" color={isSelected ? Colors.white : theme.colors.text}>
                  {option.label}
                </Text>
              )}
            />
          )
        })}
      </ButtonWrapper>
      {Boolean(firstHint || lastHint) && (
        <DescriptionWrapper>
          <DescriptionText>{firstHint}</DescriptionText>
          <DescriptionText>{lastHint}</DescriptionText>
        </DescriptionWrapper>
      )}
    </Wrapper>
  )
}
