import { TrackingTypeEnum, UnitPreferences } from '@vetahealth/tuna-can-api'
import { produce } from 'immer'
import React from 'react'

import { SelectButton } from '@components/Elements/Button'
import { getStaticTrackingConfig } from '@components/Tracking/helpers'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { useAuthStore } from '@stores/auth'

import { OptionsWrapper, Wrapper } from './styles'

export function Units(): JSX.Element | null {
  const [user, updateAccount] = useAuthStore((state) => [state.user, state.updateAccount])

  function handleChangeUnit<UnitType extends keyof UnitPreferences>(
    unitType: UnitType,
    unit: UnitPreferences[UnitType],
  ) {
    if (!user) return

    trackEvent(TrackingEvent.unitChanged, { type: unitType, unit })
    updateAccount({
      preferences: produce(user.preferences, (draft) => {
        draft.units[unitType] = unit
      }),
    })
  }

  if (!user) return null

  return (
    <>
      <Wrapper {...getStaticTrackingConfig.bloodGlucose()}>
        <OptionsWrapper>
          {['mmol/l', 'mg/dl'].map((unit, index) => (
            <SelectButton
              hasBottomBorder={index === 0}
              type="radio"
              label={unit}
              key={unit}
              isChecked={user.preferences.units.bloodGlucose === unit}
              onPress={() => handleChangeUnit(TrackingTypeEnum.BloodGlucose, unit)}
            />
          ))}
        </OptionsWrapper>
      </Wrapper>
      <Wrapper {...getStaticTrackingConfig.bloodPressure()}>
        <OptionsWrapper>
          {['mmHg', 'kPa'].map((unit, index) => (
            <SelectButton
              hasBottomBorder={index === 0}
              type="radio"
              label={unit}
              key={unit}
              isChecked={user.preferences.units.bloodPressure === unit}
              onPress={() => handleChangeUnit(TrackingTypeEnum.BloodPressure, unit)}
            />
          ))}
        </OptionsWrapper>
      </Wrapper>
      <Wrapper {...getStaticTrackingConfig.height()}>
        <OptionsWrapper>
          {['cm', 'm', 'ft_in'].map((unit, index) => (
            <SelectButton
              hasBottomBorder={index < 2}
              type="radio"
              label={unit.replace('_', ' ')}
              key={unit}
              isChecked={user.preferences.units.height === unit}
              onPress={() => handleChangeUnit(TrackingTypeEnum.Height, unit)}
            />
          ))}
        </OptionsWrapper>
      </Wrapper>
      <Wrapper {...getStaticTrackingConfig.bodyTemperature()}>
        <OptionsWrapper>
          {['°C', '°F'].map((unit, index) => (
            <SelectButton
              hasBottomBorder={index === 0}
              type="radio"
              label={unit}
              key={unit}
              isChecked={user.preferences.units.bodyTemperature === unit}
              onPress={() => handleChangeUnit(TrackingTypeEnum.BodyTemperature, unit)}
            />
          ))}
        </OptionsWrapper>
      </Wrapper>
      <Wrapper {...getStaticTrackingConfig.weight()}>
        <OptionsWrapper>
          {['kg', 'lbs', 'st_lb', 'lb_oz'].map((unit, index) => (
            <SelectButton
              hasBottomBorder={index < 3}
              type="radio"
              label={unit.replace('_', ' ')}
              key={unit}
              isChecked={user.preferences.units.weight === unit}
              onPress={() => handleChangeUnit(TrackingTypeEnum.Weight, unit)}
            />
          ))}
        </OptionsWrapper>
      </Wrapper>
    </>
  )
}
