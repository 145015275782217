import noop from 'lodash/noop'
import React, { forwardRef } from 'react'
import { TextInput as NativeTextInput } from 'react-native'

import { Text } from '../Text'
import { BaseInput, InputWrapper } from './BaseInput'
import { InputError } from './InputError'
import { TextInputProps } from './types'

export const TextInput = forwardRef<NativeTextInput, TextInputProps>(
  (
    {
      onChange = noop,
      onSubmitEditing = noop,
      value = '',
      placeholder,
      inputMode,
      secureTextEntry,
      maxLength,
      error = '',
      autoFocus = false,
      textContentType,
      enterKeyHint,
      numberOfLines,
      label,
      editable = true,
    },
    ref,
  ) => {
    return (
      <InputWrapper>
        {label ? (
          <Text weight="semiBold" size="small" margins={{ bottom: 5, left: 5 }}>
            {label}
          </Text>
        ) : null}
        <BaseInput
          ref={ref}
          aria-label="text-input"
          onChangeText={onChange}
          onSubmitEditing={({ nativeEvent }) => onSubmitEditing(nativeEvent.text)}
          value={value}
          placeholder={placeholder}
          inputMode={inputMode}
          maxLength={maxLength}
          secureTextEntry={secureTextEntry}
          autoFocus={autoFocus}
          enterKeyHint={enterKeyHint}
          textContentType={textContentType}
          numberOfLines={numberOfLines}
          textAlignVertical="top"
          editable={editable}
        />
        <InputError error={error} />
      </InputWrapper>
    )
  },
)
