import { isWeb } from '@lib/constants'
import { useDeepLinkingHandler } from '@lib/linking'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { useNavigation } from '@react-navigation/native'
import { useAppStore } from '@stores/app'
import { useAuthStore } from '@stores/auth'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { TabBar, getTabs } from './TabBar'
import { RouteName } from './routeNames'

const Tab = createBottomTabNavigator()

export const Tabs = () => {
  const navigation = useNavigation()
  const [user, welcome, isAuthorized] = useAuthStore((state) => [state.user, state.welcome, state.authorized])
  const [releases, getReleases] = useAppStore((state) => [state.releases, state.getReleases])

  useEffect(() => {
    getReleases()
  }, [])

  useEffect(() => {
    if (isAuthorized && !welcome) {
      if (user?.flags?.welcome) {
        setTimeout(() => navigation.navigate(RouteName.WelcomeModal), 1000)
      } else if (releases.length && !user?.flags?.viewedReleaseInfo?.includes(releases[0].id)) {
        setTimeout(() => navigation.navigate(RouteName.ReleaseModal), 1000)
      }
    }
  }, [isAuthorized, user, welcome, releases])

  useDeepLinkingHandler()

  return (
    <Tab.Navigator
      screenOptions={{ headerShown: false, tabBarHideOnKeyboard: true }}
      tabBar={(props) => <TabBar {...props} />}
      backBehavior={isWeb ? 'history' : 'firstRoute'}
    >
      {Object.entries(getTabs()).map(([key, { component = View }]) => (
        <Tab.Screen key={key} name={key} component={component} />
      ))}
    </Tab.Navigator>
  )
}
