import { keyBy, mapValues } from 'lodash'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { api } from '@lib/api'
import { takeLeading } from '@lib/takeLeading'

import { ChatState } from './types'

export const MESSAGE_LIMIT = 100

const initialState: Pick<ChatState, 'chatMessages' | 'hasChatMessages'> = {
  hasChatMessages: false,
  chatMessages: {},
}

export const useChatStore = createWithEqualityFn<ChatState>(
  (set, get) => ({
    ...initialState,
    getChatMessages: takeLeading(async (offset = 0, limit = MESSAGE_LIMIT) => {
      const { chatMessages } = get()

      const newChatMessages = await api.getChatMessages({ limit, offset })

      if (newChatMessages?.length) {
        set({
          hasChatMessages: newChatMessages
            .filter(({ senderId, userId, readAt }) => senderId !== userId && !readAt)
            .some((newChatMessage) => !chatMessages[newChatMessage.id]),
          chatMessages: {
            ...chatMessages,
            ...mapValues(keyBy(newChatMessages, 'id')),
          },
        })
      }
    }),
    sendChatMessage: async (message) => {
      const { chatMessages } = get()

      const newChatMessage = await api.sendChatMessage(message.trim())

      if (newChatMessage) {
        set({
          chatMessages: {
            ...chatMessages,
            ...mapValues(keyBy([newChatMessage], 'id')),
          },
        })
      }
    },
    markChatMessagesAsRead: takeLeading(async () => {
      await api.markChatMessagesAsRead()
      set({ hasChatMessages: false })
    }),
  }),
  shallow,
)
