import { Colors } from '@vetahealth/fishing-gear/colors'
import React from 'react'
import styled from 'styled-components/native'

import { Icon } from '../Icon'
import { Pressable } from '../Pressable'
import { Text } from '../Text'
import { NavButtonProps } from './types'

const StyledPressable = styled(Pressable)`
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};
  margin-bottom: 10px;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  padding: 15px;
`
const Wrapper = styled.View`
  flex-direction: row;
  align-items: center;
`
const IconWrapper = styled.View`
  padding: 8px;
`
const ArrowWrapper = styled.View`
  margin-left: 10px;
`

const LabelWrapper = styled.View`
  flex: 1;
  justify-content: center;
`

export function NavButton({ icon, label, onPress, color }: NavButtonProps): JSX.Element {
  return (
    <StyledPressable
      onPress={onPress}
      render={() => (
        <Wrapper>
          <IconWrapper>
            <Icon color={color} size={22} icon={icon} />
          </IconWrapper>
          <LabelWrapper>
            <Text numberOfLines={1} margins={{ left: 10, right: 10 }} weight="semiBold" size="medium">
              {label}
            </Text>
          </LabelWrapper>
          <ArrowWrapper>
            <Icon color={Colors.gray600} icon={['fas', 'arrow-right']} />
          </ArrowWrapper>
        </Wrapper>
      )}
    />
  )
}
