import { ScreenLayout } from '@components/Layouts'
import { Units as UnitSettings } from '@components/Settings'
import { useI18n } from '@lib/i18n'
import React from 'react'

export function Units(): JSX.Element {
  const t = useI18n()

  return (
    <ScreenLayout hasBack title={t('settings.units')}>
      <UnitSettings />
    </ScreenLayout>
  )
}
