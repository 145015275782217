import * as React from 'react'
import { View } from 'react-native'
import Svg, { Circle, Ellipse, Path } from 'react-native-svg'
import type { SvgProps } from 'react-native-svg'

export const SvgStartChat = (props: SvgProps) => (
  <View>
    {
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        data-name="Layer 1"
        viewBox="0 0 652.675 737.697"
        {...props}
      >
        <Path
          fill="#f2f2f2"
          d="M442.392 580.86c20.72-19.504 31.402-48.723 20.271-73.44-16.204 24.872-42.88 44.46-73.808 54.196-12.046 3.792-25.898 6.92-32.45 16.486-4.077 5.952-4.374 13.406-2.537 20.146 1.837 6.74 5.619 12.94 9.363 19.054l.022 1.729c29.319-7.46 58.42-18.666 79.14-38.17Z"
        />
        <Path
          fill="#fff"
          d="M462.04 507.552c-3.493 21.524-14.924 42.218-32.402 57.818a51.44 51.44 0 0 1-12.769 8.708 33.523 33.523 0 0 1-15.297 2.826c-4.945-.157-9.983-.816-14.886.074a18.158 18.158 0 0 0-11.918 7.74c-3.25 4.781-4.33 10.292-5.17 15.713-.933 6.019-1.792 12.284-5.755 17.461-.48.628.669 1.238 1.148.612 6.895-9.008 4.588-20.52 9.24-30.235 2.171-4.533 5.927-8.525 11.543-9.894 4.91-1.197 10.106-.522 15.112-.325a35.978 35.978 0 0 0 15.075-2.28 48.262 48.262 0 0 0 13.108-8.248 103.58 103.58 0 0 0 21.585-25.55 96.834 96.834 0 0 0 12.732-34.395c.121-.744-1.226-.764-1.346-.025Z"
        />
        <Path
          fill="#fff"
          d="M435.627 560.56a20.135 20.135 0 0 0 23.31-1.489c.616-.53-.303-1.385-.92-.855a18.64 18.64 0 0 1-21.686 1.347c-.697-.447-1.397.553-.704.997ZM404.068 577.27a27.075 27.075 0 0 1 5.272-21.544c.478-.63-.67-1.24-1.148-.612a28.152 28.152 0 0 0-5.434 22.43c.144.743 1.453.466 1.31-.273ZM455.344 531.108a9.154 9.154 0 0 1-5.875-6.7c-.133-.744-1.441-.467-1.31.273a10.249 10.249 0 0 0 6.481 7.425.76.76 0 0 0 .926-.193.545.545 0 0 0-.222-.805Z"
        />
        <Path
          fill="#f2f2f2"
          d="M364.414 479.318c.068.467.136.935.195 1.409a101.684 101.684 0 0 1 .849 18.948c-.015.489-.039.985-.07 1.473a110.234 110.234 0 0 1-12.318 44.091 115.865 115.865 0 0 1-10.038 15.956c-5.258 7.029-11.603 14.287-13.816 22.182a18.145 18.145 0 0 0-.547 2.496l32.154 33.046c.117.043.225.093.342.137l1.214 1.37c.218-.25.437-.514.654-.765.127-.145.247-.297.374-.442.082-.099.164-.198.245-.283.027-.033.054-.066.073-.093.081-.085.146-.172.218-.25q1.846-2.21 3.663-4.448c.008-.007.008-.007.01-.02 9.195-11.394 17.376-23.555 23.172-36.408.174-.387.357-.78.517-1.182a103.13 103.13 0 0 0 6.055-17.878 88.69 88.69 0 0 0 1.764-10.1 73.201 73.201 0 0 0-1.777-26.203c-4.33-17.142-14.922-32.89-31.647-42.326-.428-.241-.85-.475-1.286-.71Z"
        />
        <Path
          fill="#fff"
          d="M364.004 479.747c12.127 19.013 17.342 41.515 14.198 63.113a40.428 40.428 0 0 1-4.161 13.63 27.288 27.288 0 0 1-10.255 10.259c-4.058 2.46-8.536 4.57-11.835 7.845a14.415 14.415 0 0 0-4.15 12.414c.717 5.517 3.674 10.482 6.76 15.25 3.427 5.293 7.083 10.745 7.507 16.951.05.752 1.391.64 1.34-.112-.738-10.798-10.558-18.784-13.576-28.974-1.408-4.754-1.176-9.907 2.36-13.937 3.09-3.524 7.707-5.702 11.84-8.163a29.571 29.571 0 0 0 10.457-9.706 37.744 37.744 0 0 0 4.75-13.442 88.277 88.277 0 0 0-.472-31.69 97.483 97.483 0 0 0-13.671-34.122c-.42-.657-1.508.032-1.092.684Z"
        />
        <Path
          fill="#fff"
          d="M379.65 535.886c8.807-.08 16.321-5.82 17.58-13.38.124-.746-1.202-.948-1.326-.202-1.18 7.083-8.15 12.343-16.383 12.418-.866.008-.732 1.172.13 1.164ZM366.033 565.736a28.747 28.747 0 0 1-10.722-19.96c-.054-.752-1.394-.64-1.34.112a29.922 29.922 0 0 0 11.205 20.75c.63.52 1.484-.386.857-.902ZM374.983 502.058a11.295 11.295 0 0 1-9.334-2.277c-.623-.525-1.475.381-.857.903a12.656 12.656 0 0 0 10.32 2.538c.357-.07.64-.295.605-.638a.658.658 0 0 0-.734-.526Z"
        />
        <Path
          fill="#f2f2f2"
          d="M289.905 618.048c-40.02 27.363-79.811 34.307-112.042 19.555-41.36-18.933-66.416-71.058-68.74-143.01l2.45.23c2.292 70.95 26.754 122.238 67.114 140.712 31.367 14.357 70.298 7.457 109.623-19.43ZM537.225 154.729c-3.941 43.423-15.169 89.21-32.778 133.39-21.775 54.632-51.526 102.753-86.036 139.159l-1.915-1.67c34.343-36.23 63.959-84.139 85.643-138.546 19.597-49.167 31.254-100.332 33.724-147.942l2.448.41q-.39 7.542-1.086 15.199ZM430.908 93.015l-2.38.26c-10.607-41.684-31.167-70.562-59.46-83.513-35.968-16.464-80.444-5.408-125.235 31.13-45.018 36.724-84.871 94.733-112.217 163.343l-2.308-1.057c27.465-68.91 67.523-127.198 112.794-164.127C287.599 1.936 332.982-9.2 369.892 7.694c29.104 13.322 50.203 42.826 61.016 85.321ZM562.921 455.923c-48.472 77.116-107.28 132.216-165.59 155.148l-1.18-2.162c57.908-22.774 116.37-77.592 164.622-154.355ZM637.707 283.15q-1.198 13.201-3.071 26.725l-2.431-.589c9.33-67.283 6.02-128.974-9.573-178.416l2.342-.392c13.601 43.13 17.924 95.478 12.733 152.672ZM131.434 216.304a691.975 691.975 0 0 0-17.59 40.358c-31.36 78.682-47.252 159.037-45.953 232.383l-2.451-.261c-1.3-73.619 14.638-154.251 46.096-233.179a693.863 693.863 0 0 1 17.64-40.472Z"
        />
        <Path
          fill="#f2f2f2"
          d="M288.729 672.111c-40.02 27.364-79.812 34.308-112.042 19.555-41.361-18.932-66.416-71.057-68.74-143.009l2.45.23c2.291 70.95 26.753 122.237 67.114 140.711 31.366 14.358 70.298 7.458 109.623-19.43ZM536.049 208.792c-3.942 43.424-15.17 89.211-32.778 133.39-21.775 54.633-51.526 102.754-86.037 139.16l-1.915-1.67c34.344-36.231 63.96-84.14 85.644-138.546 19.597-49.167 31.253-100.332 33.724-147.942l2.447.41q-.39 7.542-1.085 15.198ZM531.732 150.079l-2.38.26c-10.607-41.684-31.168-70.562-59.461-83.514-35.968-16.463-80.443-5.407-125.234 31.13-45.019 36.725-84.872 94.733-112.217 163.343l-2.309-1.056c27.466-68.91 67.524-127.199 112.794-164.127C388.423 59 433.806 47.863 470.715 64.758c29.105 13.322 50.204 42.826 61.017 85.32ZM559.745 528.987c-48.472 77.116-107.28 132.216-165.59 155.148l-1.18-2.162c57.907-22.774 116.37-77.592 164.621-154.356ZM636.531 337.214q-1.198 13.2-3.072 26.725l-2.43-.59c9.329-67.282 6.02-128.973-9.574-178.415l2.343-.392c13.6 43.129 17.924 95.478 12.733 152.672ZM130.257 270.367a691.972 691.972 0 0 0-17.589 40.358c-31.36 78.683-47.252 159.038-45.954 232.384l-2.45-.262c-1.3-73.618 14.637-154.25 46.096-233.178A693.865 693.865 0 0 1 128 269.197Z"
        />
        <Ellipse
          cx={864.324}
          cy={307.932}
          fill="#fd6584"
          rx={8.857}
          ry={10.195}
          transform="rotate(-56.364 651.762 522.738)"
        />
        <Path
          fill="#2f2e41"
          d="M287.726 284.343c5.674-8.694 14.937-15.526 25.253-16.103 10.316-.578 22.714 8.208 27.806 17.247 9.313 16.536 5.272 32.435-7.556 43.936-1.742-.591-7.824-2.376-9.595-2.947l-1.123-2.028-1.386 1.233c-15.852 1.582-31.166-.18-43.098-11.992 1.484-10.28 4.026-20.652 9.7-29.346Z"
        />
        <Path
          fill="#ffb6b6"
          d="m330.231 400.404-1.874 17.381 1.103 22.793-50.485-5.998-4.744-34.176 6.044.059 49.956-.059z"
        />
        <Circle cx={309.503} cy={304.328} r={20.964} fill="#ffb7b7" />
        <Path
          fill="#a8dfd3"
          d="m286.274 343.279 11.996-7.198 23.992-2.4 21.206 10.8-.212 32.987-13.453 34.214-56.383-7.197-2.142-12.069s-15.595-20.946 1.2-32.942Z"
        />
        <Path
          fill="#2f2e41"
          d="M301.665 272.49a26.33 26.33 0 0 1 32.316 32.96c-12.768 1.019-25.388-.173-35.337-9.207l-.885-7.748-3.63 6.484q-5.492-1.545-11-3.109a26.885 26.885 0 0 1 18.536-19.38Z"
        />
        <Path
          fill="#ffb6b6"
          d="M214.273 245.325a9.157 9.157 0 0 0 5.919 12.734l39.058 73.696 15.765-12.168-43.35-68.615a9.207 9.207 0 0 0-17.392-5.647Z"
        />
        <Path
          fill="#a8dfd3"
          d="M278.342 356.657s-3.62 3.21-8.766-5.653c-4.12-7.096-24.671-38.87-27.58-49.885a7.194 7.194 0 0 1-7.119-5.208l22.074-8.568c4.799 2.4 3.467 5.725 2.25 7.445l8.221 12.332 27.346 30.688L281 354.383Z"
        />
        <Path fill="#ccc" d="M651.485 737.697H1.19a1.19 1.19 0 0 1 0-2.381h650.294a1.19 1.19 0 0 1 0 2.381Z" />
        <Path
          fill="#e6e6e6"
          d="M495.8 73.18c-11.737-11.568-27.41-17.953-43.128-22.038-17.792-4.624-36.19-7.348-54.367-9.961q-28.414-4.085-57.054-6.302-28.6-2.205-57.305-2.525c-17.052-.19-34.218-.196-51.187 1.695-15.982 1.782-31.926 5.358-46.335 12.699a76.616 76.616 0 0 0-31.103 28.468c-6.896 11.367-10.996 24.993-9.57 38.354a47.036 47.036 0 0 0 6.012 18.678 64.802 64.802 0 0 0 14.55 16.633c11.906 10.053 26.14 17.402 36.383 29.35a58.095 58.095 0 0 1 7.047 10.172c1.487 2.754 3.16 5.125 5.926 6.732a14.396 14.396 0 0 0 15.937-1.111c3.19-2.504 5.481-6.364 7.95-9.541l8.61-11.08a17.414 17.414 0 0 1 2.151-2.769c.58-.48-.377-.057-.187-.176.105-.066.288-.03.402-.076a9.347 9.347 0 0 1 1.756-.327q9.429-1.733 18.898-3.243c23.31-3.76 46.771-6.863 70.33-8.535a327.163 327.163 0 0 1 36.506-.871c15.744.644 31.27 3.77 46.956 5.119 16.889 1.452 34.628 1.251 50.542-5.26 12.737-5.213 24.563-13.982 31.46-26.094a43.887 43.887 0 0 0 5.672-18.701c.842-14.927-6.417-29.003-16.852-39.29Z"
        />
        <Path
          fill="#fff"
          d="M274.56 44.589q-4.506 0-9.005.047c-28.804.306-58.572 2.635-81.162 17.482-20.051 13.178-34.104 42.533-22.211 63.585 5.46 9.667 15.355 16.704 25.83 24.152 4.106 2.92 8.353 5.94 12.422 9.192 8.89 7.104 17.26 16.637 22.076 27.357l21.151-27.22 3.996-.765c3.845-.736 94.712-17.966 143.106-12.372 4.45.514 8.949 1.106 13.3 1.679 19.66 2.586 38.228 5.028 56.055 1.423 18.61-3.766 39.046-18.14 40.262-37.36.833-13.159-7.728-27.594-21.808-36.776-14.238-9.284-31.995-13.117-48.963-16.247a856.345 856.345 0 0 0-155.05-14.177Z"
        />
        <Path
          fill="#a8dfd3"
          d="M438.706 86.471H234.444a4.444 4.444 0 0 1 0-8.888h204.262a4.444 4.444 0 0 1 0 8.888ZM438.706 113.75H234.444a4.444 4.444 0 1 1 0-8.888h204.262a4.444 4.444 0 0 1 0 8.889ZM258.248 141.03h-23.804a4.444 4.444 0 0 1 0-8.889h23.804a4.444 4.444 0 0 1 0 8.889Z"
        />
        <Circle cx={221.342} cy={228.743} r={18.89} fill="#e6e6e6" />
        <Ellipse
          cx={431.556}
          cy={536.534}
          fill="#f2f2f2"
          rx={8.857}
          ry={10.195}
          transform="rotate(-56.364 218.994 751.34)"
        />
        <Ellipse
          cx={836.855}
          cy={535.248}
          fill="#e6e6e6"
          rx={8.857}
          ry={10.195}
          transform="rotate(-56.364 624.294 750.055)"
        />
        <Ellipse
          cx={683.855}
          cy={388.248}
          fill="#e6e6e6"
          rx={8.857}
          ry={10.195}
          transform="rotate(-56.364 471.294 603.055)"
        />
        <Path fill="#ffb6b6" d="M356.417 715.978h-11.038l-5.252-42.574h16.29v42.574z" />
        <Path
          fill="#2f2e41"
          d="M362.243 735.102h-7.916l-1.413-7.473-3.618 7.473h-20.994a4.72 4.72 0 0 1-2.682-8.603l16.765-11.578v-7.556l17.634 1.053Z"
        />
        <Path fill="#ffb6b6" d="M294.417 715.978h-11.038l-5.252-42.574h16.29v42.574z" />
        <Path
          fill="#2f2e41"
          d="M300.243 735.102h-7.916l-1.413-7.473-3.618 7.473h-20.994a4.72 4.72 0 0 1-2.682-8.603l16.765-11.578v-7.556l17.634 1.053ZM361.18 679.264h-.12l-2.123-69.223a18.101 18.101 0 0 0-.238-17.105l-.304-.55.788-3.653a23.267 23.267 0 0 0-1.33-14.003l-1.98-64.573c7.075-45.85-26.642-75.753-26.642-75.753h-50.508L267.88 471.66c-7.169 69.84-5.868 137.898 5.507 203.834l-.31.157c-1.116.385-1.595 1.932-1.07 3.455s1.857 2.445 2.974 2.06l26.674-.928c1.117-.386 1.596-1.933 1.07-3.456-.526-1.522-1.857-2.444-2.974-2.06l-1.813.918 3.868-66.798-.43-12.2.885-13.843 4.088-62.26 15.476 76.563 15.809 82.933a2.908 2.908 0 0 0 1.96 5.063h21.585a2.917 2.917 0 1 0 0-5.834Z"
        />
        <Path
          fill="#ffb6b6"
          d="M337.624 485.71a9.157 9.157 0 0 0 2.031-13.895l7.736-83.047-19.856 1.534-1.36 81.15a9.207 9.207 0 0 0 11.45 14.257Z"
        />
        <Path
          fill="#a8dfd3"
          d="M342.733 344.811s4.787-.7 4.232 9.535c-.444 8.193 1.666 58.696-1.939 69.503a7.194 7.194 0 0 1 3.098 8.258l-23.158-4.936c-2.698-4.637-2.14-9.398-.178-10.17l-.116-14.82-5.118-48.85 19.71-8.075Z"
        />
      </Svg>
    }
  </View>
)
