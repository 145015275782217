import { ScreenLayout } from '@components/Layouts'
import { Appearance as AppearanceComponent } from '@components/Settings'
import { useI18n } from '@lib/i18n'
import React from 'react'

export function Appearance(): JSX.Element {
  const t = useI18n()

  return (
    <ScreenLayout hasBack title={t('settings.appearance')}>
      <AppearanceComponent />
    </ScreenLayout>
  )
}
