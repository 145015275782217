import { SvgProsper } from '@assets/Prosper'
import { SvgProsperName } from '@assets/ProsperName'
import { SvgVetahealth } from '@assets/Vetahealth'
import { NavButton } from '@components/Elements/Button'
import { Text } from '@components/Elements/Text'
import { ScreenLayout } from '@components/Layouts'
import { appVersion } from '@lib/constants'
import { useI18n } from '@lib/i18n'
import { openExternalLink } from '@lib/linking'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { useNavigation } from '@react-navigation/native'
import { Colors } from '@vetahealth/fishing-gear/colors'
import dayjs from 'dayjs'
import React from 'react'
import styled, { useTheme } from 'styled-components/native'
import { RouteName } from '../../../routeNames'

const ContentWrapper = styled.View`
  padding: 20px 0;
`
const ScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
})``
const ImageWrapper = styled.View`
  margin: 10px 0;
`
const InfoWrapper = styled.View`
  margin: 0 20px;
`
const Info = styled(Text).attrs({
  weight: 'semiBold',
  size: 'tiny',
  isCentered: true,
})``
const Wrapper = styled.View`
  margin: 20px 0 10px;
  padding: 0 20px;
`

export function AboutList() {
  const t = useI18n()
  const theme = useTheme()
  const navigation = useNavigation()

  function handleTermsOfServicePress(): void {
    trackEvent(TrackingEvent.aboutTermsOfServicePressed)
    openExternalLink(t('links.termsOfService'))
  }

  function handlePrivacyPolicyPress(): void {
    trackEvent(TrackingEvent.aboutPrivacyPolicyPressed)
    openExternalLink(t('links.privacyPolicy'))
  }

  return (
    <ScreenLayout title={t('screen.about')} hasBack isStatic>
      <ScrollView>
        <ContentWrapper>
          <ImageWrapper>
            <SvgProsper width="100%" height={90} strokeWidth={30} />
          </ImageWrapper>
          <ImageWrapper>
            <SvgProsperName fill={theme.colors.primary} width="100%" />
          </ImageWrapper>
          <Wrapper>
            <NavButton
              color={Colors.ink600}
              icon={['fad', 'memo-circle-check']}
              label={t('about.termsOfService')}
              onPress={handleTermsOfServicePress}
            />
            <NavButton
              color={Colors.azure500}
              icon={['fad', 'shield-check']}
              label={t('about.privacyPolicy')}
              onPress={handlePrivacyPolicyPress}
            />
            <NavButton
              color={Colors.mint400}
              icon={['fad', 'brackets-curly']}
              label={t('about.licenses')}
              onPress={() => navigation.navigate(RouteName.Licenses)}
            />
            <NavButton
              label={t('settings.releases')}
              icon={['fad', 'list-timeline']}
              onPress={() => navigation.navigate(RouteName.Releases)}
              color={Colors.apricot400}
            />
          </Wrapper>
          <InfoWrapper>
            <Info>{t('about.version', { version: appVersion })}</Info>
            <Info i18n-ignore> © {dayjs().get('year')} Veta Health Inc.</Info>
            <ImageWrapper>
              <SvgVetahealth width="100%" height={15} fill={theme.colors.text} />
            </ImageWrapper>
          </InfoWrapper>
        </ContentWrapper>
      </ScrollView>
    </ScreenLayout>
  )
}
