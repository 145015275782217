import { Portal } from '@gorhom/portal'
import { Colors } from '@vetahealth/fishing-gear/colors'
import React, { useCallback, useEffect, useMemo } from 'react'
import { LayoutChangeEvent, Platform, Pressable, StyleSheet, View } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import Animated, {
  interpolateColor,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import styled from 'styled-components/native'

import { FullWindowOverlay } from '../FullWindowOverlay'
import { BottomSheetProps } from './types'

const SPACING = { TOP: 30, BOTTOM: 20 }

const AnimatedPressable = Animated.createAnimatedComponent(Pressable)
const Backdrop = styled(AnimatedPressable)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${({ theme }) => theme.height}px;
  opacity: 0.5;
`
const Sheet = styled(Animated.View)<{ bottom: number }>`
  width: 90%;
  align-self: center;
  top: ${({ theme }) => theme.height}px;
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  padding: ${SPACING.TOP}px 20px ${SPACING.BOTTOM}px;
`
const SwipeIndicatorWrapper = styled.View`
  position: absolute;
  height: ${SPACING.TOP}px;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
`
const SwipeIndicator = styled.View`
  height: 4px;
  width: 30%;
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
  background-color: ${({ theme }) => theme.colors.border};
  margin-top: 10px;
`
const ChildrenWrapper = styled.View``

const animationConfig = { damping: 30, stiffness: 200 }

export function BottomSheet({ isVisible, onBackdropPress, children }: BottomSheetProps): JSX.Element | null {
  const { bottom } = useSafeAreaInsets()
  const backdropAnimation = useSharedValue(0)
  const heightAnimation = useSharedValue(0)
  const persistedHeight = useSharedValue(0)

  const backdropStyle = useAnimatedStyle(() => ({
    backgroundColor: interpolateColor(backdropAnimation.value, [0, 1], ['transparent', Colors.gray900]),
  }))

  const sheetStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: -heightAnimation.value }],
  }))

  const gesture = useMemo(() => {
    const pan = Gesture.Pan()
      .onStart(() => {
        persistedHeight.value = heightAnimation.value
      })
      .onUpdate((event) => {
        if (event.translationY > 0) {
          heightAnimation.value = persistedHeight.value - event.translationY
        }
      })
      .onEnd(() => {
        const shouldClose = persistedHeight.value - heightAnimation.value > 30

        if (shouldClose) {
          backdropAnimation.value = withSpring(0, {
            ...animationConfig,
            overshootClamping: true,
          })
          heightAnimation.value = withSpring(0, animationConfig, () => runOnJS(onBackdropPress)())
        } else {
          heightAnimation.value = withSpring(persistedHeight.value, animationConfig)
        }
      })

    return pan
  }, [])

  const handleClose = useCallback((): void => {
    backdropAnimation.value = withSpring(0, {
      ...animationConfig,
      overshootClamping: true,
    })
    heightAnimation.value = withSpring(0, animationConfig, () => runOnJS(onBackdropPress)())
  }, [])

  const handleHeightAnimation = useCallback((event: LayoutChangeEvent) => {
    const height = event.nativeEvent.layout.height
    heightAnimation.value = withSpring(height + SPACING.TOP + SPACING.BOTTOM + bottom, {
      ...animationConfig,
    })
  }, [])

  useEffect(() => {
    if (isVisible) {
      backdropAnimation.value = withSpring(1, {
        ...animationConfig,
        overshootClamping: true,
      })
    } else {
      backdropAnimation.value = 0
      heightAnimation.value = 0
    }
  }, [isVisible])

  if (!isVisible) return null

  return (
    <Portal hostName="bottomSheet">
      <FullWindowOverlay>
        <View style={Platform.OS === 'ios' ? null : StyleSheet.absoluteFill}>
          <Backdrop onPress={handleClose} style={backdropStyle} />
          <Sheet bottom={bottom} style={sheetStyle}>
            <GestureDetector gesture={gesture}>
              <SwipeIndicatorWrapper>
                <SwipeIndicator />
              </SwipeIndicatorWrapper>
            </GestureDetector>
            <ChildrenWrapper onLayout={handleHeightAnimation}>{children}</ChildrenWrapper>
          </Sheet>
        </View>
      </FullWindowOverlay>
    </Portal>
  )
}
