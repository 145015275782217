import dayjs from 'dayjs'
import React, { useCallback } from 'react'
import styled from 'styled-components/native'

import { IconButton } from '@components/Elements/Button'
import { Text } from '@components/Elements/Text'
import { RadioButton } from '@components/Elements/Toggles'
import { useI18n } from '@lib/i18n'

import { getIntervalDateLabel } from './helpers'
import { IntervalDates, IntervalType, TrackingIntervalProps } from './types'

const Wrapper = styled.View`
  padding: 0 20px 10px;
`
const RadioWrapper = styled.View`
  padding-bottom: 10px;
`
const IntervalWrapper = styled.View`
  flex-direction: row;
  gap: 5px;
`
const DateIntervalWrapper = styled.View`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`
const DateIntervalButtonWrapper = styled.View`
  position: absolute;
  right: 0;
`

export function getIntervalDates({
  intervalType,
  intervalDates,
  offset = 0,
}: {
  intervalType: IntervalType
  intervalDates: IntervalDates
  offset?: number
}): IntervalDates {
  if (!offset) {
    const today = dayjs()
    const start = intervalDates[1].startOf(intervalType)
    const end = intervalDates[1].endOf(intervalType)

    if (end.isAfter(today.endOf(intervalType))) {
      return [today.startOf(intervalType), today.endOf(intervalType)]
    }

    return [start, end]
  }

  const shiftedDate = intervalDates[0].subtract(offset, intervalType)

  return [shiftedDate.startOf(intervalType), shiftedDate.endOf(intervalType)]
}

export function TrackingInterval({
  intervalType,
  intervalDates,
  minDate,
  intervals,
  onChange,
}: TrackingIntervalProps): JSX.Element {
  const t = useI18n()
  const today = dayjs()

  const intervalsWithLabel: Array<{ value: IntervalType; label: string }> = [
    { value: 'day', label: t('tracking.range.day') },
    { value: 'week', label: t('tracking.range.week') },
    { value: 'month', label: t('tracking.range.month') },
  ]

  const selectableIntervals = intervals
    ? intervalsWithLabel.filter(({ value }) => intervals.includes(value))
    : intervalsWithLabel

  function handleIntervalChange(type: IntervalType): void {
    onChange(type, getIntervalDates({ intervalType: type, intervalDates }))
  }

  const handleIntervalOffsetChange = useCallback(
    (offset: number): void => {
      onChange(intervalType, getIntervalDates({ intervalType, intervalDates, offset }))
    },
    [intervalType, intervalDates],
  )

  function jumpToEndOfInterval(): void {
    onChange(intervalType, getIntervalDates({ intervalType, intervalDates: [today, today] }))
  }

  return (
    <Wrapper>
      <RadioWrapper>
        <RadioButton
          value={intervalType}
          items={selectableIntervals}
          onChange={(value) => handleIntervalChange(value as IntervalType)}
        />
      </RadioWrapper>
      <IntervalWrapper>
        <IconButton
          size="small"
          icon={['fas', 'arrow-left']}
          iconSize={14}
          variant="secondary"
          onPress={() => handleIntervalOffsetChange(1)}
          isDisabled={intervalDates[0].isBefore(minDate)}
        />
        <DateIntervalWrapper>
          <Text weight="semiBold" size="small">
            {getIntervalDateLabel({
              intervalType,
              intervalDates,
            })}
          </Text>
          {!dayjs().isBetween(...intervalDates) && (
            <DateIntervalButtonWrapper>
              <IconButton
                size="small"
                icon={['fas', 'calendar-day']}
                iconSize={14}
                variant="secondary"
                onPress={jumpToEndOfInterval}
              />
            </DateIntervalButtonWrapper>
          )}
        </DateIntervalWrapper>
        <IconButton
          size="small"
          icon={['fas', 'arrow-right']}
          iconSize={14}
          variant="secondary"
          onPress={() => handleIntervalOffsetChange(-1)}
          isDisabled={intervalDates[1].isSameOrAfter(today, intervalType)}
        />
      </IntervalWrapper>
    </Wrapper>
  )
}
