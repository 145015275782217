import { Content, DatoAssets } from '@components/Content'
import { DownloadApp } from '@components/DownloadApp'
import { Button } from '@components/Elements/Button'
import { KeyboardView } from '@components/Elements/KeyboardView'
import { HintText, Text } from '@components/Elements/Text'
import { ScreenLayout } from '@components/Layouts'
import { api } from '@lib/api'
import { getRemoteFormDefinition } from '@lib/validation'
import { Route, useRoute } from '@react-navigation/native'
import { useTasksStore } from '@stores/tasks'
import { useToastsStore } from '@stores/toasts'
import { Colors } from '@vetahealth/fishing-gear/colors'
import {
  PreviewTask as IPreviewTask,
  InfoScreen,
  PreviewMessageBody,
  PreviewSearchDtoTypeEnum,
  SupportedLocaleEnum,
  Task,
  Welcome,
} from '@vetahealth/tuna-can-api'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { RouteName } from '../../routeNames'

const Wrapper = styled.View`
  padding: 10px ${({ theme }) => (theme.width > 360 ? 20 : 10)}px 30px;
  margin: 0 10px;
`
const RefWrapper = styled.View`
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  background-color: ${Colors.plum200};
  padding: 3px 10px;
  align-self: flex-start;
  margin-left: ${({ theme }) => (theme.width > 360 ? 20 : 10)}px;
`
const Ref = styled(Text).attrs({
  size: 'tiny',
  weight: 'semiBold',
  color: Colors.plum500,
  margins: { bottom: 0 },
})``
const ResponseWrapper = styled.View`
  margin: 20px 0 0;
  padding: 20px;
  border-width: 1px;
  border-color: ${Colors.plum400};
  border-radius: 12px;
`
const ResponseLabel = styled(HintText).attrs({
  margins: { bottom: 20 },
  isCentered: true,
  weight: 'semiBold',
})``
const TaskResponseButtonWrapper = styled.View`
  margin-bottom: 20px;
`

function isTaskPreview(preview: IPreviewTask | InfoScreen | Welcome): preview is IPreviewTask | InfoScreen {
  return 'type' in preview
}

function isWelcomePreview(preview: IPreviewTask | InfoScreen | Welcome): preview is Welcome {
  return 'content' in preview
}

export function PreviewTask({
  locale,
  onPress,
}: {
  locale: `${SupportedLocaleEnum}`
  onPress: () => void
}) {
  const [setInfo] = useTasksStore((state) => [state.setInfo])
  const { params } = useRoute<Route<RouteName.PreviewTask, { ref?: string; type?: PreviewSearchDtoTypeEnum }>>()
  const [isLoading, setLoading] = useState<boolean>(true)
  const [isRefreshing, setRefresh] = useState<boolean>(false)
  const [preview, setPreview] = useState<IPreviewTask | InfoScreen | Welcome | undefined>()
  const [addToast] = useToastsStore((state) => [state.addToast])

  useEffect(() => {
    if (params.ref) {
      setLoading(true)
      api
        .getPreview({ ref: params.ref, locale })
        .then(setPreview)
        .finally(() => setLoading(false))
    }
  }, [params.ref, locale])

  function handleRefresh(): void {
    if (params.ref) {
      setRefresh(true)
      api
        .getPreview({ ref: params.ref, locale })
        .then(setPreview)
        .finally(() => setRefresh(false))
    }
  }

  function handleSendMessage(messageBody: PreviewMessageBody): void {
    if (params.ref) {
      api
        .sendPreviewMessage({ ref: params.ref, locale, messageBody })
        .then(() => addToast({ message: 'Message sent' }))
        .catch()
    }
  }

  function handleTaskResponse(taskResponse: InfoScreen): void {
    setInfo(taskResponse)
  }

  function renderMeasurementResponsesByType(
    channelType: 'SMS' | 'PUSH',
    data: { id: string; message: string }[],
  ): JSX.Element | null {
    return (
      <View>
        <ResponseLabel>{channelType}</ResponseLabel>
        {data.map(({ id, message }) => (
          <View key={id}>
            <Text margins={{ bottom: 20 }}>{message}</Text>
            <TaskResponseButtonWrapper>
              <Button
                fullWidth
                variant="secondary"
                label="Send"
                onPress={() =>
                  handleSendMessage({
                    channel: channelType,
                    message,
                    id,
                  })
                }
              />
            </TaskResponseButtonWrapper>
          </View>
        ))}
      </View>
    )
  }

  function getPreviewTitle(): string {
    if (params?.type === 'INFO_SCREEN') return 'Info'
    if (params?.type === 'WELCOME') return 'Welcome'
    return 'Task'
  }

  return (
    <ScreenLayout title={getPreviewTitle()} action={{ label: locale, onPress }} isStatic isLoading={isLoading} hasBack>
      <RefWrapper>
        <Ref>{params.ref}</Ref>
      </RefWrapper>
      <KeyboardView isRefreshing={isRefreshing} onRefresh={handleRefresh}>
        <Wrapper>
          {preview && isTaskPreview(preview) && (
            <>
              <Content
                content={preview}
                formDefinition={getRemoteFormDefinition(preview as Task)}
                submit={async (values) => console.log(values)}
              />

              {'taskResponses' in preview && (
                <ResponseWrapper>
                  <ResponseLabel i18n-ignore>-- Task responses --</ResponseLabel>
                  {preview.taskResponses?.map((taskResponse) => {
                    return (
                      <TaskResponseButtonWrapper key={taskResponse.id}>
                        <Button label={taskResponse.ref} onPress={() => handleTaskResponse(taskResponse)} fullWidth />
                      </TaskResponseButtonWrapper>
                    )
                  })}
                </ResponseWrapper>
              )}

              {'measurementResponses' in preview && preview.measurementResponses && (
                <ResponseWrapper>
                  <ResponseLabel i18n-ignore>-- Measurement responses --</ResponseLabel>
                  {renderMeasurementResponsesByType(
                    'SMS',
                    preview.measurementResponses
                      .filter((item) => item.sms)
                      .map((item) => ({
                        id: item.id,
                        message: item.sms ?? '',
                      })),
                  )}
                  {renderMeasurementResponsesByType(
                    'PUSH',
                    preview.measurementResponses
                      .filter((item) => item.push)
                      .map((item) => ({
                        id: item.id,
                        message: item.push ?? '',
                      })),
                  )}
                </ResponseWrapper>
              )}
            </>
          )}
          {preview && isWelcomePreview(preview) && (
            <>
              <DatoAssets assets={preview.content} />
              <DownloadApp testLocale={locale} />
            </>
          )}
        </Wrapper>
      </KeyboardView>
    </ScreenLayout>
  )
}
