import { SvgEmpty } from '@assets/svgr/Empty'
import { Svg } from '@components/Elements/Image'
import { TextInput } from '@components/Elements/Input'
import { Loading } from '@components/Elements/Loading'
import { Pressable } from '@components/Elements/Pressable'
import { Text } from '@components/Elements/Text'
import { ScreenLayout } from '@components/Layouts'
import { api } from '@lib/api'
import { useNavigation } from '@react-navigation/native'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { PreviewSearchDto, PreviewSearchDtoTypeEnum, SupportedLocaleEnum } from '@vetahealth/tuna-can-api'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/native'
import { RouteName } from '../../routeNames'

const Wrapper = styled.View`
  flex: 1;
  padding: 10px 30px;
  gap: 10px;
`
const ListWrapper = styled.ScrollView`
  flex: 1;
  border-top-width: 1px;
  border-color: ${({ theme }) => theme.colors.border};
  padding-bottom: 70px;
`
const ListItem = styled(Pressable)`
  width: 100%;
  padding: 10px;
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.colors.border};
  flex-direction: row;
  align-items: center;
`
const Empty = styled(SvgEmpty)`
  margin-top: 30px;
`
const LoadingWrapper = styled.View`
  margin-top: 30px;
`

export function PreviewSearch({
  locale,
  onPress,
}: {
  locale: `${SupportedLocaleEnum}`
  onPress: () => void
}) {
  const [value, setValue] = useState<string>('')
  const navigation = useNavigation()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [previewRefs, setPreviewRefs] = useState<PreviewSearchDto[] | undefined>([])

  const handleQuery = useCallback(
    debounce((query: string) => {
      setLoading(true)
      api
        .searchPreviewRefs(query.trim())
        .then(setPreviewRefs)
        .finally(() => setLoading(false))
    }, 300),
    [setPreviewRefs],
  )

  useEffect(() => {
    handleQuery(value)
  }, [value])

  const handlePress = useCallback(
    debounce((ref: string, type: PreviewSearchDtoTypeEnum) => {
      navigation.navigate(RouteName.PreviewTask, { ref, type })
    }, 300),
    [],
  )

  return (
    <ScreenLayout title={'Preview'} isStatic hasBack action={{ label: locale, onPress }}>
      <Wrapper>
        <TextInput value={value} onChange={setValue} placeholder="Search for task references" />
        <ListWrapper>
          {isLoading ? (
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          ) : (
            <>
              {previewRefs?.map(({ ref, type }, index) => (
                <ListItem
                  key={index}
                  onPress={() => handlePress(ref, type)}
                  render={() => (
                    <>
                      <Text weight="semiBold" size="button">
                        {ref}
                      </Text>
                      <Text color={Colors.plum600} margins={{ left: 10 }} weight="semiBold" size="tiny">
                        {type.toLowerCase()}
                      </Text>
                    </>
                  )}
                />
              ))}
              {(!previewRefs || !previewRefs.length) && (
                <Svg size="small">
                  <Empty />
                </Svg>
              )}
            </>
          )}
        </ListWrapper>
      </Wrapper>
    </ScreenLayout>
  )
}
