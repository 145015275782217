import { ModalLayout } from '@components/Layouts'
import { Account } from '@components/Onboarding'
import { useI18n } from '@lib/i18n'
import React from 'react'

export function UpdateAccountModal() {
  const t = useI18n()

  return (
    <ModalLayout title={t('screen.account')} isStatic hasCancel={false}>
      <Account />
    </ModalLayout>
  )
}
