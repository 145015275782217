import { Colors } from '@vetahealth/fishing-gear/colors'
import dayjs from 'dayjs'
import React from 'react'
import styled from 'styled-components/native'
import { Text } from '../Elements/Text'

export const MessageWrapper = styled.View`
  padding: 20px 30px;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
`

export const Message = styled(Text).attrs({
  weight: 'semiBold',
  size: 'button',
})``

const Wrapper = styled.View`
  flex-grow: 1;
  align-self: flex-start;
`
const EventValueWrapper = styled.View`
  margin-top: 5px;
  align-self: flex-end;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`
const EventValueInnerWrapper = styled.View`
  justify-content: flex-end;
  align-items: flex-end;
`

export function TrackingEventValue({
  displayValue,
  timestamp,
  color,
}: { displayValue: string; timestamp?: string; color?: Colors }): JSX.Element {
  const splittedDisplayValue = displayValue.split(/\s+/)

  return (
    <Wrapper>
      <EventValueWrapper>
        {splittedDisplayValue.map((item, index) => {
          return (
            <EventValueInnerWrapper key={index}>
              {index % 2 === 0 ? (
                <Text
                  size="xLarge"
                  margins={{ bottom: -4 }}
                  disableLineHeight
                  key={index}
                  weight="semiBold"
                  color={color}
                >
                  {item}
                </Text>
              ) : (
                <Text
                  disableLineHeight
                  margins={{ bottom: -1 }}
                  size="small"
                  weight="medium"
                  color={color}
                >{` ${item} `}</Text>
              )}
            </EventValueInnerWrapper>
          )
        })}
        {!!timestamp && (
          <Text
            margins={{ bottom: -1, left: 10, right: 10 }}
            disableLineHeight
            weight="medium"
            size="tiny"
            numberOfLines={1}
          >
            {dayjs(timestamp).fromNow()}
          </Text>
        )}
      </EventValueWrapper>
    </Wrapper>
  )
}
