import React, { Component, ReactNode } from 'react'

import { SvgUnexpected } from '@assets/svgr/Unexpected'
import { Button } from '@components/Elements/Button'
import { Svg } from '@components/Elements/Image'
import { captureException } from '@lib/error'
import { t } from '@lib/i18n'

import { ButtonWrapper, Message, ScrollView, Wrapper } from './styles'

export class ErrorBoundary extends Component<{ children: ReactNode }, { hasError: boolean }> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: any) {
    captureException(error, 'ErrorBoundary')
  }

  render() {
    // Ups. Something went wrong! Please try again.

    if (this.state.hasError) {
      return (
        <ScrollView>
          <Wrapper>
            <Svg>
              <SvgUnexpected />
            </Svg>
            <Message>{t('error.general.message')}</Message>
            <ButtonWrapper>
              <Button label={t('error.general.redo')} onPress={() => this.setState({ hasError: false })} />
            </ButtonWrapper>
          </Wrapper>
        </ScrollView>
      )
    }

    return this.props.children
  }
}
