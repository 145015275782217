import { Colors } from '@vetahealth/fishing-gear/colors'
import { SupportedLocaleEnum } from '@vetahealth/tuna-can-api'
import React from 'react'

import { SelectButton } from '@components/Elements/Button'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { useAuthStore } from '@stores/auth'

import { OptionsWrapper, Wrapper } from './styles'

const languageLabels: Record<SupportedLocaleEnum, string> = {
  [SupportedLocaleEnum.EnUs]: 'English (US)',
  [SupportedLocaleEnum.Es419]: 'Español (América Latina)',
  [SupportedLocaleEnum.EsUs]: 'Español (US)',
}

export function Language(): JSX.Element | null {
  const t = useI18n()
  const [user, updateAccount, supportedLocales] = useAuthStore((state) => [
    state.user,
    state.updateAccount,
    state.supportedLocales,
  ])

  function handleLanguageChange(locale: SupportedLocaleEnum): void {
    trackEvent(TrackingEvent.appLanguageChanged, { language: locale })
    updateAccount({ locale })
  }

  if (!user) return null

  return (
    <Wrapper color={Colors.azure300} icon={['fad', 'earth-americas']} title={t('settings.language')}>
      <OptionsWrapper>
        {supportedLocales.map((supportedLocale, index) => (
          <SelectButton
            hasBottomBorder={index < supportedLocales.length - 1}
            type="radio"
            label={languageLabels[supportedLocale]}
            key={supportedLocale}
            isChecked={user.locale === supportedLocale}
            onPress={() => handleLanguageChange(supportedLocale as SupportedLocaleEnum)}
          />
        ))}
      </OptionsWrapper>
    </Wrapper>
  )
}
