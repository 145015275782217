import React, { useEffect } from 'react'
import { Linking, useWindowDimensions } from 'react-native'
import Animated, { useAnimatedProps, useSharedValue } from 'react-native-reanimated'
import { Circle, G, Svg } from 'react-native-svg'
import styled, { useTheme } from 'styled-components/native'

import { Text } from '@components/Elements/Text'
import { storage } from '@lib/mmkv'
import { useAppStore } from '@stores/app'

const Wrapper = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`
const TextWrapper = styled.View`
  position: absolute;
`

const AnimatedCircle = Animated.createAnimatedComponent(Circle)

export function Codepush(): JSX.Element {
  const theme = useTheme()
  const [codepushUpdateProgress] = useAppStore((state) => [state.codepushUpdateProgress])
  const { width, height } = useWindowDimensions()
  const circleLength = width * 1.75
  const radius = circleLength / (2 * Math.PI)

  const animatedProgress = useSharedValue(0)
  const animatedProps = useAnimatedProps(() => ({
    strokeDashoffset: circleLength * (1 - animatedProgress.value),
  }))

  useEffect(() => {
    if (codepushUpdateProgress) {
      animatedProgress.value = codepushUpdateProgress
    }
  }, [codepushUpdateProgress])

  useEffect(() => {
    Linking.getInitialURL().then((url) => url && storage.set('deepLink', url))
    Linking.addEventListener('url', ({ url }) => storage.set('deepLink', url))

    return () => {
      Linking.removeAllListeners('url')
    }
  }, [])

  return (
    <Wrapper>
      <TextWrapper>
        <Text color={theme.colors.primary} size="xxLarge" weight="bold">
          {Math.floor((codepushUpdateProgress || 0) * 100)}
        </Text>
      </TextWrapper>
      <Svg fillOpacity={0}>
        <G rotation="-90" origin={`${width / 2}, ${height / 2}`}>
          <Circle
            cx={width / 2}
            cy={height / 2}
            r={radius}
            stroke={theme.colors.secondaryBackground}
            strokeWidth={30}
          />
          <AnimatedCircle
            cx={width / 2}
            cy={height / 2}
            r={radius}
            stroke={theme.colors.primary}
            strokeWidth={15}
            strokeDasharray={circleLength}
            strokeLinecap="round"
            animatedProps={animatedProps}
          />
        </G>
      </Svg>
    </Wrapper>
  )
}
