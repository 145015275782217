import { IconButton } from '@components/Elements/Button'
import { Text } from '@components/Elements/Text'
import { ModalLayout } from '@components/Layouts'
import {
  TrackingChartAssetTrackingTypeWithoutHeightEnum,
  TrackingForm,
  TrackingList,
  getTrackingConfig,
} from '@components/Tracking'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { Route, useNavigation, useRoute } from '@react-navigation/native'
import { useTrackingEventsStore } from '@stores/trackingEvents'
import React, { useEffect, useState } from 'react'
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated'
import styled, { useTheme } from 'styled-components/native'
import { RouteName } from '../../routeNames'
import { isSupportedTrackingType } from '../helpers'

const Container = styled(Animated.View)`
  flex-direction: row;
`
const Wrapper = styled.View`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
`
const InnerWrapper = styled.View`
  flex-direction: row;
  padding: 0 20px 10px;
  align-items: center;
`
const TrackingFormWrapper = styled.View`
  padding: 0 30px;
`

export function AddTrackingModal() {
  const t = useI18n()
  const theme = useTheme()
  const { params } =
    useRoute<
      Route<RouteName.AddTrackingModal, { trackingType?: `${TrackingChartAssetTrackingTypeWithoutHeightEnum}` }>
    >()
  const supportedTrackingType = isSupportedTrackingType(params?.trackingType) ? params.trackingType : undefined
  const [trackingType, setTrackingType] = useState<`${TrackingChartAssetTrackingTypeWithoutHeightEnum}` | undefined>(
    supportedTrackingType,
  )
  const navigation = useNavigation()
  const animation = useSharedValue(trackingType ? 1 : 0)
  const [lastTrackingEvents, getLastTrackingEvents] = useTrackingEventsStore((state) => [
    state.lastTrackingEvents,
    state.getLastTrackingEvents,
  ])

  const containerStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: interpolate(animation.value, [0, 1], [0, -theme.width]) }],
  }))

  useEffect(() => {
    if (!lastTrackingEvents) getLastTrackingEvents()
  }, [])

  useEffect(() => {
    animation.value = withSpring(trackingType ? 1 : 0, {
      damping: 30,
      stiffness: 300,
    })
  }, [trackingType])

  function handleOnSucces(): void {
    getLastTrackingEvents()
    navigation.goBack()
  }

  function handleTrackingType(trackingType: `${TrackingChartAssetTrackingTypeWithoutHeightEnum}`): void {
    trackEvent(TrackingEvent.trackingAddTypePressed, { type: trackingType })
    setTrackingType(trackingType)
  }

  function handleBack(): void {
    trackEvent(TrackingEvent.trackingAddBackPressed)
    setTrackingType(undefined)
  }

  const { title } = (trackingType && getTrackingConfig(trackingType)) || {}

  return (
    <ModalLayout title={t('screen.addTracking')} isLoading={!lastTrackingEvents}>
      <Container style={containerStyle}>
        <Wrapper>
          <TrackingList manualTrackingOnly onPress={handleTrackingType} />
        </Wrapper>
        <Wrapper>
          {!!trackingType && (
            <>
              <InnerWrapper>
                {!params?.trackingType && (
                  <IconButton variant="plain" icon={['fas', 'arrow-left']} onPress={handleBack} />
                )}
                <Text margins={trackingType ? { left: 10, right: 10 } : undefined} weight="bold">
                  {title}
                </Text>
              </InnerWrapper>
              <TrackingFormWrapper>
                <TrackingForm trackingType={trackingType} onSuccessCallback={handleOnSucces} />
              </TrackingFormWrapper>
            </>
          )}
        </Wrapper>
      </Container>
    </ModalLayout>
  )
}
