import React, { forwardRef } from 'react'
import { RefreshControl } from 'react-native'
import { KeyboardAwareScrollView, KeyboardAwareScrollViewProps } from 'react-native-keyboard-aware-scroll-view'
import { useTheme } from 'styled-components/native'

import { useBottom } from '@lib/hooks'

const paddingTop = 10
const paddingBottom = 30

export const KeyboardView = forwardRef<
  KeyboardAwareScrollView,
  KeyboardAwareScrollViewProps & {
    isRefreshing?: boolean
    onRefresh?: () => void
  }
>(({ onRefresh, isRefreshing, children, ...props }, ref) => {
  const theme = useTheme()
  const bottomOffset = useBottom(0, paddingBottom)

  return (
    <KeyboardAwareScrollView
      ref={ref}
      contentContainerStyle={{
        paddingBottom: bottomOffset,
        paddingTop,
      }}
      keyboardShouldPersistTaps="handled"
      extraScrollHeight={20}
      {...props}
      refreshControl={
        onRefresh && (
          <RefreshControl
            colors={[theme.colors.primary]}
            tintColor={theme.colors.primary}
            refreshing={!!isRefreshing}
            onRefresh={onRefresh}
          />
        )
      }
    >
      {children}
    </KeyboardAwareScrollView>
  )
})
