import React from 'react'
import styled from 'styled-components'
import { useTheme } from 'styled-components/native'

const Iframe = styled.iframe`
  width: 100%;
  height: 315px;
`

export function YouTubePlayer({ id }: { id: string }): JSX.Element {
  const theme = useTheme()

  return (
    <Iframe
      width="100%"
      src={`https://www.youtube.com/embed/${id}?rel=0`}
      title="YouTube video player"
      style={{ borderRadius: theme.borderRadius.medium }}
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )
}
