import { Portal } from '@gorhom/portal'
import { Colors } from '@vetahealth/fishing-gear/colors'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import Animated, { runOnJS, useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import styled, { useTheme } from 'styled-components/native'

import { isWeb } from '@lib/constants'
import { ToastWithId, useToastsStore } from '@stores/toasts'

import { Markdown } from '../../Markdown'
import { IconButton } from '../Button'
import { FullWindowOverlay } from '../FullWindowOverlay'
import { Icon } from '../Icon'
import { Pressable } from '../Pressable'
import { Text } from '../Text'

const Container = styled.View<{ top: number }>`
  position: absolute;
  top: ${({ top }) => top || 0}px;
  width: 100%;
  z-index: 3;
`
const Wrapper = styled(Animated.View)<{ hasAction: boolean }>`
  flex-direction: row;
  align-self: center;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
  padding: 20px;
  background-color: ${Colors.white};
  margin: 5px 20px;
  width: ${({ hasAction }) => (isWeb && hasAction ? '90%' : 'auto')};
  pointer-events: auto;
`
const ToastColor = styled.View`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
  background-color: ${({ theme }) => theme.colors.toastBackground};
`
const ContentWrapper = styled.View<{ hasAction: boolean }>`
  ${({ hasAction }) => (hasAction || isWeb) && 'flex: 1;'}
`
const ContentHeaderWrapper = styled.View<{ hasMessage: boolean }>`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ hasMessage }) => (hasMessage ? 10 : 0)}px;
`
const IconWrapper = styled.View<{ color?: Colors }>`
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  background-color: ${({ color, theme }) => color || theme.colors.primary};
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`
const ActionWrapper = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 5px;
`
const ToastButton = styled(Pressable)`
  padding: 5px 10px 0;
  margin-left: 20px;
`
const CancelWrapper = styled.View<{ isCentered?: boolean }>`
  right: 0px;
  top: 0px;
  justify-content: ${({ isCentered }) => (isCentered ? 'center' : 'flex-start')};
  margin: -10px -10px -10px 10px;
`

const DEFAULT_DURATION = 12000 // 12s

const CustomGestureDetector = (props: Parameters<typeof GestureDetector>[0]): JSX.Element => {
  return isWeb ? (
    <View>{props.children}</View>
  ) : (
    <GestureDetector gesture={props.gesture}>{props.children}</GestureDetector>
  )
}

function Toast({
  title,
  message,
  id,
  duration,
  icon,
  iconColor,
  isCancable = true,
  onPress,
  infinite,
  primaryAction,
  secondaryAction,
  backgroundAction,
}: ToastWithId) {
  const theme = useTheme()
  const [removeToast] = useToastsStore((state) => [state.removeToast])
  const translateY = useSharedValue(-30)
  const opacity = useSharedValue(0)

  const shadowStyle = {
    shadowColor: Colors.gray900,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4.41,
    elevation: 2,
  }

  useEffect(() => {
    backgroundAction?.()

    translateY.value = withSpring(0, { overshootClamping: true })
    opacity.value = withSpring(1, { overshootClamping: true })

    const timer =
      !infinite &&
      setTimeout(() => {
        translateY.value = withSpring(-30, { overshootClamping: true })
        opacity.value = withSpring(0, { overshootClamping: true }, () => runOnJS(removeToast)(id))
      }, duration || DEFAULT_DURATION)

    return () => {
      if (!infinite && timer) clearTimeout(timer)
    }
  }, [])

  const pan = Gesture.Pan()
    .onUpdate((event) => {
      if (event.translationY < 0) {
        translateY.value = event.translationY
      }
    })
    .onEnd(() => {
      if (isCancable && translateY.value <= -30) {
        opacity.value = withSpring(0, undefined, () => runOnJS(removeToast)(id))
      } else {
        translateY.value = withSpring(0)
      }
    })

  const tap = Gesture.Tap()
    .onEnd(() => {
      if (onPress) {
        runOnJS(removeToast)(id)
        runOnJS(onPress)()
      }
    })
    .numberOfTaps(1)

  const style = useAnimatedStyle(() => ({
    transform: [
      {
        translateY: translateY.value,
      },
    ],
    opacity: opacity.value,
  }))

  function handleCancelPress() {
    translateY.value = withSpring(-30, { overshootClamping: true })
    opacity.value = withSpring(0, { overshootClamping: true }, () => runOnJS(removeToast)(id))
  }

  function handleActionPress(action: () => void): () => void {
    return () => {
      handleCancelPress()
      action()
    }
  }

  const hasAction = Boolean(primaryAction || secondaryAction)

  return (
    <CustomGestureDetector gesture={Gesture.Race(tap, pan)}>
      <Wrapper hasAction={hasAction} style={[shadowStyle, style]}>
        <ToastColor />
        <ContentWrapper hasAction={hasAction}>
          <>
            {(title || icon) && (
              <ContentHeaderWrapper hasMessage={!!message}>
                {!!icon && (
                  <IconWrapper color={!theme.isLight ? iconColor : Colors.mauve50}>
                    <Icon icon={icon} color={theme.isLight ? iconColor : Colors.mauve50} size={14} />
                  </IconWrapper>
                )}
                {!!title && (
                  <Text color={theme.colors.background} size="settings" weight="bold">
                    {title}
                  </Text>
                )}
              </ContentHeaderWrapper>
            )}
            {!!message && (
              <Markdown
                paragraphColor={theme.colors.background}
                withoutParagraphSpacing
                defaultWeight="semiBold"
                defaultFontSize="toast"
                text={message}
              />
            )}
            {hasAction && (
              <ActionWrapper>
                {secondaryAction && (
                  <ToastButton
                    onPress={handleActionPress(secondaryAction.onPress)}
                    render={() => (
                      <Text color={theme.isLight ? Colors.white : theme.colors.primary} weight="bold" size="button">
                        {secondaryAction.label}
                      </Text>
                    )}
                  />
                )}
                {primaryAction && (
                  <ToastButton
                    onPress={handleActionPress(primaryAction.onPress)}
                    render={() => (
                      <Text color={theme.isLight ? Colors.white : theme.colors.primary} weight="bold" size="button">
                        {primaryAction.label}
                      </Text>
                    )}
                  />
                )}
              </ActionWrapper>
            )}
          </>
        </ContentWrapper>
        {isWeb && isCancable && (
          <CancelWrapper isCentered={!title || !message}>
            <IconButton
              icon={['fas', 'xmark']}
              variant="plain"
              color={theme.colors.closeToast}
              onPress={handleCancelPress}
              size="small"
            />
          </CancelWrapper>
        )}
      </Wrapper>
    </CustomGestureDetector>
  )
}

export function Toasts() {
  const { top } = useSafeAreaInsets()
  const [toasts] = useToastsStore((state) => [state.toasts])

  return (
    <Portal hostName="toast">
      <FullWindowOverlay>
        <Container top={top}>
          {toasts.map(({ key, ...toast }) => (
            <Toast key={key || toast.id} {...toast} />
          ))}
        </Container>
      </FullWindowOverlay>
    </Portal>
  )
}
