import { useIsFocused, useNavigation } from '@react-navigation/native'
import React, { useEffect, useReducer, useRef } from 'react'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated'
import styled from 'styled-components/native'

import { SvgCheck } from '@assets/svgr/Check'
import { Content } from '@components/Content'
import { Dialog } from '@components/Elements/Dialog'
import { Empty } from '@components/Elements/Empty'
import { KeyboardView } from '@components/Elements/KeyboardView'
import { TaskInput } from '@lib/api'
import { useResetParams } from '@lib/hooks'
import { useI18n } from '@lib/i18n'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { getRemoteFormDefinition } from '@lib/validation'
import { useTasksStore } from '@stores/tasks'

import { RouteName } from '../../router/routeNames'

const Wrapper = styled(Animated.View)`
  padding: 0 ${({ theme }) => (theme.width > 360 ? 20 : 10)}px 0px;
  margin: 0 10px;
`

type TaskState = {
  isRefreshing: boolean
  isVisible: boolean
}

const initialState: TaskState = {
  isRefreshing: false,
  isVisible: false,
}

export function Task() {
  const t = useI18n()
  const isFocused = useIsFocused()
  const navigation = useNavigation()
  const animation = useSharedValue(0)
  const scrollRef = useRef<KeyboardAwareScrollView>(null)
  const resetParams = useResetParams()
  const [{ isRefreshing, isVisible }, set] = useReducer(
    (state: TaskState, update: Partial<TaskState>) => ({ ...state, ...update }),
    initialState,
  )
  const [taskByRef, task, submitTask, getTask, reset] = useTasksStore((state) => [
    state.taskByRef,
    state.task,
    state.submitTask,
    state.getTask,
    state.reset,
  ])
  const style = useAnimatedStyle(() => ({
    opacity: interpolate(animation.value, [0, 1], [0.1, 1]),
    transform: [{ translateY: interpolate(animation.value, [0, 1], [10, 0]) }],
  }))

  const displayTask = taskByRef || task
  const trackingPayload = displayTask && {
    ref: displayTask.ref,
    version: displayTask.version,
    locale: displayTask.locale,
  }

  async function handleSubmit(response: TaskInput): Promise<void> {
    if (displayTask) {
      trackEvent(TrackingEvent.taskSubmitPressed, trackingPayload)
      await submitTask({ task: displayTask, response })

      resetParams()
    }
  }

  function handleTaskRespondedTracking(): void {
    trackEvent(TrackingEvent.taskRespondedTrackingPressed)
    set({ isVisible: false })
    navigation.navigate(RouteName.Tracking)
    reset()
    getTask()
  }

  useEffect(() => {
    if (displayTask) {
      trackEvent(TrackingEvent.taskViewed, trackingPayload)
    }
  }, [displayTask?.ref])

  useEffect(() => {
    if (isFocused && displayTask?.hasResponse) set({ isVisible: true })
  }, [displayTask, isFocused])

  useEffect(() => {
    animation.value = 0
    animation.value = withSpring(1)

    if (scrollRef.current) {
      scrollRef.current.scrollToPosition(0, 0, true)
    }
  }, [displayTask])

  function handleRefresh(): void {
    trackEvent(TrackingEvent.taskRefreshPulled)
    set({ isRefreshing: true })
    getTask().finally(() => set({ isRefreshing: false }))
  }

  return (
    <KeyboardView ref={scrollRef} isRefreshing={isRefreshing} onRefresh={displayTask ? undefined : handleRefresh}>
      <Wrapper style={style}>
        {displayTask ? (
          <Content content={displayTask} submit={handleSubmit} formDefinition={getRemoteFormDefinition(displayTask)} />
        ) : (
          <Empty message={t('empty.allDone')} svg={<SvgCheck />} />
        )}
      </Wrapper>
      <Dialog
        isVisible={isVisible}
        message={t('dialog.answeredTask.description')}
        primaryAction={{
          label: t('dialog.answeredTask.tracking'),
          onPress: handleTaskRespondedTracking,
        }}
        secondaryAction={{
          label: t('dialog.answeredTask.task'),
          onPress: () => set({ isVisible: false }),
        }}
      />
    </KeyboardView>
  )
}
