import { yupResolver } from '@hookform/resolvers/yup'
import { TaskInput } from '@lib/api'
import { useI18n } from '@lib/i18n'
import { getRemoteFormDefinition } from '@lib/validation'
import { AssetTypeEnum, InfoScreen, Task } from '@vetahealth/tuna-can-api'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { Actions } from './Actions'
import { DatoAssets } from './Assets'
import { Header } from './Header'

export function Content({
  content,
  formDefinition,
  hideActions = false,
  submit,
}: {
  content: Task | InfoScreen
  formDefinition?: ReturnType<typeof getRemoteFormDefinition>
  hideActions?: boolean
  submit?: (response: TaskInput) => Promise<void>
}) {
  const interpolateText = useI18n()

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = useForm({
    ...(formDefinition && {
      resolver: yupResolver(formDefinition.validationSchema),
      defaultValues: formDefinition.initialValues,
    }),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const handleFormSubmit = useCallback(() => {
    handleSubmit(async (values: TaskInput): Promise<void> => {
      if (submit) await submit(values)
    })()
  }, [submit])

  useEffect(() => {
    reset(formDefinition?.initialValues)
  }, [content])

  return (
    <View>
      {'title' in content && <Header title={interpolateText(content.title)} />}

      <DatoAssets assets={content.assets} control={control} />

      {!hideActions && (
        <Actions
          hasQuestions={content.assets.some(({ type }) => type === AssetTypeEnum.Question)}
          onSubmit={handleFormSubmit}
          isSubmitLoading={isSubmitting}
        />
      )}
    </View>
  )
}
