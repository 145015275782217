import React, { ReactNode } from 'react'
import styled from 'styled-components/native'

import { KeyboardView } from '../Elements/KeyboardView'
import { Text } from '../Elements/Text'

export const Wrapper = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
  background-color: ${({ theme }) => theme.colors.background};
  overflow: hidden;
`
export const HeaderWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
`
export const Header = styled.View`
  flex-direction: row;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  margin: 0 10px;
  flex: 1;
`
export const TitleWrapper = styled.View``
export const Title = styled(Text).attrs<{ length?: number }>(({ length }) => ({
  numberOfLines: 1,
  weight: 'bold',
  size: length && length >= 20 ? 'large' : 'xLarge',
}))`
`
export const ActionWrapper = styled.View``
export const StaticContent = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
`
export const ScrollContent = ({ children }: { children: ReactNode }) => {
  return (
    <KeyboardView>
      <StaticContent>{children}</StaticContent>
    </KeyboardView>
  )
}
export const LoadingWrapper = styled.View`
  flex: 1;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
`
