import React from 'react'
import styled from 'styled-components/native'

import { Text } from '../Elements/Text'

const Wrapper = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`
const TitleWrapper = styled.View`
  flex: 1;
  justify-content: center;
`

export function Header({ title }: { title: string }) {
  return (
    <Wrapper>
      <TitleWrapper>
        <Text weight="bold" size="large">
          {title}
        </Text>
      </TitleWrapper>
    </Wrapper>
  )
}
