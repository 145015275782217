import { ModalLayout } from '@components/Layouts'
import {
  TrackingChartAssetTrackingTypeWithoutHeightEnum,
  TrackingInsights,
  getTrackingConfig,
} from '@components/Tracking'
import { Route, useRoute } from '@react-navigation/native'
import React from 'react'
import styled from 'styled-components/native'
import { RouteName } from '../../../router/routeNames'

const Wrapper = styled.View`
  padding: 0 20px;
`

export function TrackingInsightsModal() {
  const { params } =
    useRoute<Route<RouteName.TrackingEventModal, { trackingType: TrackingChartAssetTrackingTypeWithoutHeightEnum }>>()

  const trackingConfig = getTrackingConfig(params.trackingType)

  return (
    <ModalLayout title={trackingConfig.title}>
      <Wrapper>
        <TrackingInsights trackingType={params.trackingType} trackingConfig={getTrackingConfig(params.trackingType)} />
      </Wrapper>
    </ModalLayout>
  )
}
