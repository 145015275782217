import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { Colors } from '@vetahealth/fishing-gear/colors'
import pickBy from 'lodash/pickBy'
import React, { useEffect } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import styled from 'styled-components/native'

import { Icon } from '@components/Elements/Icon'
import { Pressable } from '@components/Elements/Pressable'
import { isWeb, tabBarHeight } from '@lib/constants'
import { useChatStore } from '@stores/chat'
import { useTasksStore } from '@stores/tasks'

import { RouteName } from '../routeNames'
import { isSupportedTrackingType } from '../routes/helpers'
import { Tab } from './Tab'
import { getTabs } from './tabs'

const Container = styled.View<{ bottom: number }>`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: ${({ bottom }) => (bottom || 10) + tabBarHeight}px;
`
const Wrapper = styled.View`
  position: absolute;
  height: ${tabBarHeight}px;
  padding: 5px 10px 5px 5px;
  top: 0;
  align-self: center;
  flex-direction: row;
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.tabBarBackground};
`
const TabBarBackground = styled.View`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.tabBarBackground};
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
`
const AddTrackingWrapper = styled.View`
  align-items: center;
  justify-content: center;
`
const AddButton = styled(Pressable)`
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.xLarge}px;
  background-color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  align-items: center;
  margin: 0 10px;
`

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.2,
  shadowRadius: isWeb ? 10 : 1.41,

  elevation: 2,
}

export function TabBar({ state, navigation }: BottomTabBarProps) {
  const [hasTask] = useTasksStore((taskState) => [taskState.hasTask])
  const [hasChatMessages, getChatMessages] = useChatStore((state) => [state.hasChatMessages, state.getChatMessages])
  const { bottom } = useSafeAreaInsets()

  function handleAddTrackingPress(): void {
    const { index, routes } = navigation.getState()
    const { index: routeIndex, routes: routeRoutes } = routes[index].state || {}
    const { trackingType }: { trackingType?: string } = (routeIndex && routeRoutes?.[routeIndex]?.params) || {}

    navigation.navigate(
      RouteName.AddTrackingModal,
      isSupportedTrackingType(trackingType) ? { trackingType } : undefined,
    )
  }

  useEffect(() => {
    getChatMessages()
  }, [state.index])

  return (
    <Container bottom={bottom}>
      <Wrapper style={shadow}>
        <TabBarBackground />
        {state.routes.map((route, index) => {
          const tabOptions = getTabs()[route.name as RouteName]
          const isFocused = state.index === index

          const hasTaskBadge = hasTask && !isFocused && route.name === RouteName.Task
          const hasChatMessageBadge = hasChatMessages && !isFocused && route.name === RouteName.Messaging

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            })

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name, pickBy(route.params))
            }
          }

          if (route.name === RouteName.PseudoTabAddTracking) {
            return (
              <AddTrackingWrapper key={RouteName.AddTrackingModal}>
                <AddButton
                  onPress={handleAddTrackingPress}
                  hoverStyle={{ color: Colors.mauve700, opacity: 1 }}
                  render={() => <Icon size={20} color={Colors.white} icon={['fas', 'plus']} />}
                />
              </AddTrackingWrapper>
            )
          }

          return (
            <Pressable
              key={route.name}
              onPress={onPress}
              render={({ hovered }) => (
                <Tab
                  icon={tabOptions?.icon}
                  isFocused={isFocused}
                  isHovered={hovered}
                  hasBadge={hasTaskBadge || hasChatMessageBadge}
                />
              )}
            />
          )
        })}
      </Wrapper>
    </Container>
  )
}
