import { Portal } from '@gorhom/portal'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { CountryCode, getCountryCallingCode, isSupportedCountry, parsePhoneNumberWithError } from 'libphonenumber-js'
import noop from 'lodash/noop'
import React, { useEffect, useMemo, useState } from 'react'
import { FlatList } from 'react-native'
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import styled, { useTheme } from 'styled-components/native'

import { deviceCountry } from '@lib/constants'
import { Country, countries } from '@lib/countries'
import { useI18n } from '@lib/i18n'
import { storage } from '@lib/mmkv'

import { IconButton } from '../Button'
import { Pressable } from '../Pressable'
import { HintText, Text } from '../Text'
import { BaseInput } from './BaseInput'
import { InputError } from './InputError'
import { PhoneInputProps } from './types'

const Wrapper = styled.View`
  width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
`
const InlineWrapper = styled.View`
  flex-direction: row;
`
const InlineInputWrapper = styled.View`
  flex: 1;
`
const CountryButton = styled(Pressable)`
  height: 44px;
  min-width: 46px;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};
  border-width: 2px;
  border-color: ${({ theme }) => theme.colors.border};
  margin-right: 5px;
  padding: 0 15px;
`

const CountryModal = styled.View`
  position: absolute;
  height: ${({ theme }) => theme.height}px;
  width: 100%;
`
const Backdrop = styled(Animated.View)`
  position: absolute;
  background-color: ${Colors.gray900};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.8;
`
const CountryModalContent = styled(Animated.View)<{
  insets: { top: number; bottom: number }
}>`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ insets }) => `30px 30px ${30 + insets.bottom}px`};
  margin: ${({ insets }) => `${insets.top || 10}px 0 0`};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.large}px;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.large}px;
`
const CountryModalHeader = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;
`
const CountryListItemWrapper = styled(Pressable)<{ isSelected: boolean }>`
  height: 50px;
  padding: 0 10px;
  justify-content: center;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.secondaryBackground : theme.colors.background};
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
`

function formatCountryCode(countryCode: CountryCode): string {
  return `+${getCountryCallingCode(countryCode)}`
}

export function PhoneInput({
  value,
  onChange = noop,
  onSubmitEditing = noop,
  placeholder,
  error,
}: PhoneInputProps): JSX.Element {
  const t = useI18n()
  const theme = useTheme()
  const { top, bottom } = useSafeAreaInsets()
  const [countryCode, setCountryCode] = useState<CountryCode>(deviceCountry as CountryCode)
  const [isVisible, setVisibility] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const animation = useSharedValue(isVisible ? 1 : 0)

  const countryModalStyle = useAnimatedStyle(() => ({
    opacity: interpolate(animation.value, [0, 1], [0, 0.8]),
  }))

  const countryContentStyle = useAnimatedStyle(() => ({
    top: interpolate(animation.value, [0, 1], [theme.height * 1.5, 0]),
  }))

  useEffect(() => {
    animation.value = withSpring(isVisible ? 1 : 0, {
      overshootClamping: true,
      damping: 20,
      stiffness: 150,
    })
  }, [isVisible])

  useEffect(() => {
    if (value) {
      try {
        const parsedPhoneNumber = parsePhoneNumberWithError(value)
        setPhoneNumber(parsedPhoneNumber?.nationalNumber as string)
        setCountryCode(
          parsedPhoneNumber.country || (isSupportedCountry(deviceCountry) ? (deviceCountry as CountryCode) : 'US'),
        )
      } catch (_) {}
    } else {
      const lastSelectedCountryCode = storage.get('lastSelectedCountryCode')
      if (lastSelectedCountryCode) setCountryCode(lastSelectedCountryCode as CountryCode)
    }
  }, [])

  function handleCountryCode(newValue: CountryCode): void {
    setCountryCode(newValue)
    setVisibility(false)
    storage.set('lastSelectedCountryCode', newValue)
    onChange(formatCountryCode(newValue) + phoneNumber)
  }

  function handlePhoneNumber(newValue: string): void {
    setPhoneNumber(newValue)
    onChange(formatCountryCode(countryCode) + newValue)
  }

  function renderItem({ item }: { item: Country }) {
    const isSelected = countryCode === item.code
    return (
      <CountryListItemWrapper
        key={item.code}
        onPress={() => handleCountryCode(item.code)}
        hoverStyle={{ opacity: 0.65 }}
        isSelected={isSelected}
        render={() => (
          <Text weight="semiBold">
            {`${item.flag}  ${item.name} `}
            <HintText weight="semiBold">{formatCountryCode(item.code)}</HintText>
          </Text>
        )}
      />
    )
  }

  const scrollIndex = useMemo(() => {
    return countries.findIndex((country) => country.code === countryCode)
  }, [countryCode])

  return (
    <Wrapper>
      <InlineWrapper>
        <CountryButton
          onPress={() => setVisibility(true)}
          render={() => (
            <Text weight="semiBold">
              {countries.find((country) => country.code === countryCode)?.flag}
              {`  ${formatCountryCode(countryCode)}`}
            </Text>
          )}
        />

        <InlineInputWrapper>
          <BaseInput
            value={phoneNumber}
            onChangeText={handlePhoneNumber}
            onSubmitEditing={({ nativeEvent }) => onSubmitEditing(formatCountryCode(countryCode) + nativeEvent.text)}
            aria-label="phone-input"
            placeholder={placeholder}
            inputMode="tel"
            enterKeyHint="done"
            textContentType="telephoneNumber"
          />
          <InputError error={error} />
        </InlineInputWrapper>
      </InlineWrapper>

      {isVisible && (
        <Portal>
          <CountryModal>
            <Backdrop style={countryModalStyle} />
            <CountryModalContent insets={{ top, bottom }} style={countryContentStyle}>
              <CountryModalHeader>
                <Text weight="bold" size="xLarge">
                  {t('phoneInput.dialCode')}
                </Text>
                <IconButton variant="secondary" icon={['fas', 'xmark']} onPress={() => setVisibility(false)} />
              </CountryModalHeader>
              <FlatList
                data={countries}
                renderItem={renderItem}
                initialScrollIndex={scrollIndex}
                getItemLayout={(_data, index) => ({
                  length: 50,
                  offset: 50 * index,
                  index,
                })}
              />
            </CountryModalContent>
          </CountryModal>
        </Portal>
      )}
    </Wrapper>
  )
}
