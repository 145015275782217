import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRightFromBracket } from '@fortawesome/pro-duotone-svg-icons/faArrowRightFromBracket'
import { faBed } from '@fortawesome/pro-duotone-svg-icons/faBed'
import { faBell } from '@fortawesome/pro-duotone-svg-icons/faBell'
import { faBracketsCurly } from '@fortawesome/pro-duotone-svg-icons/faBracketsCurly'
import { faBrightness } from '@fortawesome/pro-duotone-svg-icons/faBrightness'
import { faCapsules } from '@fortawesome/pro-duotone-svg-icons/faCapsules'
import { faChartPie } from '@fortawesome/pro-duotone-svg-icons/faChartPie'
import { faDroplet } from '@fortawesome/pro-duotone-svg-icons/faDroplet'
import { faDropletPercent } from '@fortawesome/pro-duotone-svg-icons/faDropletPercent'
import { faEarthAmericas } from '@fortawesome/pro-duotone-svg-icons/faEarthAmericas'
import { faFolderMedical } from '@fortawesome/pro-duotone-svg-icons/faFolderMedical'
import { faGaugeHigh } from '@fortawesome/pro-duotone-svg-icons/faGaugeHigh'
import { faHandHoldingSeedling } from '@fortawesome/pro-duotone-svg-icons/faHandHoldingSeedling'
import { faHeartPulse } from '@fortawesome/pro-duotone-svg-icons/faHeartPulse'
import { faImage } from '@fortawesome/pro-duotone-svg-icons/faImage'
import { faListTimeline } from '@fortawesome/pro-duotone-svg-icons/faListTimeline'
import { faLungs } from '@fortawesome/pro-duotone-svg-icons/faLungs'
import { faMemoCircleCheck } from '@fortawesome/pro-duotone-svg-icons/faMemoCircleCheck'
import { faMessageLines } from '@fortawesome/pro-duotone-svg-icons/faMessageLines'
import { faMessages } from '@fortawesome/pro-duotone-svg-icons/faMessages'
import { faMobile } from '@fortawesome/pro-duotone-svg-icons/faMobile'
import { faPen } from '@fortawesome/pro-duotone-svg-icons/faPen'
import { faPenToSquare } from '@fortawesome/pro-duotone-svg-icons/faPenToSquare'
import { faPersonArrowUpFromLine } from '@fortawesome/pro-duotone-svg-icons/faPersonArrowUpFromLine'
import { faPlayPause } from '@fortawesome/pro-duotone-svg-icons/faPlayPause'
import { faShieldCheck } from '@fortawesome/pro-duotone-svg-icons/faShieldCheck'
import { faShoePrints } from '@fortawesome/pro-duotone-svg-icons/faShoePrints'
import { faTasks } from '@fortawesome/pro-duotone-svg-icons/faTasks'
import { faTemperatureThreeQuarters } from '@fortawesome/pro-duotone-svg-icons/faTemperatureThreeQuarters'
import { faTrashCan } from '@fortawesome/pro-duotone-svg-icons/faTrashCan'
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser'
import { faWandMagicSparkles } from '@fortawesome/pro-duotone-svg-icons/faWandMagicSparkles'
import { faWavePulse } from '@fortawesome/pro-duotone-svg-icons/faWavePulse'
import { faWeightScale } from '@fortawesome/pro-duotone-svg-icons/faWeightScale'
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons/faCalendarDays'
import { faSeedling } from '@fortawesome/pro-light-svg-icons/faSeedling'
import { faRotateRight } from '@fortawesome/pro-regular-svg-icons/faRotateRight'
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { faBiking } from '@fortawesome/pro-solid-svg-icons/faBiking'
import { faCalendarDay } from '@fortawesome/pro-solid-svg-icons/faCalendarDay'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons/faEllipsis'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faExpand } from '@fortawesome/pro-solid-svg-icons/faExpand'
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo'
import { faMicrophone } from '@fortawesome/pro-solid-svg-icons/faMicrophone'
import { faMicrophoneSlash } from '@fortawesome/pro-solid-svg-icons/faMicrophoneSlash'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons/faThumbsUp'
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark'

library.add(
  faEllipsis,
  faListTimeline,
  faFolderMedical,
  faDropletPercent,
  faShieldCheck,
  faMemoCircleCheck,
  faPlus,
  faInfo,
  faMessageLines,
  faPlayPause,
  faMicrophone,
  faMicrophoneSlash,
  faArrowRight,
  faArrowLeft,
  faThumbsUp,
  faBiking,
  faTrashCan,
  faChartPie,
  faCheck,
  faAddressCard,
  faPen,
  faBell,
  faHeartPulse,
  faWeightScale,
  faWavePulse,
  faDroplet,
  faCapsules,
  faTasks,
  faXmark,
  faHandHoldingSeedling,
  faImage,
  faExpand,
  faRotateRight,
  faBrightness,
  faMessages,
  faSeedling,
  faGaugeHigh,
  faArrowRightFromBracket,
  faUser,
  faEarthAmericas,
  faMobile,
  faLungs,
  faBed,
  faShoePrints,
  faTemperatureThreeQuarters,
  faPersonArrowUpFromLine,
  faBracketsCurly,
  faWandMagicSparkles,
  faCalendarDays,
  faEnvelope,
  faPenToSquare,
  faCalendarDay,
)
