import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useAuthStore } from '@stores/auth'
import React from 'react'
import { RouteName } from '../routeNames'
import { Account } from '../routes/Account'
import { Messaging } from '../routes/Messaging'
import { Task } from '../routes/Task'
import { Tracking } from '../routes/Tracking'

type TabDefinition = {
  [key in RouteName]?: { icon?: IconProp; component?: React.FC }
}

export function getTabs(): TabDefinition {
  const hasMessaging = !!useAuthStore.getState().user?.enableMessaging

  return {
    [RouteName.Task]: {
      icon: ['fad', 'tasks'],
      component: Task,
    },
    [RouteName.Tracking]: {
      icon: ['fad', 'chart-pie'],
      component: Tracking,
    },
    ...(hasMessaging && {
      [RouteName.PseudoTabAddTracking]: {},
      [RouteName.Messaging]: {
        icon: ['fad', 'messages'],
        component: Messaging,
      },
    }),
    [RouteName.Account]: {
      icon: ['fad', 'user'],
      component: Account,
    },
    ...(!hasMessaging && { [RouteName.PseudoTabAddTracking]: {} }),
  }
}
