import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import noop from 'lodash/noop'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components/native'

import { Button } from '@components/Elements/Button'
import { DateInput, TextInput } from '@components/Elements/Input'
import { useI18n } from '@lib/i18n'
import { getTrackingInputSchema } from '@lib/validation'
import { useAuthStore } from '@stores/auth'
import { useToastsStore } from '@stores/toasts'
import { useTrackingEventsStore } from '@stores/trackingEvents'

import { HintText, InlineLink } from '@components/Elements/Text'
import { TrackingEvent, trackEvent } from '@lib/tracking'
import { MaunalTrackingChartAssetType, TrackingInputProps } from './types'

const Wrapper = styled.View`
  flex: 1;
  gap: 15px;
`
const ActionWrapper = styled.View`
  margin-top: 10px;
  align-self: center;
`
const InlineWrapper = styled.View`
  flex-direction: row;
  gap: 10px;
`
const NoteWrapper = styled.View``

export function TrackingInput<TrackingType extends MaunalTrackingChartAssetType>({
  trackingType,
  unit,
  addValue,
  onSuccessCallback = noop,
}: TrackingInputProps<TrackingType>): JSX.Element | null {
  const t = useI18n()
  const [isVisible, setVisibility] = useState(false)
  const [user] = useAuthStore((state) => [state.user])
  const [addTrackingEvent] = useTrackingEventsStore((state) => [state.addTrackingEvent])
  const [addToast] = useToastsStore((state) => [state.addToast])

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
    trigger,
  } = useForm({
    resolver: yupResolver(getTrackingInputSchema(trackingType, unit), undefined, {
      raw: true,
    }),
    defaultValues: { timestamp: new Date() },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  function handleFormSubmit(): void {
    handleSubmit(async ({ value, additionalValue, timestamp, additionalText }) => {
      // values are validated as numbers at this point but rawValues are returned due to double unit validations
      await addTrackingEvent({
        value: Number(value),
        additionalValue: Number(additionalValue) || undefined,
        type: trackingType,
        unit,
        timestamp: dayjs(timestamp).toISOString(),
        additionalText,
      })

      addToast({
        message: t('tracking.toasts.measurementRecorded'),
        duration: 2000,
        isCancable: false,
      })

      reset()

      onSuccessCallback()
    })()
  }

  useEffect(() => {
    if (Object.values(errors).length) trigger()
  }, [user?.locale])

  function handleTrackingNoteHintPressed(): void {
    trackEvent(TrackingEvent.trackingAddNotePressed)
    setVisibility(true)
  }

  return (
    <Wrapper>
      <Controller
        control={control}
        name="timestamp"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <DateInput
            mode="datetime"
            format="lll"
            value={dayjs(value) || undefined}
            error={error?.message}
            onChange={(date) => onChange(date.toDate())}
            min={dayjs('2021-01-01')}
            max={dayjs()}
          />
        )}
      />

      <InlineWrapper>
        {addValue.map(({ key, placeholder }, index) => {
          return (
            <Wrapper key={key}>
              <Controller
                control={control}
                name={key}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextInput
                    autoFocus={index === 0}
                    placeholder={placeholder}
                    inputMode="decimal"
                    onChange={(newValue) => onChange(newValue.replace(',', '.'))}
                    value={value ? `${value}` : ''}
                    error={error?.message}
                    enterKeyHint="done"
                  />
                )}
              />
            </Wrapper>
          )
        })}
      </InlineWrapper>

      {isVisible ? (
        <NoteWrapper>
          <Controller
            control={control}
            name="additionalText"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextInput
                onChange={onChange}
                value={value}
                numberOfLines={4}
                placeholder={t('tracking.note.example')}
                error={error?.message}
              />
            )}
          />

          <HintText size="tiny" margins={{ top: 5, left: 5, right: 5 }} weight="medium">
            {t('tracking.note.disclaimer')}
          </HintText>
        </NoteWrapper>
      ) : (
        <InlineLink
          isCentered
          size="small"
          weight="semiBold"
          hasUnderline={false}
          onPress={handleTrackingNoteHintPressed}
        >
          {t('tracking.note.hint')}
        </InlineLink>
      )}

      <ActionWrapper>
        <Button label={t('tracking.actions.add')} onPress={handleFormSubmit} isLoading={isSubmitting} />
      </ActionWrapper>
    </Wrapper>
  )
}
