import { SvgEmpty } from '@assets/svgr/Empty'
import { Empty } from '@components/Elements/Empty'
import { useI18n } from '@lib/i18n'
import { useTrackingEventsStore } from '@stores/trackingEvents'
import React from 'react'
import styled from 'styled-components/native'
import { TrackingPreview } from './TrackingPreview'
import { getTrackingConfig } from './trackingConfig'
import { TrackingChartAssetTrackingTypeWithoutHeightEnum, TrackingListProps } from './types'

const Wrapper = styled.View`
  margin: 0 20px 20px;
`
const TrackingPreviewWrapper = styled.View`
  margin-bottom: 10px;
`

export function TrackingList({ onPress, manualTrackingOnly = false }: TrackingListProps): JSX.Element {
  const t = useI18n()
  const [lastTrackingEvents] = useTrackingEventsStore((state) => [state.lastTrackingEvents])

  const eventTypes = Object.keys(lastTrackingEvents || {}) as TrackingChartAssetTrackingTypeWithoutHeightEnum[]

  return (
    <Wrapper>
      {!!lastTrackingEvents &&
        eventTypes.map((trackingType) => {
          const trackingConfig = getTrackingConfig<TrackingChartAssetTrackingTypeWithoutHeightEnum>(trackingType)

          if (!trackingConfig || (manualTrackingOnly && !trackingConfig.addValue)) return null

          return (
            <TrackingPreviewWrapper key={trackingType}>
              <TrackingPreview
                onPress={() => onPress(trackingType)}
                config={trackingConfig}
                event={lastTrackingEvents[trackingType]}
              />
            </TrackingPreviewWrapper>
          )
        })}
      {!eventTypes.length && <Empty message={t('empty.noTrackingConfigured')} svg={<SvgEmpty />} />}
    </Wrapper>
  )
}
