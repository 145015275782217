import { AxiosError } from 'axios'

import { isProd } from '@lib/constants'
import { captureException } from '@lib/error'
import { t } from '@lib/i18n'
import { useAppStore } from '@stores/app'
import { useToastsStore } from '@stores/toasts'

import { AxiosServerErrorResponse, ServerErrorResponse } from './types'

const isServerErrorResponse = (error: AxiosServerErrorResponse | Error): error is AxiosServerErrorResponse =>
  'response' in error && typeof error.response?.data === 'object'

const getServerErrorMessage = (error: ServerErrorResponse): string => {
  const errorMessages: Record<string, string> = {
    NotFoundError: t('error.notFound'),
    BlockedNumberError: t('error.blockedNumber'),
    InvalidNumberError: t('error.invalidNumber'),
    AccountNotFoundError: t('error.accountNotFound'),
    AccountAlreadyRegisteredError: t('error.alreadyExists'),
    AccountIsLockedError: t('error.accountLocked'),
    AccountDeletedError: t('error.accountDeleted'),
    IncorrectDobError: t('error.invalidDob'),
    default: `${error.errorName}: ${error.error}`,
  }

  const errorsWithoutToastMessage = [...(isProd ? ['BadRequestError'] : [])]

  return errorsWithoutToastMessage.includes(error.errorName)
    ? '' // don't show a toast message
    : (errorMessages[error.errorName] ?? errorMessages.default)
}

export function handleApiError(
  error: AxiosError<ServerErrorResponse | string | undefined> | Error,
  ignoredStatusCodes: number[] = [],
) {
  if ('request' in error && error.request) {
    // request identifies connection issues
    if (error.request.status === 502) {
      useAppStore.getState().setServerDown()

      return
    } // aptible returns 502 when service is down

    if (
      error.request.status === 504 ||
      ((error.request.status === null || error.request.status === 0) &&
        error.code &&
        ['ERR_BAD_REQUEST', 'ERR_NETWORK'].includes(error.code))
    ) {
      useToastsStore.getState().addToast({
        message: t('error.noInternetConnection'),
        key: 'noInternetConnection',
      })

      if (ignoredStatusCodes.includes(504)) throw new Error('504')

      return
    } // mobile devices might return 'status === null / 0' but an error code
  }

  if ('response' in error && error.response) {
    if (error.response.status === 426) {
      useAppStore.getState().setOutdated()

      return
    } // outdated => force update

    if (ignoredStatusCodes.includes(error.response.status)) {
      const cause = typeof error.response.data === 'object' ? error.response.data.errorName : undefined
      throw new Error(`${error.response.status}`, { cause })
    } // handle error locally
  }

  const message = isServerErrorResponse(error)
    ? getServerErrorMessage(error.response.data)
    : t('error.general.fallback', { error: error.message })

  console.log(`[handleApiError]: ${error.name} - ${message}`)

  if (message) {
    useToastsStore.getState().addToast({ message })
  }

  if (isServerErrorResponse(error)) {
    const sentryId: string | undefined = error.response.data.sentryId
    // Internal server errors supply the backend's sentryId as reference

    if (sentryId) console.log(`Sentry: ${sentryId}`)
  } else {
    captureException(error, 'handleApiError')
  }

  throw new Error(error.message)
}
