import { ScreenLayout } from '@components/Layouts'
import { Notifications as NotificationSettings } from '@components/Settings'
import { useI18n } from '@lib/i18n'
import React from 'react'

export function Notifications(): JSX.Element {
  const t = useI18n()

  return (
    <ScreenLayout hasBack title={t('settings.notifications')}>
      <NotificationSettings />
    </ScreenLayout>
  )
}
